import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminAddGalleryDetailsHook from "../../hook/admin/AdminAddGalleryDetailsHook";
import AsyncSelect from "react-select/async";
import add from "../../Images/add.png";
import MultiImageInput from "react-multiple-image-input";

const AdminAddGalleryDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [img, selectedFile, allUsers, onImageChange, onUserIdChange, onSubmit] =
    AdminAddGalleryDetailsHook(id);

  let loadUsersOptions = async (inputText) =>
    allUsers.filter((i) => i.fullname.firstname.includes(inputText));

  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  return (
    <div>
      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">اضافة صورة</div>

        <Col sm="8">

        <div className="text-form pb-2">{t("image")}</div>
        <div>
          <label htmlFor="upload-photo">
            <img
              src={img}
              alt="fzx"
              height="120px"
              width="120px"
              style={{ cursor: "pointer" }}
            />
          </label>
          <input
            type="file"
            name="photo"
            onChange={onImageChange}
            id="upload-photo"
          />
        </div>

          <label className="d-block mt-3 mb-2">اسم المستخدم</label>
          <AsyncSelect
            loadOptions={loadUsersOptions}
            onChange={onUserIdChange}
            getOptionLabel={(e) => e.fullname.firstname}
            getOptionValue={(e) => e._id}
            onInputChange={loadUsersOptions}
          />
        </Col>
      </Row>
      <Row>
      <Col sm="8" className="d-flex justify-content-end ">
        <button
          onClick={onSubmit}
          className="btn-save d-inline mt-3 "
          style={{ backgroundColor: color }}
        >
          {t("add")}
        </button>
      </Col>
    </Row>

    <ToastContainer />
    </div>
  );
};

export default AdminAddGalleryDetails;
