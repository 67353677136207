import {
    GET_GALLERY_DETAILS_FOR_GALLERY,
    UPDATE_ONE_GALLERY_DETAILS,
    ADD_GALLERY_DETAILS,
    GET_ONE_GALLERY_DETAILS
    
  } from "../type";
  const inital = {
    allGalleryDetails:[],
    updateGalleryDetails:[],
    addGallaryDetails:[],
    oneGalleryDetails:[]
  };
  
  const galleryDetailsReducer = (state = inital, action) => {
    switch (action.type) {
        case GET_GALLERY_DETAILS_FOR_GALLERY:
        return{
            ...state,
            allGalleryDetails:action.payload
        };
        case UPDATE_ONE_GALLERY_DETAILS:
            return{
             ...state,
             updateGalleryDetails:action.payload
            };
        case ADD_GALLERY_DETAILS:
            return{
             ...state,
             addGallaryDetails:action.payload
            };
        case GET_ONE_GALLERY_DETAILS:
            return{
             ...state,
             oneGalleryDetails:action.payload
            };
        default:
            return state;
    }
}
export default galleryDetailsReducer;