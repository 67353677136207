import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";
import avatar from "../../../src/Images/adminIcon.png";
import { addOneNews } from "../../redux/actions/newsActions";
import { getAllUsers } from "../../redux/actions/usersActions";
import { useRef } from "react";

const AdminAddNewsHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [newsMainType, setNewsMainType] = useState("");
  const [title, setTitle] = useState("");
  const descRef = useRef();

  const [desc, setDesc] = useState("");
  const [images, setImages] = useState([]);
  // const[mainImage,setMainImage]=useState("");

  //normal

  const [seen, setSeen] = useState("");
  const [important, setImportant] = useState("");
  //monasba
  const [newsDate, setNewsDate] = useState("");
  const [relationalUsersArray, setRelationalUsersArray] = useState("");
  const [userId, setUserId] = useState("");
  const [monasbaType, setMonasbaType] = useState("");
  const [monasbaGrromId, setMonasbaGrromId] = useState("");
  const [monasbaBrideId, setMonasbaBrideId] = useState("");
  const [monasbaGroomNameFML, setMonasbaGroomNameFML] = useState("");
  const [monasbaBirdeNameFML, setMonasbaBirdeNameFML] = useState("");
  //birth
  const [newBornUserId, setNewBornUserId] = useState("");
  const [newBorn2UserId, setNewBorn2UserId] = useState("");
  const [newBornNameFML, setNewBornNameFML] = useState("");
  const [newBorn2NameFML, setNewBorn2NameFML] = useState("");
  const [newBornGenderFML, setNewBornGenderFML] = useState("");
  const [newBorn2GenderFML, setNewBorn2GenderFML] = useState("");
  const [parentId, setParentId] = useState("");
  //death
  const [deathUserId, setDeathUserId] = useState("");
  const [deathFromOutFamilyFMLGender, setDeathFromOutFamilyFMLGender] =
    useState("");
  const [deathFromOutFamilyFML, setDeathFromOutFamilyFML] = useState("");
  const [deathDesc, setDeathDesc] = useState("");

  const [deathGanazaAddress, setDeathGanazaAddress] = useState("");
  const [deathGanazaDateTime, setDeathGanazaDateTime] = useState("");
  const [deathGanazaLocation, setDeathGanazaLocation] = useState("");

  const [deathDafnaAddress, setDeathDafnaAddress] = useState("");
  const [deathDafnaDateTime, setDeathDafnaDateTime] = useState("");
  const [deathDafnaLocation, setDeathDafnaLocation] = useState("");

  const [death3azaAddress, setDeath3azaAddress] = useState("");
  const [death3azaLocation, setDeath3azaLocation] = useState("");
  const [death3azaDateTimeFrom, setDeath3azaDateTimeFrom] = useState("");
  const [death3azaDateTimeTo, setDeath3azaDateTimeTo] = useState("");
  const [male3azaContacts, setMale3azaContacts] = useState("");
  const [female3azaContacts, setFemale3azaContacts] = useState("");
  const [allUsers, setAllUsers] = useState("");

  const [normalType, setNormalType] = useState(false);
  const [birthType, setBirthType] = useState(false);
  const [deathType, setDeathType] = useState(false);
  const [selectMonasbaType, setselectMonasbaType] = useState(false);
  const [firstNameNewBorn, setFirstNameNewBorn] = useState("");
  const [firstNameNewBorn2, setFirstNameNewBorn2] = useState("");
  const [familyFemales, setFamilyFemales] = useState([]);
  const [familyMales, setFamilyMales] = useState([]);

  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const [jobArr, setJobArr] = useState([]);

  const [eduKey, setEduKey] = useState("");
  const [eduValues, setEduValues] = useState("");
  const [showEducation, setShowEducation] = useState(false);
  const [EduArr, setEduArr] = useState([]);

  const [female3AzaArr, setFemale3AzaArr] = useState([]);
  const [birthFromFamily, setBirthFromFamily] = useState("");

  const [death3azaFemaleAddress, setDeath3azaFemaleAddress] = useState("");
  const [death3azaFemaleLocation, setDeath3azaFemaleLocation] = useState("");
  const [death3azaFemaleDateTimeFrom, setDeath3azaFemaleDateTimeFrom] =
    useState("");
  const [death3azaFemaleDateTimeTo, setDeath3azaFemaleDateTimeTo] =
    useState("");

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  let Users = useSelector((state) => state.usersReducers.allUser);

  useEffect(() => {
    if (Users) {
      let familyFemales = Users.filter((el) => {
        if (el.gender === "female") return el;
      });

      let familyMales = Users.filter((el) => {
        if (el.gender === "male") return el;
      });

      setFamilyMales(familyMales);
      setFamilyFemales(familyFemales);
    }

    setAllUsers(Users);
  }, [Users]);

  //deaths
  //monasba
  const onChangeNewsMainType = (e) => {
    if (e.target.value == "normal") {
      setNormalType(true);
      setBirthType(false);
      setDeathType(false);
      setselectMonasbaType(false);
    }

    if (e.target.value == "births") {
      setNormalType(false);
      setBirthType(true);
      setDeathType(false);
      setselectMonasbaType(false);
    }

    if (e.target.value == "monasba") {
      setNormalType(false);
      setBirthType(false);
      setDeathType(false);
      setselectMonasbaType(true);
    }

    if (e.target.value == "deaths") {
      setNormalType(false);
      setBirthType(false);
      setDeathType(true);
      setselectMonasbaType(false);
    }

    setNewsMainType(e.target.value);
  };

  const handleShow = (flag) => {
    console.log("handle");
    if (flag == "3azamales") {
      setShow(true);
    } else if (flag == "education") {
      setShowEducation(true);
    }
    // else if(flag=='social')
    // {
    //   setShowSocial(true);
    // }
  };

  const onChangeKey = (e) => {
    e.persist();
    console.log("key");

    setKey(e.target.value);
  };

  const onChangeValue = (e) => {
    e.persist();
    setValue(e.target.value);
  };

  const onChangeEduKey = (e) => {
    e.persist();
    setEduKey(e.target.value);
  };

  const onChangeEduValues = (e) => {
    setEduValues(e.target.value);
  };

  const clickHandler = (flag) => {
    if (flag === "3azamales") {
      setJobArr([
        ...jobArr,
        { name: key, mobile: value, count: jobArr.length + 1 },
      ]);
    } else if (flag === "education") {
      setEduArr([
        ...EduArr,
        { name: eduKey, mobile: eduValues, count: EduArr.length + 1 },
      ]);
    }

    setKey("");
    setValue("");
    setEduKey("");
    setEduValues("");
  };

  const deleteElement = (el, Arr, count = null) => {
    if (Arr === "3azamales") {
      // setJobArr(jobArr.filter((element) => el !== element));
      setJobArr(jobArr.filter((el) => el.count !== count));
    } else if (Arr === "education") {
      setEduArr(EduArr.filter((element) => el !== element));
    }
    // else if(Arr=='social')
    // {
    //    setSocialMediaArr(socialMediaArr.filter((element) => el !== element));
    // }
  };

  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const onChangeDesc = (e) => {
    setDesc(e.target.value);
  };

  // const onChangeMainImage = (e) =>{
  //   setMainImage(e.target.value);
  // }

  const onChangeImages = (e) => {};

  const onChangeSeen = (e) => {
    setSeen(e.target.value);
  };

  const onChangeImportant = (e) => {
    setImportant(e.target.value);
  };

  const onChangeNewsDate = (e) => {
    setNewsDate(e.target.value);
  };
  //array
  const onChangeRelationalUsersArray = (values) => {
    console.log("object");
    console.log(values);
    let id = [];
    values.map((e) => {
      id.push(e);
    });
    console.log(id);
    setRelationalUsersArray(id);
    //
  };

  const onChangeUserId = (value) => {
    //console.log('first');
    //console.log(value._id)
    setUserId(value);
  };

  const onChangeMonasbaType = (e) => {
    setMonasbaType(e.target.value);
  };

  const onChangeMonasbaGrromId = (value) => {
    setMonasbaGrromId(value);
  };

  const onChangeMonasbaBrideId = (value) => {
    setMonasbaBrideId(value);
  };

  const onChangeMonasbaGroomNameFML = (e) => {
    setMonasbaGroomNameFML(e.target.value);
  };

  const onChangeMonasbaBirdeNameFML = (e) => {
    setMonasbaBirdeNameFML(e.target.value);
  };

  const onChangeNewBornUserId = (value) => {
    setNewBornUserId(value._id);
    setFirstNameNewBorn(value.fullname.firstname);
  };

  const onChangeNewBorn2UserId = (value) => {
    setNewBorn2UserId(value._id);
    setFirstNameNewBorn2(value.fullname.firstname);
  };

  const onChangeNewBornNameFML = (e) => {
    setNewBornNameFML(e.target.value);
  };

  const onChangeNewBorn2NameFML = (e) => {
    setNewBorn2NameFML(e.target.value);
  };

  const onChangeNewBornGenderFML = (e) => {
    setNewBornGenderFML(e.target.value);
  };

  const onChangeNewBorn2GenderFML = (e) => {
    setNewBorn2GenderFML(e.target.value);
  };

  const onChangeParentId = (value) => {
    //console.log('first');
    //console.log(value._id);
    setParentId(value);
  };

  const onChangeDeathUserId = (value) => {
    setDeathUserId(value);
  };

  const onChangeDeathFromOutFamilyFMLGender = (e) => {
    setDeathFromOutFamilyFMLGender(e.target.value);
  };

  const onChangeDeathFromOutFamilyFML = (e) => {
    setDeathFromOutFamilyFML(e.target.value);
  };

  const onChangeDeathDesc = (e) => {
    setDeathDesc(e.target.value);
  };

  const onChangeDeathGanazaAddress = (e) => {
    setDeathGanazaAddress(e.target.value);
  };

  const onChangeDeathGanazaDateTime = (e) => {
    setDeathGanazaDateTime(e.target.value);
  };

  const onChangeDeathGanazaLocation = (e) => {
    setDeathGanazaLocation(e.target.value);
  };

  const onChangeDeathDafnaAddress = (e) => {
    setDeathDafnaAddress(e.target.value);
  };

  const onChangeDeathDafnaDateTime = (e) => {
    setDeathDafnaDateTime(e.target.value);
  };

  const onChangeDeathDafnaLocation = (e) => {
    setDeathDafnaLocation(e.target.value);
  };

  const onChangeDeath3azaAddress = (e) => {
    setDeath3azaAddress(e.target.value);
  };

  const onChangeDeath3azaLocation = (e) => {
    setDeath3azaLocation(e.target.value);
  };

  const onChangeDeath3azaDateTimeFrom = (e) => {
    setDeath3azaDateTimeFrom(e.target.value);
  };

  const onChangeDeath3azaDateTimeTo = (e) => {
    setDeath3azaDateTimeTo(e.target.value);
  };
  //array
  const onChangeMale3azaContacts = (values) => {
    console.log("object");
    let id = [];
    values.map((e) => {
      id.push(e._id);
    });
    console.log(id);
    setMale3azaContacts(id);
  };
  //array
  const onChangeFemale3azaContacts = (values) => {
    console.log("object");
    let id = [];
    values.map((e) => {
      id.push(e._id);
    });
    console.log(id);
    setFemale3azaContacts(id);
  };

  const onChangeBirthFromFamily = (e) => {
    setBirthFromFamily(e.target.value);
  };

  //to convert base 64 to file
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const itemImages = Array.from(Array(Object.keys(images).length).keys()).map(
    (item, index) => {
      return dataURLtoFile(images[index], Math.random() + ".png");
    }
  );

  const onSubmit = async () => {
    // console.log('descRef.current.getContent()');
    // console.log(descRef.current.getContent());
    // return;
    if (userId == "") {
      notify("من فضلك ادخل اسم المستخدم", "error");
      return;
    }
    //NewsMainType

    const formData = new FormData();

    itemImages.map((item) => formData.append("images", item));
    formData.append("NewsMainType", newsMainType);
    formData.append("userId", userId._id);
    formData.append("desc", descRef.current.getContent());

    //desc, setDesc

    if (newsMainType === "normal") {
      formData.append("title", title);
      if (relationalUsersArray.length > 0)
        relationalUsersArray.map((item) =>
          formData.append("relationalUsersArray", item._id)
        );
    }

    if (newsMainType == "births") {
      if (parentId == "") {
        notify("من فضلك ادخل اسم الاب", "error");
        return;
      }

      if (birthFromFamily == "") {
        notify("اختر اذا كان المولود من داخل العائلة", "error");
        return;
      }
      if (newsDate == "") {
        notify("من فضلك ادخل تاريخ الخبر", "error");
        return;
      }

      if (parentId) formData.append("parentId", parentId._id);
      formData.append("newsDate", newsDate);

      // formData.append("desc", descRef.current.getContent());

      if (birthFromFamily === "true") {
        formData.append("newBornFullName", newBornNameFML);
        formData.append("newBorn2FullName", newBorn2NameFML);
        formData.append("newBornGender", newBornGenderFML);
        formData.append("newBorn2Gender", newBorn2GenderFML);
      } else {
        formData.append("newBornNameFML", newBornNameFML);
        formData.append("newBorn2NameFML", newBorn2NameFML);
        formData.append("newBornGenderFML", newBornGenderFML);
        formData.append("newBorn2GenderFML", newBorn2GenderFML);
      }

      let name = parentId.gender == "male" ? " رزق " : " رزقت";
      let parentName = parentId.fullname.split(" ");

      name = name + parentName[0] + " ب " + newBornNameFML;

      if (newBorn2NameFML) name = name + " و " + newBorn2NameFML;

      formData.append("title", name);
    }

    if (newsMainType == "monasba") {
      let monasbaTypeString;

      if (monasbaType == "") {
        notify("من فضلك اختر نوع المناسبة", "error");
        return;
      }

      if (monasbaType == "marriage") monasbaTypeString = " زواج ";
      if (monasbaType == "contract") monasbaTypeString = " عقد قران ";
      if (monasbaType == "engagement") monasbaTypeString = " خطوبة ";

      if (monasbaGrromId) {
        let nameArr = monasbaGrromId.fullname.split(" ");
        monasbaTypeString = monasbaTypeString + nameArr[0];
      } else {
        //
        monasbaTypeString = monasbaTypeString + monasbaGroomNameFML;
      }

      if (monasbaBrideId) {
        let nameArr = monasbaBrideId.fullname.split(" ");
        monasbaTypeString = monasbaTypeString + " و " + nameArr[0];
      } else {
        monasbaTypeString = monasbaTypeString + " و " + monasbaBirdeNameFML;
      }

      //let monasbaTitle=
      formData.append("title", monasbaTypeString);
      formData.append("newsDate", newsDate);
      console.log("relationalUsersArray");
      console.log(relationalUsersArray);

      formData.append("monasbaType", monasbaType);
      if (monasbaGrromId) formData.append("monasbaGrromId", monasbaGrromId._id);
      if (monasbaBrideId) formData.append("monasbaBrideId", monasbaBrideId._id);
      formData.append("monasbaGroomNameFML", monasbaGroomNameFML);
      formData.append("monasbaBirdeNameFML", monasbaBirdeNameFML);
    }

    if (newsMainType == "deaths") {
      let deathTitle;

      if (deathUserId) {
        let nameArr = deathUserId.fullname.split(" ");

        deathTitle =
          (deathUserId.gender == "male"
            ? "انتقل الي رحمة الله "
            : "انتقلت الي رحمة الله ") + nameArr[0];
      } else {
        //deathFromOutFamilyFMLGender
        if (deathFromOutFamilyFMLGender == "") {
          notify("من فضلك اختر جنس المتوفي", "error");
          return;
        }
        deathTitle =
          (deathFromOutFamilyFMLGender == "male"
            ? "انتقل الي رحمة الله "
            : "انتقلت الي رحمة الله ") + deathFromOutFamilyFML;
      }

      formData.append("title", deathTitle);
      if (deathUserId) formData.append("deathUserId", deathUserId._id);
      formData.append(
        "deathFromOutFamilyFMLGender",
        deathFromOutFamilyFMLGender
      );
      formData.append("deathFromOutFamilyFML", deathFromOutFamilyFML);
      formData.append("deathDesc", desc);
      formData.append("deathGanazaAddress", deathGanazaAddress);
      formData.append("deathGanazaDateTime", deathGanazaDateTime);
      formData.append("deathGanazaLocation", deathGanazaLocation);

      formData.append("deathDafnaAddress", deathDafnaAddress);
      formData.append("deathDafnaDateTime", deathDafnaDateTime);
      formData.append("deathDafnaLocation", deathDafnaLocation);

      formData.append("death3azaAddress", death3azaAddress);

      formData.append("death3azaLocation", death3azaLocation);
      formData.append("death3azaDateTimeFrom", death3azaDateTimeFrom);
      formData.append("death3azaDateTimeTo", death3azaDateTimeTo);
      formData.append("male3azaContacts", JSON.stringify(jobArr));
      formData.append("female3azaContacts", JSON.stringify(EduArr));

      ////////////////
      formData.append("death3azaFemaleAddress", death3azaFemaleAddress);
      formData.append("death3azaFemaleLocation", death3azaFemaleLocation);
      formData.append(
        "death3azaFemaleDateTimeFrom",
        death3azaFemaleDateTimeFrom
      );
      formData.append("death3azaFemaleDateTimeTo", death3azaFemaleDateTimeTo);
      ////////////

      //if(male3azaContacts.length>0) male3azaContacts.map((item) => formData.append("male3azaContacts", item));
      //if(female3azaContacts.length>0) female3azaContacts.map((item) => formData.append("female3azaContacts", item));
    }

    setLoading(true);

    await dispatch(addOneNews(formData));

    setLoading(false);
  };

  const res = useSelector((state) => state.newsReducer.addNews);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم الاضافة بنجاح", "success");
        setTimeout(() => {
          navigate("/admin/allnews");
        }, 1500);
      } else {
        notify("فشل في عملية الاضافة", "error");
      }
    }
  }, [loading]);

  return [
    newsMainType,
    title,
    desc,
    setDesc,
    images,
    setImages,
    seen,
    important,
    newsDate,
    relationalUsersArray,
    userId,
    monasbaType,
    monasbaGrromId,
    monasbaBrideId,
    monasbaGroomNameFML,
    monasbaBirdeNameFML,
    newBornUserId,
    newBorn2UserId,
    newBornNameFML,
    newBorn2NameFML,
    newBornGenderFML,
    newBorn2GenderFML,
    parentId,
    deathUserId,
    deathFromOutFamilyFMLGender,
    deathFromOutFamilyFML,
    deathDesc,
    deathGanazaAddress,
    deathGanazaDateTime,
    deathGanazaLocation,
    deathDafnaAddress,
    deathDafnaDateTime,
    deathDafnaLocation,
    death3azaAddress,
    death3azaLocation,
    death3azaDateTimeFrom,
    death3azaDateTimeTo,
    male3azaContacts,
    female3azaContacts,
    birthFromFamily,

    onChangeNewsMainType,
    onChangeTitle,
    onChangeDesc,
    onChangeImages,
    onChangeSeen,
    onChangeImportant,
    onChangeNewsDate,
    onChangeRelationalUsersArray,
    onChangeUserId,
    onChangeMonasbaType,
    onChangeMonasbaGrromId,
    onChangeMonasbaBrideId,
    onChangeMonasbaGroomNameFML,
    onChangeMonasbaBirdeNameFML,
    onChangeNewBornUserId,
    onChangeNewBorn2UserId,
    onChangeNewBornNameFML,
    onChangeNewBorn2NameFML,
    onChangeNewBornGenderFML,
    onChangeNewBorn2GenderFML,
    onChangeParentId,
    onChangeDeathUserId,
    onChangeDeathFromOutFamilyFMLGender,
    onChangeDeathFromOutFamilyFML,
    onChangeDeathDesc,
    onChangeDeathGanazaAddress,
    onChangeDeathGanazaDateTime,
    onChangeDeathGanazaLocation,
    onChangeDeathDafnaAddress,
    onChangeDeathDafnaDateTime,
    onChangeDeathDafnaLocation,
    onChangeDeath3azaAddress,
    onChangeDeath3azaLocation,
    onChangeDeath3azaDateTimeFrom,
    onChangeDeath3azaDateTimeTo,
    onChangeMale3azaContacts,
    onChangeFemale3azaContacts,
    onChangeBirthFromFamily,

    normalType,
    birthType,
    deathType,
    selectMonasbaType,
    allUsers,
    familyFemales,
    familyMales,

    handleShow,
    key,
    onChangeKey,
    value,
    onChangeValue,
    clickHandler,
    show,
    showEducation,
    jobArr,
    eduKey,
    onChangeEduKey,
    eduValues,
    onChangeEduValues,
    EduArr,
    deleteElement,
    death3azaFemaleAddress,
    setDeath3azaFemaleAddress,
    death3azaFemaleLocation,
    setDeath3azaFemaleLocation,
    death3azaFemaleDateTimeFrom,
    setDeath3azaFemaleDateTimeFrom,
    death3azaFemaleDateTimeTo,
    setDeath3azaFemaleDateTimeTo,
    descRef,
    onSubmit,
  ];
};

export default AdminAddNewsHook;
