

import React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import AdminProfile from '../../Components/Admin/AdminProfile';
import SideBar from "../../Components/Admin/SideBar";



const AdminProfilePage = () => {
    return (

        <div className="d-flex justify-content-start">
            <div>
                <SideBar />
            </div>
            <div className="p-4" style={{ width: '100%' }}>
                <AdminProfile />
            </div>
        </div>
    )

}

export default AdminProfilePage

