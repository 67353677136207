export const formateDate = (dateString) =>  {
    const options = { day: "numeric", month: "numeric", year: "numeric" };
   var mdy= new Date(dateString).toLocaleDateString(undefined, options);
    mdy = mdy.split("/");
    var month = mdy[0];
    var day = mdy[1];

    if(day<10) day='0'+day;
    if(month<10) month='0'+month
    var year = parseInt(mdy[2]);
    var formattedDate;
    
   formattedDate = year + "-" + month + "-" + day ;

   return  formattedDate;
  };

  //export default formateDate;
  