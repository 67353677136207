import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Container,
  Modal,
  Pagination,
  Row,
  Spinner,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import ViewAllNewsHook from "../../hook/admin/ViewAllNewsHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddButton from "../Utilities/AddButton";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { formateDate } from "../Utilities/formatDate";

//import Popup from 'reactjs-popup';
//import 'reactjs-popup/dist/index.css';

const AdminAllNews = () => {
  //console.log('done');  newsDate
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [news, clickHandler, handleShowFun, currentPage, handleChangePage] =
    ViewAllNewsHook();

  let columns = [
    {
      name: "العنوان",
      selector: "title",
      sortable: true,
    },
    {
      name: "الصورة الرئيسية",
      cell: (row) => (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img
          src={row.mainImage}
          width="80px"
          height="80px"
          style={{
            padding: "2px",
            justifyContent: "center",
            alignItems: "center",
            objectFit: "contain",
          }}
        />
      ),
    },
    {
      name: "التفاعل",
      cell: (row) => (
        <div
          style={{ cursor: "pointer" }}
          variant="secondary"
          id={row._id}
          onClick={() => navigate(`/admin/newsaction/${row._id}`)}
        >
          {t("clickhere")}
          <i className="fa fa-folder-open-o mx-1" />
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },

    {
      name: t("modify"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("state"),
      cell: (row) =>
        row.status == true ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false);
            }}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true);
            }}
            id={row._id}
          >
            {t("active")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: news,
  };

  return (
    <div>
      <title>الاخبار</title>
      <div className="d-flex justify-content-between">
        <div className="admin-content-text">الاخبار </div>
        <AddButton title="اضافة " navigateTo="/admin/addnews" />
      </div>

      {news? (
        <DataTableExtensions
          {...tableData}
          filterPlaceholder={t("search here")}
        >
          <DataTable
            noHeader
            defaultSortAsc={false}
            pagination
            highlightOnHover
            data={news}
          ></DataTable>
        </DataTableExtensions>
      ) : (
        <Spinner animation="border" variant="primary" />
      )}

      <div></div>

      <ToastContainer />
    </div>
  );
};

export default AdminAllNews;
