import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";
import { loginUser } from "../../redux/actions/authActions";

const LoginHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [val, setVal] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [isPress, setIsPress] = useState(false);

  const onChangeVal = (e) => {
    setVal(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const onSubmit = async () => {
    if (val === "") {
      notify("تاكد من الايميل او رقم الجوال", "error");
      return;
    }
    if (password === "") {
      notify("من فضلك ادخل الباسورد", "error");
      return;
    } else {
      setIsPress(true);
      setLoading(true);
      await dispatch(
        loginUser({
          username: val.toLocaleLowerCase(),
          password: password,
        })
      );
      setLoading(false);
      setIsPress(false);
    }
  };
  const res = useSelector((state) => state.authReducers.loginUser);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        if (res.token) {
          localStorage.setItem("token", res.token);
          localStorage.setItem("user", JSON.stringify({...res,app:'family'}));
          notify("تم تسجيل الدخول بنجاح", "success");
          setTimeout(() => {
            if (res.role === 'admin') {
              window.location.href = "/";
            } else {
              window.location.href = "/";
            }
          }, 1500);
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        if (res.data) {
          if (
            res.data.success == false
          ) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            notify("خطأ في البريد الالكتروني أو كلمة المرور   ", "error");
          }
          if (res.data.message === "The user is blocked") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            notify("هذا المستخدم محظور من قبل الادارة", "error");
          }
        }

        setLoading(true);
      }
    }
  }, [loading]);

  return [
    val,
    password,
    onChangeVal,
    onChangePassword,
    loading,
    onSubmit,
    isPress,
  ];
};

export default LoginHook;
