import { GET_ALL_SETTING_FOR_USER , UPDATE_SETTINGS_FOR_ONE_USER } from "../type";
const inital = {
  allSettings:[],
  updateSettings:[]
};

const userSettingsReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_SETTING_FOR_USER:
        return{
            ...state,
            allSettings:action.payload
        };
    case UPDATE_SETTINGS_FOR_ONE_USER:
        return{
            ...state,
            updateSettings:action.payload
        };

    default:
        return state;
  }
}
export default userSettingsReducer;