import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminEditCountry from "../../Components/Admin/AdminEditCountry";
import SideBar from "../../Components/Admin/SideBar";

const AdminEditCountryPage = () => {
  return (

    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
        <AdminEditCountry />
      </div>
    </div>

  );
};

export default AdminEditCountryPage;