import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ViewAllGallariesHook from "../../hook/admin/ViewAllGallariesHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddButton from "../Utilities/AddButton";
import { useTranslation } from "react-i18next";
import {formateDate} from "../../Components/Utilities/formatDate";
import Cookies from "js-cookie";

const AdminAllGalleries = () => {
      // console.log('first')
    //const[documents, clickHandler, handleShowFun]= ViewAllDocumentsHook();
    const[gallaries, clickHandler, handleShowFun]=ViewAllGallariesHook();
  
    const navigate = useNavigate();
    const {t} = useTranslation();

    let columns = [
        {
          name: 'العنوان',
          selector: "title",
          sortable: true,
        },
        {
           name: 'الصورة الرئيسية',
           cell: (row) => (
             // eslint-disable-next-line jsx-a11y/alt-text
             <img
               src={row.mainImage}
               width="80px"
               height="80px"
               style={{
                 padding: "2px",
                 justifyContent: "center",
                 alignItems: "center",
                 objectFit: "contain",
               }}
             />
           ),
        },

        {
            name: 'الصور',
            cell: (row) => (
              <div
                style={{ cursor: 'pointer' }}
                variant='secondary'
                id={row._id}
                onClick={() => navigate(`/admin/gallarydetails/${row._id}`)}
              >
                {t("clickhere")}
                <i className="fa fa-folder-open-o mx-1" />
      
              </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
          },
        
      
     
        {
          name: t("modify"),
          cell: (row) => (
            <Button
              variant="secondary"
              onClick={() => clickHandler(row._id)}
              id={row._id}
            >
              {t("Modify")}
              <i className="fa fa-pencil-square-o mx-1" />
            </Button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        }
      ];
    
      const tableData = {
        columns,
        data: gallaries
      };
    
      return( 
   
      <div>
      <title>معرض الصور</title>
          <div className="d-flex justify-content-between">
            <div className="admin-content-text">معرض الصور </div>
            <AddButton title='اضافة ' navigateTo='/admin/addgallery' />
    
          </div>
    
          <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
            <DataTable
              noHeader
              defaultSortAsc={false}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
          <ToastContainer />
        </div>
      );
}

export default AdminAllGalleries;

