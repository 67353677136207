import {
  GET_ALL_CITIES_FOR_ONE_COUNTRY,
  ADD_CITY,
  GET_ONE_CITY,
  UPDATE_ONE_CITY,
} from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";
import { useInsertData } from "../../hooks/useInsertData";

//get all cities for one country
export const getAllCitiesForOneCountry = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/cities/country/${id}`);

    dispatch({
      type: GET_ALL_CITIES_FOR_ONE_COUNTRY,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_CITIES_FOR_ONE_COUNTRY,
      payload: error,
    });
  }
};
//get one city
export const getOneCity = (id) => async (dispatch) => {
    try {
      const response = await useGetDataToken(`/api/v1/cities/${id}`);
  
      dispatch({
        type: GET_ONE_CITY,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ONE_CITY,
        payload: error,
      });
    }
  };
  
//add city
export const addCity = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/cities`, body);

    dispatch({
      type: ADD_CITY,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADD_CITY,
      payload: error,
    });
  }
};
//update one city
export const updateOneCity = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/cities/${id}`, body);

    dispatch({
      type: UPDATE_ONE_CITY,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ONE_CITY,
      payload: error,
    });
  }
};
