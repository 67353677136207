import {
  GET_ALL_COUNTRIES,
  UPDATE_ONE_COUNTRIES,
  GET_ONE_COUNTRIES,
  ADD_ONE_COUNTRY,
} from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";
import { useInsertData } from "../../hooks/useInsertData";

//get All countries
export const getAllCountries = () => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/countries`);

    dispatch({
      type: GET_ALL_COUNTRIES,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_COUNTRIES,
      payload: error,
    });
  }
};

//add one country
export const addOneCountry = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/countries`, body);

    dispatch({
      type: ADD_ONE_COUNTRY,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADD_ONE_COUNTRY,
      payload: error,
    });
  }
};

//get one country
export const getOneCountry = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/countries/${id}`);

    dispatch({
      type: GET_ONE_COUNTRIES,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_COUNTRIES,
      payload: error,
    });
  }
};

//update one country
export const updateOneCountry = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/countries/${id}`, body);

    dispatch({
      type: UPDATE_ONE_COUNTRIES,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ONE_COUNTRIES,
      payload: error,
    });
  }
};
