import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";

import {
  addCity
} from "../../redux/actions/citiesActions";

const AdminAddCityHook = (id) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    //const [loadingData, setLoadingData] = useState(true);
    const [name, setName] = useState("");
    
    let onChangeName = (e) => {
      e.persist();
      setName(e.target.value);
    };

    let onSubmit = async () => {
        if (name === "") {
          notify("من فضلك اكمل البيانات", "error");
          return;
        }
        setLoading(true);
        await dispatch(
            addCity( {
            name: name,
            country:id
          })
        );
        setLoading(false);
      };
    
      const res = useSelector((state) => state.citiesReducer.addCity);
    
      useEffect(() => {
        if (loading === false) {
          if (res) {
            notify("تم الاضافة بنجاح", "success");
            setTimeout(() => {
              navigate(`/admin/cities/${id}`);
            }, 1500);
          } else {
            notify("فشل في عملية الاضافة", "error");
          }
        }
      }, [loading]);
    
    
    
      return [name, onChangeName, onSubmit];
}

export default AdminAddCityHook;
