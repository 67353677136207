import { combineReducers } from "redux";
import authReducers from "./authReducers";
import usersReducers from "./usersReducers";
import countriesReducer from "./countriesReducer";
import citiesReducer from "./citiesReducer";
import utilitiesReducer from "./utilitiesReducer";
import fixedPagesReducer from "./fixedPagesReducer";
import documentsReducer from "./documentsReducer";
import contactsReducer from "./contactsReducer";
import gallariesReducer from "./gallariesReducer";
import galleryDetailsReducer from "./galleryDetailsReducer";
import userSettingsReducer from "./userSettingsReducer";
import newsReducer from "./newsReducer";
import newsActionsReducer from "./newsActionsReducer";
import mainCharactersReducer from "./mainCharactersReducer";
import subCharactersReducer from "./subCharactersReducer"; 
import settingsReducers from "./settingsReducers";
import pollsReducer from "./pollsReducer";


export default combineReducers({
  authReducers: authReducers,
  usersReducers: usersReducers,
  countriesReducer:countriesReducer,
  citiesReducer:citiesReducer,
  fixedPagesReducer:fixedPagesReducer,
  documentsReducer:documentsReducer,
  contactsReducer:contactsReducer,
  gallariesReducer:gallariesReducer,
  galleryDetailsReducer:galleryDetailsReducer,
  userSettingsReducer:userSettingsReducer,
  newsReducer:newsReducer,
  newsActionsReducer:newsActionsReducer,
  mainCharactersReducer:mainCharactersReducer,
  subCharactersReducer:subCharactersReducer,
  settingsReducers: settingsReducers,
  utilitiesReducer: utilitiesReducer,
  pollsReducer
});
