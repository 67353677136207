import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllMainCharacters,updateOneMainCharacter } from "../../redux/actions/mainCharactersActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";

const ViewAllMainCharactersHook = () => {
  //console.log('done');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
   dispatch(getAllMainCharacters());
  },[]);

  let allMainCharacters = useSelector((state) => state.mainCharactersReducer.allMainCharacters);
  console.log(allMainCharacters);
  let mainCharacters = [];
  if (mainCharacters) mainCharacters = allMainCharacters;

  const clickHandler = (id) => {
    navigate(`/admin/editmaincharacter/${id}`);
  };

  //update
  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
        updateOneMainCharacter(id, {
          status: status
        })
      );
  
    setLoading(false);
  };

  const res = useSelector((state) => state.mainCharactersReducer.updateOneMainCharacter);
  //console.log(res);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllMainCharacters());
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  return [mainCharacters, clickHandler, handleShowFun];

  
}
export default ViewAllMainCharactersHook;