export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const GET_ERROR = "GET_ERROR";
export const GET_ALL_BRAND = "GET_ALL_BRAND";
export const CREATE_BRAND = "CREATE_BRAND";
export const CREATE_SUB_CATEGORY = "CREATE_SUB_CATEGORY";
export const GET_SUB_BY_CAT_ID = "GET_SUB_BY_CAT_ID";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_SIMILAR_PRODUCTS = "GET_SIMILAR_PRODUCTS";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const LOGIN_USER = "LOGIN_USER";
export const CREATE_REVIEW = "CREATE_REVIEW";
export const REVIEWS_BY_PRODUCT_ID = "REVIEWS_BY_PRODUCT_ID";
export const DELETE_REVIEW = "DELETE_REVIEW";
export const UPDATE_REVIEW = "UPDATE_REVIEW";
export const ADD_TO_FAV = "ADD_TO_FAV";
export const REMOVE_FROM_FAV = "REMOVE_FROM_FAV";
export const USER_FAVORITE = "USER_FAVORITE";
export const ADD_COUPON = "ADD_COUPON";
export const GET_ALL_COUPON = "GET_ALL_COUPON";
export const DELETE_COUPON = "DELETE_COUPON";
export const GET_ONE_COUPON = "GET_ONE_COUPON";
export const EDIT_COUPON = "EDIT_COUPON";
export const USER_ADD_ADDRESS = "USER_ADD_ADDRESS";
export const GET_USER_ADDRESS_BY_ID = "GET_USER_ADDRESS_BY_ID";
export const DELETE_USER_ADDRESS = "DELETE_USER_ADDRESS";
export const USER_EDIT_ADDRESS = "USER_EDIT_ADDRESS";
export const GET_ONE_ADDRESS = "GET_ONE_ADDRESS";
export const ALL_USERS = "ALL_USERS";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const ADD_TO_CART = "ADD_TO_CART";
export const GET_USER_CART_ITEMS = "GET_USER_CART_ITEMS";
export const DELETE_ALL_CART_ITEMS = "DELETE_ALL_CART_ITEMS";
export const REMOVE_ONE_ITEM = "REMOVE_ONE_ITEM";
export const UPDATE_ITEM_CART = "UPDATE_ITEM_CART";
export const APPLY_COUPON = "APPLY_COUPON";
export const CREATE_ORDER = "CREATE_ORDER";
export const GET_ALL_PAYMENT_METHOD = "GET_ALL_PAYMENT_METHOD";
export const GET_ALL_ORDERS_BY_USER = "GET_ALL_ORDERS_BY_USER";
export const GET_ALL_ORDERS_BY_ADMIN = "GET_ALL_ORDERS_BY_ADMIN";
export const GET_ONE_ORDER = "GET_ONE_ORDER";
export const UPDATA_ORDER_STATUS = "UPDATA_ORDER_STATUS";
export const CREATE_NEW_SLIDER = "CREATE_NEW_SLIDER";
export const GET_ALL_SLIDER = "GET_ALL_SLIDER";
export const GET_ONE_USER = "GET_ONE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_SETTINGS = "GET_SETTINGS";
export const GET_ALL_SHIPPING_METHODS = "GET_ALL_SHIPPING_METHODS";
export const EDIT_SETTINGS = "EDIT_SETTINGS";
export const GET_POLICY_TERMS_ABOUT = "GET_POLICY_TERMS_ABOUT";
export const UPDATE_TERMS_ABOUT_POLICY = "UPDATE_TERMS_ABOUT_POLICY";
export const GET_ALL_ORDER_STATUS = "GET_ALL_ORDER_STATUS";
export const ADMIN_ALL_CARTS = "ADMIN_ALL_CARTS";
export const SEND_EMAIL = "SEND_EMAIL";
export const GET_ALL_PRODUCT_CATEGORY = "GET_ALL_PRODUCT_CATEGORY";
export const GET_ALL_PRODUCT_SUB_CATEGORY = "GET_ALL_PRODUCT_SUB_CATEGORY";
export const GET_ALL_PRODUCT_BRAND = "GET_ALL_PRODUCT_BRAND";
export const BRAND_BY_ID = "BRAND_BY_ID";
export const GET_CAT_BY_ID = "GET_CAT_BY_ID";
export const GET_SALES_REPORT = "GET_SALES_REPORT";
export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const GET_ONE_PAYMENT_METHOD = "GET_ONE_PAYMENT_METHOD";
export const EDIT_PAYMENT_METHOD = "EDIT_PAYMENT_METHOD";
export const ADD_VENDOR = "ADD_VENDOR";
export const GET_PRODUCTS_BY_VENDOR_ID = "GET_PRODUCTS_BY_VENDOR_ID";
export const GET_ORDERS_BY_VENDOR_ID = "GET_ORDERS_BY_VENDOR_ID";
export const ADD_USER_BY_ADMIN = "ADD_USER_BY_ADMIN";
export const GET_WEBSITE_DATA = "GET_WEBSITE_DATA";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_SLIDER = "DELETE_SLIDER";
export const UPDATE_SLIDER = "UPDATE_SLIDER";
export const GET_ONE_SLIDER = "GET_ONE_SLIDER";
export const GET_ONE_CATEGORY = "GET_ONE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_BRAND = "UPDATE_BRAND";
export const PRINT_INVOICE_ORDER = "PRINT_INVOICE_ORDER";
export const ADD_SHIPPING_METHODS = "ADD_SHIPPING_METHODS";
export const GET_ONE_SHIPPING_METHODS = "GET_ONE_SHIPPING_METHODS";
export const EDIT_SHIPPING_METHODS = "EDIT_SHIPPING_METHODS";
export const UPDATE_PRODUCT_STATUS = "UPDATE_PRODUCT_STATUS";
export const CREATE_NEW_ADS = "CREATE_NEW_ADS";
export const GET_ALL_ADS = "GET_ALL_ADS";
export const DELETE_ADS = "DELETE_ADS";
export const UPDATE_ADS = "UPDATE_ADS";
export const GET_ONE_ADS = "GET_ONE_ADS";
export const GET_ALL_ADS_WEB_SITE = "GET_ALL_ADS_WEB_SITE";
export const GET_ONE_USER_TOKEN = "GET_ONE_USER_TOKEN";
export const GET_VENDOR_BY_USERNAME = "GET_VENDOR_BY_USERNAME";
export const ADD_TICKET = "ADD_TICKET";
export const GET_ALL_TICKETS = "GET_ALL_TICKET";
export const UPDATE_TICKET = "UPDATE_TICKET";
export const ADD_ORDER_HISTORY = "ADD_ORDER_HISTORY";
export const GET_ALL_ORDER_HISTORY = "GET_ALL_ORDER_HISTORY";
export const GET_ORDERS_BY_DATE = "GET_ORDERS_BY_DATE";
export const GET_ALL_CATEGORIES_WITHOUT_BLOCHED_GATEGORY =
  "GET_ALL_CATEGORIES_WITHOUT_BLOCHED_GATEGORY";
export const GET_ALL_PRODUCT_ADMIN = "GET_ALL_PRODUCT_ADMIN";
export const PRINT_INVOICE_SHIPPMENTS = "PRINT_INVOICE_SHIPPMENTS";
export const CREATE_SHIPPMENTS = "CREATE_SHIPPMENTS";
export const GET_ONE_SHIPPING_METHODS_ID = "GET_ONE_SHIPPING_METHODS_ID";
export const SELECTED_LOGO = "SELECTED_LOGO";
export const SELECTED_PRIMARYCOLOR = "SELECTED_PRIMARYCOLOR";

//countries
export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const ADD_ONE_COUNTRY = "ADD_ONE_COUNTRY";
export const GET_ONE_COUNTRIES = "GET_ONE_COUNTRIES";
export const UPDATE_ONE_COUNTRIES = "UPDATE_ONE_COUNTRIES";
//cities
export const GET_ALL_CITIES_FOR_ONE_COUNTRY = "GET_ALL_CITIES_FOR_ONE_COUNTRY";
export const GET_ONE_CITY = "GET_ONE_CITY";
export const ADD_CITY = "ADD_CITY";
export const UPDATE_ONE_CITY = "UPDATE_ONE_CITY";
//fixed pages
export const GET_ALL_FIXED_PAGES = "GET_ALL_FIXED_PAGES";
export const ADD_FIXED_PAGE = "ADD_FIXED_PAGE";
export const GET_ONE_FIXED_PAGE = "GET_ONE_FIXED_PAGE";
export const UPDATE_FIXED_PAGE = "UPDATE_FIXED_PAGE";
//documents
export const GET_ALL_DOCUMENTS = "GET_ALL_DOCUMENTS";
export const UPDATE_ONE_DOCUMENT = "UPDATE_ONE_DOCUMENT";
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const GET_ONE_DOCUMENT = "GET_ONE_DOCUMENT";
//contacts
export const GET_ALL_CONTACTS = "GET_ALL_CONTACTS";
export const GET_ONE_CONTACT = "GET_ONE_CONTACT";
export const UPDATE_ONE_CONTACT = "UPDATE_ONE_CONTACT";
export const ADD_ONE_CONTACT = "ADD_ONE_CONTACT";
//gallaries
export const GET_ALL_GALLARIES = "GET_ALL_GALLARIES";
export const UPDATE_ONE_GALLARY = "UPDATE_ONE_GALLARY";
export const GET_ONE_GALLARY = "GET_ONE_GALLARY";
export const ADD_GALLARY = "ADD_GALLARY";
//gallery details
export const GET_GALLERY_DETAILS_FOR_GALLERY =
  "GET_GALLERY_DETAILS_FOR_GALLERY";
export const UPDATE_ONE_GALLERY_DETAILS = "UPDATE_ONE_GALLERY_DETAILS";
export const ADD_GALLERY_DETAILS = "ADD_GALLERY_DETAILS";
export const GET_ONE_GALLERY_DETAILS = "GET_ONE_GALLERY_DETAILS";
//user settings
export const GET_ALL_SETTING_FOR_USER = "GET_ALL_SETTING_FOR_USER";
export const UPDATE_SETTINGS_FOR_ONE_USER = "UPDATE_SETTINGS_FOR_ONE_USER";
//news
export const GET_ALL_News = "GET_ALL_News";
export const UPDATE_ONE_NEWS = "UPDATE_ONE_NEWS";
export const GET_ONE_NEWS = "GET_ONE_NEWS";
export const ADD_NEWS = "ADD_NEWS";
//news action for one news
export const GET_NEWS_ACTIONS_FOR_ONE_NEWS = "GET_NEWS_ACTIONS_FOR_ONE_NEWS";
//main characters
export const GET_ALL_MAIN_CHARACTERS = "GET_ALL_MAIN_CHARACTERS";
export const UPDATE_ONE_MAIN_CHARACTER = "UPDATE_ONE_MAIN_CHARACTER";
export const GET_ONE_MAIN_CHARACTER = "GET_ONE_MAIN_CHARACTER";
export const ADD_MAIN_CHARACTER = "ADD_MAIN_CHARACTER";
//sub characters
export const GET_ALL_SUB_CHARACTERS = "GET_ALL_SUB_CHARACTERS";
export const UPDATE_ONE_SUB_CHARACTER = "UPDATE_ONE_SUB_CHARACTER";
export const GET_ONE_SUB_CHARACTER = "GET_ONE_SUB_CHARACTER";
export const ADD_SUB_CHARACTER = "ADD_SUB_CHARACTER";

//settings
//export const GET_SETTINGS = "GET_SETTINGS";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const GET_ONE_SETTINGS = "GET_ONE_SETTINGS";
export const UPDATE_TREE = "UPDATE_TREE";

export const GET_ALL_POLLS = "GET_ALL_POLLS";
export const UPDATE_ONE_POLL = "UPDATE_ONE_POLL";
export const ADD_ONE_POLL = "ADD_ONE_POLL";

export const GET_ALL_USERS_WITH_PAGINATION = "GET_ALL_USERS_WITH_PAGINATION";

export const UPDATE_USERS_COLORS = "UPDATE_USERS_COLORS";
