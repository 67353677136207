import {
    GET_ALL_FIXED_PAGES,
    UPDATE_FIXED_PAGE,
    ADD_FIXED_PAGE,
    GET_ONE_FIXED_PAGE
  } from "../type";
  const inital = {
   allFixedPages:[],
   addFixedPage:[],
   updateFixedPage:[],
   oneFixedPage:[]
  };
  
  const fixedPagesReducer = (state = inital, action) => {
    switch (action.type) {
     case GET_ALL_FIXED_PAGES:
        return{
         ...state,
         allFixedPages:action.payload
        };
      case ADD_FIXED_PAGE:
        return{
         ...state,
         addFixedPage:action.payload
        };
     case UPDATE_FIXED_PAGE:
        return{
            ...state,
            updateFixedPage:action.payload
        };
      case GET_ONE_FIXED_PAGE:
        return{
          ...state,
          oneFixedPage:action.payload
        };

  
      default:
        return state;
    }
  };
  
  export default fixedPagesReducer;
  