import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllGallaryDetailsForOneGallery , updateOneGalleryDetails } from "../../redux/actions/galleryDetailsActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";

const ViewAllGalleryDetailsHook = (id) => {
   
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [loading, setLoading] = useState(true);
  //console.log(id);
  
  useEffect(()=>{
    
   dispatch(getAllGallaryDetailsForOneGallery(id));
  },[]);

  let allGallariesDetails = useSelector((state) => state.galleryDetailsReducer.allGalleryDetails);
  console.log('allGallariesDetails');
  console.log(allGallariesDetails);
   let gallaryDetails = [];
   if (allGallariesDetails) gallaryDetails = allGallariesDetails;

   const clickHandler = (id) => {
    navigate(`/admin/editgallerydetails/${id}`);
  };

  //updateOneGallary
  const handleShowFun = async (id, status) => {
    //console.log(id)
    setLoading(true);
    await dispatch(
        updateOneGalleryDetails(id, {
          status: status
        })
      );
  
    setLoading(false);
  };

  const res = useSelector((state) => state.gallariesReducer.updateGallery);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllGallaryDetailsForOneGallery(id));
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  return [gallaryDetails, clickHandler, handleShowFun];

}

export default ViewAllGalleryDetailsHook;
