import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllGallaries , updateOneGallery } from "../../redux/actions/gallariesActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";

const ViewAllGallariesHook = () => {
   
   const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      dispatch(getAllGallaries());
  }, []);

  let allGallaries = useSelector((state) => state.gallariesReducer.allGallaries);
  console.log(allGallaries);
   let gallaries = [];
   if (allGallaries) gallaries = allGallaries;

   const clickHandler = (id) => {
    navigate(`/admin/editgallery/${id}`);
  };

  //updateOneGallary
  const handleShowFun = async (id, status) => {
    //console.log(id)
    setLoading(true);
    await dispatch(
        updateOneGallery(id, {
          status: status
        })
      );
  
    setLoading(false);
  };

  const res = useSelector((state) => state.gallariesReducer.updateGallery);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllGallaries());
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  return [gallaries, clickHandler, handleShowFun];


}
export default ViewAllGallariesHook;