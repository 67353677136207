import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";
import avatar from "../../../src/Images/adminIcon.png";
import {formateDate} from "../../Components/Utilities/formatDate";

import {
  getOneGallery,
  updateOneGallery,
} from "../../redux/actions/gallariesActions";

const AdminEditGalleryHook = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [title, setTitle] = useState("");
  const [img, setImg] = useState(avatar);
  const [selectedFile, setSelectedFile] = useState('');
  const[status,setStatus]=useState("");
  const[eventDate,setEventDate]=useState("");

  useEffect(()=>{
    dispatch(getOneGallery(id));
    //console.log('after')
  },[]);

  let oneGallery= useSelector((state) => state.gallariesReducer.oneGallery);
  console.log(oneGallery);
  useEffect(() => {
    if (oneGallery) {
      setTitle(oneGallery.title);
      setImg(oneGallery.mainImage);
      setStatus(oneGallery.statusIsShareIsLiveNoActive);
      setEventDate(formateDate(oneGallery.eventDate));
    }
  }, [oneGallery]);

  let onChangeTitle = (e) => {
    e.persist();
    setTitle(e.target.value);
  };

  //image
  const onImageChange = (event) => {
    console.log('done');
    if (event.target.files && event.target.files[0])
      setImg(URL.createObjectURL(event.target.files[0]));
      setSelectedFile(event.target.files[0]);
  };

  const onChangeStatus= (e) =>{
    e.persist();
    //console.log(e.target.value);
    setStatus(e.target.value);
  }

  const onChangeEventDate = (e) =>{
    e.persist();
    setEventDate(e.target.value);
  }

  const onSubmit = async() =>{
     if(title=='')
     {
        notify("من فضلك ادخل العنوان", "error");
        return;
     }
        setLoading(true);
        const formData = new FormData();
        formData.append("title", title);
        if(selectedFile)
        {
            formData.append("image", selectedFile);
        }
        formData.append("statusIsShareIsLiveNoActive", status);
        formData.append("eventDate", eventDate);
        await dispatch(updateOneGallery(id,formData));
        setLoading(false);
  }

  const res = useSelector((state) => state.gallariesReducer.updateGallery);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          navigate(`/admin/galleries`);
        }, 1500);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);



  return[
    title,
    img,
    selectedFile,
    status,
    eventDate,
    onChangeTitle,
    onImageChange,
    onChangeStatus,
    onChangeEventDate,
    onSubmit  
  ];
}

export default AdminEditGalleryHook;