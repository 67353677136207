import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllUsers, addUser } from "../../redux/actions/usersActions";
import { getAllCountries } from "../../redux/actions/countriesActions";
import { getAllCitiesForOneCountry } from "../../redux/actions/citiesActions";
import { getAllMainCharacters } from "../../redux/actions/mainCharactersActions";
import notify from "../useNotification";
import avatar from "../../../src/Images/adminIcon.png";
import { getAllSubCharactersForOneMain } from "../../redux/actions/subCharactersActions";
import { fullNameFML } from "../../Components/Utilities/fullNameFML";
import { setValuesInFmlString } from "../../Components/Utilities/setValuesInFmlString";
import { useRef } from "react";

const AdminAddUserHook = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fatherId, setFatherId] = useState("");

  const [firstname, setFirstname] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [surName, setSurName] = useState("");

  const [gender, setGender] = useState("male");
  const [zone, setZone] = useState("");
  const [img, setImage] = useState(avatar);
  const [selectedFile, setSelectedFile] = useState(null);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("single");
  const [partnerId, setPartnerId] = useState("");
  const [partnerNameFML, setPartnerNameFML] = useState("");
  const [birthDate, setBirthDate] = useState("");



  const [cityId, setCityId] = useState("");

  const [role, setRole] = useState("user");
  const [motherId, setMotherId] = useState("");
  const [motherFML, setMotherFML] = useState("");
  const [contactArr, setContactArr] = useState("");
  const [description, setDescription] = useState("");
  const [countryId, setCountryId] = useState("");
  const [mobile, setMobile] = useState("");
  const [allUsers, setAllUsers] = useState("");
  const [mothers, setMothers] = useState("");
  const [fathers, setFathers] = useState("");
  const [countries, setAllCountries] = useState("");
  const [cities, setCities] = useState("");

  const [show, setShow] = useState(false);
  const [showEducation, setShowEducation] = useState(false);
  const [showSocial, setShowSocial] = useState(false);
  const [showBrothersFML, setShowBrothersFML] = useState(false);
  const [showReda3aMothers, setShowReda3aMothers] = useState(false);
  const [showReda3aSons, setShowReda3aSons] = useState(false);

  const [key, setKey] = useState("");
  const [eduKey, setEduKey] = useState("");
  const [socialKey, setSocialKey] = useState("");
  const [brotherFMLKey, setBrotherFMLKey] = useState("");
  const [reda3aMothersKey, setReda3aMothersKey] = useState("");
  const [reda3aSonsKey, setReda3aSonsKey] = useState("");

  const [value, setValue] = useState("");
  const [eduValues, setEduValues] = useState("");
  const [socialValues, setSocialValues] = useState("");
  const [brotherFMLValues, setBrotherFMLValues] = useState("male");
  const [reda3aSonsValues, setReda3aSonsValues] = useState("");

  const [jobArr, setJobArr] = useState([]);
  const [EduArr, setEduArr] = useState([]);
  const [socialMediaArr, setSocialMediaArr] = useState([]);
  const [brotherFMLArr, setBrotherFMLArr] = useState([]);
  const [reda3aMothersArr, setReda3aMothersArr] = useState([]);
  const [reda3aSonsArr, setReda3aSonsArr] = useState([]);



  const [isDead, setIsDead] = useState(false);

  const [tablekeysValues, settablekeysValues] = useState([]);

  const [loading, setLoading] = useState(false);
  //vip
  const [isVip, setIsVip] = useState(false);
  const [charSubCategoryVip, setCharSubCategoryVip] = useState("");
  const [charMainCategoryVip, setCharMainCategoryVip] = useState("");
  const [descVip, setDescVip] = useState("");
  const descVipRef = useRef();
  const [images, setImages] = useState([]);
  const [creatorVip, setCreatorVip] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [snapchat, setSnapchat] = useState("");

  //const[isLoading,setIsLoading]=useState(false);




  async function get() {
    await dispatch(getAllUsers());
    await dispatch(getAllCountries());
    await dispatch(getAllMainCharacters());
  }

  useEffect(() => {
    // setLoading(false);
    get();
    // setLoading(true);
  }, []);

  let Users = useSelector((state) => state.usersReducers.allUser);
  let allCountries = useSelector((state) => state.countriesReducer.allCountries);
  let allMainCharacters = useSelector((state) => state.mainCharactersReducer.allMainCharacters);
  //console.log(allMainCharacters);
  //console.log(allCountries);

  useEffect(() => {
    if (Users) {
      let familyMothers = Users.filter(el => {
        if (el.gender === 'female') return el;
      });

      let familyFathers = Users.filter(el => {
        if (el.gender === 'male') return el;
      });

      setFathers(familyFathers);
      setMothers(familyMothers);
      setAllUsers(Users);
    }
  }, [Users]);

  useEffect(() => {
    if (allMainCharacters) {
      setCharMainCategoryVip(allMainCharacters);
    }

  }, [allMainCharacters]);

  useEffect(() => {
    if (allCountries) {
      setAllCountries(allCountries);
    }
  }, [allCountries]);

  useEffect(() => {
    if (countryId) {
      dispatch(getAllCitiesForOneCountry(countryId));
    }

  }, [countryId]);

  let allCitiesInCountry = useSelector((state) => state.citiesReducer.allCitiesInOneCountry);

  useEffect(() => {
    if (allCitiesInCountry) {
      setCities(allCitiesInCountry);
    }

  }, [allCitiesInCountry]);




  const handleShow = (flag) => {
    if (flag == 'job') {
      setShow(true);
    }
    else if (flag == 'education') {
      setShowEducation(true);
    }
    else if (flag == 'social') {
      setShowSocial(true);
    }
    else if (flag == 'brothersFml') {
      setShowBrothersFML(true);
    }

    else if (flag == "mothersFml") {
      setShowReda3aMothers(true);
    }
    else if (flag == "reda3aSons") {
      setShowReda3aSons(true);
    }

  }

  const onChangeKey = (e) => {
    e.persist();
    setKey(e.target.value);
  };

  const onChangeEduKey = (e) => {
    e.persist();
    setEduKey(e.target.value);
  };

  const onChangeSocialKey = (e) => {
    setSocialKey(e.target.value);
  }

  const onChangeBrotherFMLKey = (e) => {
    console.log('brother fml');
    let stringWithoutSpaces = e.target.value.replace(/\s/g, '');
    setBrotherFMLKey(prevFML => prevFML + ' ' + stringWithoutSpaces);
  }

  const onChangeReda3aMothersKey = (e) => {
    //setReda3aMothersKey
    console.log('brother fml');
    let stringWithoutSpaces = e.target.value.replace(/\s/g, '');
    setReda3aMothersKey(prevFML => prevFML + ' ' + stringWithoutSpaces);
  }

  const onChangeReda3aSonsKey = (e) => {
    console.log('reda3a sons');
    let stringWithoutSpaces = e.target.value.replace(/\s/g, '');
    setReda3aSonsKey(prevFML => prevFML + ' ' + stringWithoutSpaces);
  }


  const onChangeValue = (e) => {
    e.persist();
    setValue(e.target.value);
  };

  const onChangeEduValues = (e) => {
    setEduValues(e.target.value);
  }

  const onChangesocialValues = (e) => {
    setSocialValues(e.target.value);
  }

  const onChangeBrotherFMLValues = (e) => {
    //console.log(e.target.value);
    setBrotherFMLValues(e.target.value);
  }

  //setReda3aSonsValues
  const onChangeReda3aSonsValues = (e) => {
    setReda3aSonsValues(e.target.value);
  }



  const clickHandler = (flag) => {
    if (flag === 'job') {
      setJobArr([
        ...jobArr,
        { companyname: key, jobname: value, count: jobArr.length + 1 },
      ]);
    }
    else if (flag === 'education') {
      setEduArr([
        ...EduArr,
        { certificate: eduKey, universityname: eduValues, count: EduArr.length + 1 },
      ]);

    }
    else if (flag == 'social') {
      setSocialMediaArr([
        ...socialMediaArr,
        { title: socialKey, link: socialValues, count: socialMediaArr.length + 1 },
      ]);
    }
    else if (flag == 'brothersFml') {
      let error = fullNameFML('brothersReda3', 'من فضلك ادخل الاسم الكامل في خانة اخوات من الرضاعة');

      if (error) {
        // setBrotherFMLArr([]);
        // console.log('error');
        // return;
      }
      else {
        setBrotherFMLArr([
          ...brotherFMLArr,
          { name: brotherFMLKey, gender: brotherFMLValues, count: brotherFMLArr.length + 1 }
        ])
      }
    }
    else if (flag == "mothersFml") {

      let error = fullNameFML('mothersFml', 'من فضلك ادخل الاسم الكامل في خانة امهات من الرضاعة');

      if (error) {
        // setReda3aMothersArr([]);
        // console.log('error');
        // return;
      }
      else {
        setReda3aMothersArr([
          ...reda3aMothersArr,
          { name: reda3aMothersKey, gender: 'female', count: reda3aMothersArr.length + 1 }
        ])
      }
    }

    else if (flag === 'reda3aSons') {
      let error = fullNameFML('reda3aSons', 'من فضلك ادخل الاسم الكامل في خانة ابناء من الرضاعة');

      if (error) {
        console.log('error');
      }
      else {
        setReda3aSonsArr([
          ...reda3aSonsArr,
          { name: reda3aSonsKey, gender: reda3aSonsValues, count: reda3aSonsArr.length + 1 }
        ])
      }
    }



    setKey("");
    setEduKey("");
    setSocialKey("");
    setBrotherFMLKey("");
    setReda3aMothersKey("");
    setReda3aSonsKey("");

    setValue("");
    setEduValues("");
    setSocialValues("");
    setBrotherFMLValues("");
    setReda3aSonsValues("");
  };

  const deleteElement = (el, Arr, count = null) => {
    if (Arr === 'job') {
      // setJobArr(jobArr.filter((element) => el !== element));
      setJobArr(jobArr.filter(el => el.count !== count))
    }
    else if (Arr === 'education') {
      setEduArr(EduArr.filter((element) => el !== element));
    }
    else if (Arr === 'social') {
      setSocialMediaArr(socialMediaArr.filter((element) => el !== element));
    }

    else if (Arr === 'brothersFml') {
      setBrotherFMLArr(brotherFMLArr.filter((element) => el !== element));
    }

    else if (Arr === 'mothersFml') {
      setReda3aMothersArr(reda3aMothersArr.filter((element) => el !== element))
    }

    else if (Arr === 'reda3aSons') {
      setReda3aSonsArr(reda3aSonsArr.filter((element) => el !== element));
    }

  };

  //set father id
  const onChangeFatherId = (value) => {
    console.log(value);
    setFatherId(value);
  }

  //set First Name state
  const onChangeFirstname = (e) => {
    e.persist();
    setFirstname(e.target.value);
  };

  //set middle name
  const onChangeMiddleName = (e) => {
    e.persist();
    setMiddleName(e.target.value);
  }
  //setLastName
  const onChangeLastName = (e) => {
    e.persist();
    setLastName(e.target.value);
  }
  //setSurName
  const onChangeSurName = (e) => {
    e.persist();
    setSurName(e.target.value);
  }


  //set gender
  const onChangeGender = (e) => {
    e.persist();
    setGender(e.target.value);
    console.log(e.target.value)
  };
  //profile pic
  let onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImage(URL.createObjectURL(event.target.files[0]));
    // console.log(event.target.files[0]);
    setSelectedFile(event.target.files[0]);
  };

  //setZone
  let onChangeZone = (e) => {
    setZone(e.target.value);
  };

  //set  state
  const onChangeUserName = (e) => {
    e.persist();
    setUserName(e.target.value);
  };

  //set mobile state
  const onChangeMobile = (e) => {
    e.persist();
    setMobile(e.target.value);
  };
  // set Password state
  const onChangePassword = (e) => {
    e.persist();
    setPassword(e.target.value);
  };
  // set Confirm Password state
  const onChangeConfirmPassword = (e) => {
    e.persist();
    setConfirmPassword(e.target.value);
  };
  // set maritalStatus
  const onChangeMaritalStatus = (e) => {
    setMaritalStatus(e.target.value);
  };
  //set partener id
  const onChangePartnerId = (value) => {
    setPartnerId(value);
  };
  //  setPartnerNameFML
  const onChangePartnerNameFML = (e) => {
    // console.log('partner fml');
    setPartnerNameFML('');
    //let stringWithoutSpaces = e.target.value.replace(/\s/g, '');
    setValuesInFmlString(setPartnerNameFML, 'partnerFml');
    //setMotherFML(prevFML=>prevFML+ ' ' +stringWithoutSpaces);
    //setPartnerNameFML(prevFML=>prevFML+ ' ' +stringWithoutSpaces);
  };
  //setBirthDate
  const onChangeBirthDate = (e) => {
    setBirthDate(e.target.value);
  };
  //setJobArr
  const onChangeJobArr = (e) => {
    setJobArr(e.target.value);
  };
  //setEduArr
  const onChangeEduArr = (e) => {
    setEduArr(e.target.value);
  };
  //setCityId
  const onChangeCityId = (e) => {
    setCityId(e.target.value);
  };
  //setSocialMediaArr
  const onChangeSocialMediaArr = (e) => {
    setSocialMediaArr(e.target.value);
  };
  // setRole
  const onChangeRole = (e) => {
    setRole(e.target.value);
  };
  //setMotherId
  const onChangeMotherId = (value) => {
    setMotherId(value);
  };
  //setMotherFML
  const onChangeMotherFML = (e) => {
    //console.log('mother fml');
    let mother = motherFML;
    console.log(mother);
    //let stringWithoutSpaces = e.target.value.replace(/\s/g, '');
    setMotherFML("");

    setValuesInFmlString(setMotherFML, 'motherFml');
  };
  //setContactArr
  const onChangeContactArr = (e) => {
    setContactArr(e.target.value);
  };
  //setDescription
  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };
  //setCountryId
  const onChangeCountryId = (e) => {
    //console.log(e.target.value)
    setCountryId(e.target.value);
  };
  //set is dead
  const onChangeIsDead = (e) => {
    setIsDead(e.target.value);
  }

  // 
  const onChangeFacebook = (e) => {
    setFacebook(e.target.value);
  }

  const onChangeTwitter = (e) => {
    setTwitter(e.target.value);
  }

  const onChangeSnapchat = (e) => {
    setSnapchat(e.target.value);
  }


  // isVip
  const onChangeIsVip = (e) => {
    setIsVip(e.target.value);
  }

  // charMainCategoryVip
  const onChangeCharMainCategoryVip = async (e) => {
    setCharMainCategoryVip(e.target.value);
    await dispatch(getAllSubCharactersForOneMain(e.target.value));
  }

  let allSubForMainCharacter = useSelector((state) => state.subCharactersReducer.allSubCharacters);
  //console.log(allSubForMainCharacter);



  // charSubCategoryVip
  const onChangeCharSubCategoryVip = (e) => {
    setCharSubCategoryVip(e.target.value);
  }

  // descVip
  const onChangeDescVip = (e) => {


    const generateTableEducationBody = () => {
      return EduArr ?
        EduArr.map((el) => `
          <tr>
           <td>${el.count}</td>
           <td>${el.certificate}</td>
           <td>${el.universityname}</td>
         </tr>
           `).join('') : '';
    }

    const generateTableJobsBody = () => {
      return jobArr
        ? jobArr
          .map(
            (el) => `
            <tr>
              <td>${el.count}</td>
              <td>${el.companyname}</td>
              <td>${el.jobname}</td>
            </tr>
          `
          )
          .join('')
        : '';
    };

    let text = `
 
  الاسم :${firstname} ${middleName} ${lastName} ${surName}   <br>
   ولد في: ${birthDate}  <br>
   المراحل التعليمية:  <br>

   <Table
   striped
   bordered
   hover
   className="mt-3 mx-5 w-50"
   style={{ textAlign: "center" }}
 >
   <thead>
     <tr>
       <th>#</th>
       <th> المرحلة التعليمية</th>
       <th colSpan="2">الوصف </th>
     </tr>
   </thead>
   <tbody>
   ${generateTableEducationBody()}
   </tbody>
 </Table>

   التاريخ الوظيفي:     <br>

   <table class="table table-striped table-bordered table-hover mt-3 mx-5 w-50" style="text-align: center;">
   <thead>
     <tr>
       <th>#</th>
       <th>الشركة</th>
       <th colspan="2">الوظيفة</th>
     </tr>
   </thead>
   <tbody>
     ${generateTableJobsBody()}
   </tbody>
 </table>


   `;
    descVipRef.current.setContent(text);
  }

  // creatorVip
  const onChangeCreatorVip = (value) => {
    //setCreatorVip(e.target.value);
    setCreatorVip(value._id);
  }
  // imagesVip
  //to convert base 64 to file
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const itemImages = Array.from(Array(Object.keys(images).length).keys()).map(
    (item, index) => {
      return dataURLtoFile(images[index], Math.random() + ".png");
    }
  );



  // brothersReda3aFML
  // Reda3aMothersFML
  // sonsReda3aFML
  const onSubmit = async () => {

    //console.log(motherFML);
    setMotherFML('');
    setPartnerNameFML('');


    let error = fullNameFML('motherFml', "من فضلك ادخل اسم الام بالكامل");
    let error1 = fullNameFML('partnerFml', "من فضلك ادخل اسم شريك الحياة بالكامل");

    console.log(error)
    if (error || error1) return;

    //return;

    if (firstname == "") {
      notify("من فضلك ادخل الاسم الاول", "error");
      return;
    }
    if (middleName == "") {
      notify("من فضلك ادخل الاسم الثاني", "error");
      return;
    }
    if (lastName == "") {
      notify("من فضلك ادخل الاسم الثالث", "error");
      return;
    }
    if (surName == "") {
      notify("من فضلك ادخل الاسم الرابع", "error");
      return;
    }

    if (fatherId == "") return notify("من فضلك ادخل اسم الاب", "error");
    
    let fullNameObj = {
      firstname: firstname,
      middlename: middleName,
      lastname: lastName,
      surname: surName
    }

    // console.log('fullNameObj');
    // console.log(fullNameObj);

    //return;

    if (username == "") {
      notify("من فضلك ادخل اسم المستخدم", "error");
      return;
    }
    if (mobile == "") {
      notify("من فضلك ادخل رقم الجوال", "error");
      return;
    }
    // if(selectedFile=="")
    // {
    //   notify("من فضلك قم برفع الصورة", "error");
    //   return;
    // }
    if (gender === '') {
      notify("من فضلك ادخل خانة النوع", "error");
      return;
    }
    if (maritalStatus === '') {
      notify("من فضلك ادخل الحالة الاجتماعية", "error");
      return;
    }

    


    setLoading(true);
    //
    //   facebook
    // twitter
    // snapchat
    const formData = new FormData();


    if (fatherId !== "") formData.append("fatherId", fatherId?._id);
    if (motherId !== "") formData.append("motherId", motherId?._id);

    formData.append("fullname", JSON.stringify(fullNameObj));
    formData.append("gender", gender);
    formData.append("zone", zone);

    formData.append("image", selectedFile);
    formData.append("username", username);
    formData.append("mobile", mobile);
    formData.append("password", password);
    formData.append("maritalStatus", maritalStatus);

    if (partnerId !== "") formData.append("partnerId", partnerId._id);

    formData.append("partnerNameFML", partnerNameFML);
    if (birthDate) formData.append("birthdate", birthDate);

    // brothersReda3aFML
    // Reda3aMothersFML
    // sonsReda3aFML
    formData.append("jobArr_Co_and_Title", JSON.stringify(jobArr));
    formData.append("EduArr_Type_and_desc", JSON.stringify(EduArr));
    //formData.append("socialMediaArr", JSON.stringify(socialMediaArr));
    formData.append("brothersReda3aFML", JSON.stringify(brotherFMLArr));
    formData.append("Reda3aMothersFML", JSON.stringify(reda3aMothersArr));
    formData.append("sonsReda3aFML", JSON.stringify(reda3aSonsArr));

    if (countryId) formData.append("country", countryId);
    if (cityId) formData.append("city", cityId);
    formData.append("role", role);


    formData.append("motherFML", motherFML);
    formData.append("isDead", isDead);

    formData.append("facebook", facebook);
    formData.append("twitter", twitter);
    formData.append("snapchat", snapchat);

    //vip keys 
    if (isVip === 'true') {
      if (charMainCategoryVip === "") {
        notify("من فضلك ادخل التصنيف الرئيسي للشخصيات", "error");
        return;
      }
      if (charSubCategoryVip === "") {
        notify("من فضلك ادخل التصنيف الفرعي للشخصيات", "error");
        return;
      }

      if (creatorVip === "") {
        notify("من فضلك ادخل اسم المعد للشخصيات", "error");
        return;
      }

      formData.append("isVip", isVip);
      formData.append("charMainCategory_vip", charMainCategoryVip);
      formData.append("charSubCategory_vip", charSubCategoryVip);
      formData.append("desc_vip", descVipRef.current.getContent());
      if (itemImages.length > 0) itemImages.map((item) => formData.append("images", item));
      formData.append("creator_vip", creatorVip);
    }



    await dispatch(addUser(formData));
    setLoading(false);
  };

  const res = useSelector((state) => state.usersReducers.addUser);

  useEffect(() => {
    if (res) {
      if (res.status == 201) {
        notify("تم الاضافة بنجاح", "success");
        setTimeout(() => {
          navigate(`/admin/allusers?page=1`);
        }, 1500);
      }
      else if (res.status == 401) {
        notify(" هذا المستخدم موجود بالفعل من فضلك ادخل اسم مستخدم اخر", "error");
      }
      else if (res.status == 402) {
        notify(" هذا رقم الجوال موجود بالفعل من فضلك ادخل رقم اخر", "error");
      }
      else {
        notify("فشل في عملية الاضافة", "error");
      }
    }


  }, [res]);


  // reda3aMothersKey
  // reda3aMothersArr
  // onChangeReda3aMothersKey

  // showReda3aSons
  //   reda3aSonsKey
  // reda3aSonsValues
  // onChangeReda3aSonsKey
  // onChangeReda3aSonsValues

  return [
    firstname,
    middleName,
    lastName,
    surName,

    gender,
    zone,
    img,
    selectedFile,
    username,
    password,
    confirmPassword,
    maritalStatus,
    partnerId,
    partnerNameFML,
    birthDate,
    jobArr,
    EduArr,
    cityId,
    socialMediaArr,
    brotherFMLArr,
    reda3aSonsArr,

    role,
    motherId,
    fatherId,
    motherFML,
    contactArr,
    description,
    countryId,
    mobile,
    isDead,
    facebook,
    twitter,
    snapchat,
    onChangeFirstname,
    onChangeMiddleName,
    onChangeLastName,
    onChangeSurName,

    onChangeGender,
    onChangeZone,
    onImageChange,
    onChangeUserName,
    onChangeMobile,
    onChangePassword,
    onChangeConfirmPassword,
    onChangeMaritalStatus,
    onChangePartnerId,
    onChangePartnerNameFML,
    onChangeBirthDate,
    onChangeJobArr,
    onChangeEduArr,
    onChangeCityId,
    onChangeSocialMediaArr,
    reda3aMothersArr,

    onChangeRole,
    onChangeMotherId,
    onChangeMotherFML,
    onChangeContactArr,
    onChangeDescription,
    onChangeCountryId,
    onChangeFatherId,
    onChangeIsDead,
    onChangeFacebook,
    onChangeTwitter,
    onChangeSnapchat,

    allUsers,
    mothers,
    countries,
    cities,
    fathers,
    key,
    onChangeKey,
    eduKey,
    onChangeEduKey,
    socialKey,
    onChangeSocialKey,
    onChangeBrotherFMLKey,
    reda3aMothersKey,
    brotherFMLKey,
    onChangeReda3aMothersKey,
    reda3aSonsKey,
    onChangeReda3aSonsKey,

    value,
    onChangeValue,
    eduValues,
    setEduValues,
    onChangeEduValues,
    socialValues,
    onChangesocialValues,
    brotherFMLValues,
    onChangeBrotherFMLValues,
    reda3aSonsValues,
    onChangeReda3aSonsValues,

    tablekeysValues,
    clickHandler,
    show,
    showEducation,
    showSocial,
    showBrothersFML,
    showReda3aMothers,
    showReda3aSons,

    handleShow,
    deleteElement,

    images,
    setImages,
    isVip,
    charSubCategoryVip,
    charMainCategoryVip,
    creatorVip,
    onChangeIsVip,
    onChangeCharMainCategoryVip,
    onChangeCharSubCategoryVip,
    onChangeDescVip,
    onChangeCreatorVip,
    allMainCharacters,
    allSubForMainCharacter,
    descVip,
    setDescVip,

    onSubmit,
    loading
  ];
};

export default AdminAddUserHook;
