import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminEditSubCharacterHook from "../../hook/admin/AdminEditSubCharacterHook";

const AdminEditSubCharacter = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [name, onChangeName, onSubmit] = AdminEditSubCharacterHook(id);

 
 const color = useSelector(
    (state) => state.settingsReducers.oneSettings.primaryColor
  );

  return (
    <div>
    <title>تعديل تصنيف فرعي</title>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">تعديل تصنيف فرعي</div>
        <Col sm="8">
          <label htmlFor="firstName" className="d-block mt-3">
            الاسم
          </label>
          <input
            id="firstName"
            value={name}
            onChange={onChangeName}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder="ادخل الاسم"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: color }}
          >
            تعديل
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );

 }
 export default AdminEditSubCharacter;