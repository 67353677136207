// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import { Editor } from '@tinymce/tinymce-react';
//import tinymce from 'tinymce/tinymce';
import { useEffect, useRef } from 'react';

export default function TextEditor({ value, descRef }) {

  //const editorRef = useRef(null);

  console.log('editorRef.current');
 
  // useEffect(() => {
  //   tinymce.init({
  //     selector: '#myTextarea',
  //     plugins: ['advlist link image imagetools code'],
  //     toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | link image | code',
  //     menubar: false,
  //     branding: false,
  //   });
  // }, []);

  return (
    <Editor
      apiKey="bbm3maug4i41iklcsai09c2zxkf1j8i27fday07tqme1jdtf"
      onInit={(evt, editor) => descRef.current = editor}
      // onInit={(evt, editor) => (descRef.current = editor)}
      initialValue={value}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
        ],
        toolbar: 'undo redo | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />
    // <div style={{height:'100px',marginBottom:'2%'}}>
    // <ReactQuill  theme="snow" value={value} onChange={onChange} />
    // </div>

  )
}
