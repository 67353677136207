import {
    GET_GALLERY_DETAILS_FOR_GALLERY,
    UPDATE_ONE_GALLERY_DETAILS,
    ADD_GALLERY_DETAILS,
    GET_ONE_GALLERY_DETAILS
 } from "../type";

import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateDataWithImage } from "../../hooks/useUpdateData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";

//get all gallary details for one gallery
export const getAllGallaryDetailsForOneGallery= (id) =>async(dispatch) =>{
    try {
        const response = await useGetDataToken(`/api/v1/gallerydetails/gallery/${id}`);
    
        dispatch({
          type: GET_GALLERY_DETAILS_FOR_GALLERY,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: GET_GALLERY_DETAILS_FOR_GALLERY,
          payload: error,
        });
      }
}

//update gallery details
export const updateOneGalleryDetails=(id,body) => async(dispatch) =>{
    try {
        const response = await useUpdateDataWithImage(`/api/v1/gallerydetails/${id}`, body);
    
        dispatch({
          type: UPDATE_ONE_GALLERY_DETAILS,
          payload: response,
          loading: true,
        });
      } catch (error) {
        dispatch({
          type: UPDATE_ONE_GALLERY_DETAILS,
          payload: error,
        });
      }
}

//add gallary details
export const addGallaryDetails=(body) =>async(dispatch)=>{
    try {
        const response = await useInsertDataWithImage(`/api/v1/gallerydetails`, body);
    
        dispatch({
          type:ADD_GALLERY_DETAILS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: ADD_GALLERY_DETAILS,
          payload: error,
        });
      }
}

//get one gallery details
export const getOneGalleryDetails=(id) =>async(dispatch) =>{
    try {
      const response = await useGetDataToken(`/api/v1/gallerydetails/${id}`);
  
      dispatch({
        type: GET_ONE_GALLERY_DETAILS,
        payload: response,
        loading: true,
      });
    } catch (error) {
      dispatch({
        type: GET_ONE_GALLERY_DETAILS,
        payload: error,
      });
    }
  }