import notify from "../../hook/useNotification";

export const fullNameFML = (className,alert) => {
    let FmlClass=document.getElementsByClassName(className);
    let flag=0;
    let error=0;
    
    for(let i=0; i<FmlClass.length ; i++)
    {
     if(FmlClass[0].value)
     {
         flag=1;
     }
 
     if(flag)
     {
        if(FmlClass[i].value==='')
        {
        // error=1;
         notify(alert, "error");
         return true;
        }
     }
       FmlClass[i].value='';
    }

    // if(error==1) return true;

     return false;
 
};


