import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ViewAllGalleryDetailsHook from "../../hook/admin/ViewAllGalleryDetailsHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import AddButton from "../Utilities/AddButton";
import { useTranslation } from "react-i18next";
import {formateDate} from "../../Components/Utilities/formatDate";
import Cookies from "js-cookie";

const AdminAllGalleryDetails = () => {
       //console.log('first')
       const { id } = useParams();
    //const[documents, clickHandler, handleShowFun]= ViewAllDocumentsHook();
    //const[gallaries, clickHandler, handleShowFun]=ViewAllGallariesHook();
  
    const navigate = useNavigate();
    const {t} = useTranslation();

    const[gallaryDetails, clickHandler, handleShowFun]=ViewAllGalleryDetailsHook(id);;
  
    

    let columns = [
        {
           name: 'الصور',
           cell: (row) => (
             // eslint-disable-next-line jsx-a11y/alt-text
             <img
               src={row.image}
               width="80px"
               height="80px"
               style={{
                 padding: "2px",
                 justifyContent: "center",
                 alignItems: "center",
                 objectFit: "contain",
               }}
             />
           ),
        },
        {
            name: 'اسم المستخدم',
            selector: "userId.fullname.firstname",
            sortable: true,
        },

        {
          name: t("modify"),
          cell: (row) => (
            <Button
              variant="secondary"
              onClick={() => clickHandler(row._id)}
              id={row._id}
            >
              {t("Modify")}
              <i className="fa fa-pencil-square-o mx-1" />
            </Button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
            name: t("state"),
            cell: (row) =>
              row.status == true ? (
                <Button
                  variant="danger"
                  onClick={() => {
                    handleShowFun(row._id, false);
                  }}
                  id={row._id}
                >
                  {t("block")}
                  <i className="fa fa-lock mx-1" />
                </Button>
              ) : (
                <Button
                  variant="success"
                  onClick={() => {
                    handleShowFun(row._id, true);
                  }}
                  id={row._id}
                >
                  {t("active")}
                  <i className="fa fa-unlock mx-1" />
                </Button>
              ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          }
      ];
    
      const tableData = {
        columns,
        data: gallaryDetails
      };
    
      return( 
   
      <div>
      <title>تفاصيل المعرض</title>
          <div className="d-flex justify-content-between">
            <div className="admin-content-text"> تفاصيل المعرض </div>
            <AddButton title='اضافة ' navigateTo={`/admin/addgallerydetails/${id}`} />
    
          </div>
    
          <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
            <DataTable
              noHeader
              defaultSortAsc={false}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
          <ToastContainer />
        </div>
      );
}

export default AdminAllGalleryDetails;