import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminEditMainCharacterHook from "../../hook/admin/AdminEditMainCharacterHook";
import avatar from "../../../src/Images/adminIcon.png";

const AdminEditMainCharacter = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  
 const[name, onChangeName, onSubmit] =AdminEditMainCharacterHook(id);

 const color = useSelector(
  (state) => state.settingsReducers.oneSettings.primaryColor
);

return (
  <div>
    <Row className="justify-content-start ">
      <div className="admin-content-text pb-4">اضافة تصنيف</div>
      <Col sm="8">
        <label htmlFor="firstName" className="d-block mt-3">
          الاسم
        </label>
        <input
          id="firstName"
          value={name}
          onChange={onChangeName}
          type="text"
          className="input-form d-block mt-2 px-3"
          placeholder="اسم الاسم"
        />
      </Col>
    </Row>
    <Row>
      <Col sm="8" className="d-flex justify-content-end ">
        <button
          onClick={onSubmit}
          className="btn-save d-inline mt-2 "
          style={{ backgroundColor: color }}
        >
          تعديل
        </button>
      </Col>
    </Row>
    <ToastContainer />
  </div>
);

}
export default AdminEditMainCharacter;