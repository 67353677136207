import React, { useState } from "react";
import { Row, Col, Modal, Button, Table } from "react-bootstrap";
import ProfileHook from "../../hook/admin/ProfileHook";
import editicon from "../../Images/edit.png";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const AdminProfile = () => {
  const { t } = useTranslation();

  const [
    user,
    handleShow,
    handleClose,
    handleSubmit,
    show,
    firstname,
   // lastname,
    mobile,
    //email,
    onChangeFirstname,
   // onChangeLastname,
    onChangeMobile,
  //  onChangeEmail,
    changePassword,
    oldpassword,
    newPassword,
    confirmPassword,
    onChangeOldPassword,
    onChangeNewPassword,
    onChangeConfirmPassword,
    showTable,
    oneUser
  ] = ProfileHook();

  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);
  return (
    <div>

      <title>{`${t("profile")}`} </title>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Modification of personal data")}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">
            <input
              value={firstname}
              onChange={onChangeFirstname}
              type="text"
              className="input-form d-block mt-3 px-3"
              placeholder={t("first name")}
            />
           
            <input
              value={mobile}
              onChange={onChangeMobile}
              type="text"
              className="input-form d-block  mt-3 px-3"
              placeholder={t("phone")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="font" variant="secondary" onClick={handleClose}>
            {t("retreat")}
          </Button>
          <Button className="font" variant="success" onClick={handleSubmit}>
            {t("save")}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="admin-content-text">{t("profile")}</div>
      <div className="col-lg-6 user-address-card-2 my-3 px-2">
        <Row className="d-flex justify-content-between pt-2">
          <Col xs="6" className="d-flex">
            <div className="p-2">اسم المستخدم:</div>
            <div className="p-1 item-delete-edit">
             {user?.username}
            </div>
          </Col>
          <Col xs="6" className="d-flex justify-content-end">
            <div className="d-flex mx-2" onClick={handleShow}>
              <img
                alt=""
                className="ms-1 mt-2"
                src={editicon}
                height="17px"
                width="15px"
              />
              <p className="item-delete-edit"> {t("Modify")}</p>
            </div>
          </Col>
        </Row>

        <Row className="">
          <Col xs="12" className="d-flex">
            <div className="p-2">{t("phone")}:</div>
            <div className="p-1 item-delete-edit">{user?.mobile}</div>
          </Col>
        </Row>
      
        <Row className="mt-5 user-address-card-3 p-5" style={{
          position: 'sticky'
        }}>
          <Col xs="10" sm="8" md="6" className="">
            <div className="admin-content-text mt-2">{t("change password")}</div>
            <label htmlFor="oldPassword" className="d-block mt-4 ">

              {t("old password")}
            </label>
            <input
              id="oldPassword"
              value={oldpassword}
              onChange={onChangeOldPassword}
              type="password"
              className="input-form d-block mt-1 px-3"
              placeholder={t("enter old password")}
            />
            <label htmlFor="newPassword" className="d-block mt-2 ">

              {t("new password")}
            </label>
            <input
              id="newPassword"
              value={newPassword}
              onChange={onChangeNewPassword}
              type="password"
              className="input-form d-block mt-3 px-3"
              placeholder={t("enter new password")}
            />
            <label htmlFor="verifyPassword" className="d-block mt-2 ">

              {t("confirm password")}
            </label>
            <input
              id="verifyPassword"
              value={confirmPassword}
              onChange={onChangeConfirmPassword}
              type="password"
              className="input-form d-block mt-3 px-3"
              placeholder={t("confirm new password")}
            />
            <button
              onClick={changePassword}
              className="btn-save d-inline mt-2 "
              style={{ backgroundColor: color }}
            >
              {t("save")}
            </button>
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminProfile;
