import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminAllMainCharacters from "../../Components/Admin/AdminAllMainCharacters";
import SideBar from "../../Components/Admin/SideBar";

const AdminAllMainCharactersPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
       <AdminAllMainCharacters />
      </div>
    </div>
  );
};

export default AdminAllMainCharactersPage;
