import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllNews , updateOneNews } from "../../redux/actions/newsActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";
import {formateDate} from "../../Components/Utilities/formatDate";

const ViewAllNewsHook = () => {
   
   const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const[currentPage,setCurrentPage]=useState(1);
  //console.log('object');
  useEffect(()=>{
   dispatch(getAllNews());
  },[]);


  const handleChangePage= (flag)=>{
    let page=currentPage;
    console.log('page');
    console.log(page);

    if(flag=='next') page++;
    else  page--;
    setCurrentPage(page);

    if(page>0)
    dispatch(getAllNews(page,20));
    else
    setCurrentPage(1);
  }

  let allNews = useSelector((state) => state.newsReducer.allNews);
  console.log(allNews);
   let news = [];
   if (allNews) news = allNews;

   const clickHandler = (id) => {
    navigate(`/admin/editnews/${id}`);
  };

  //updateOneGallary
  const handleShowFun = async (id, status) => {
    //console.log(id)
    setLoading(true);
    await dispatch(
        updateOneNews(id, {
          status: status
        })
      );
  
    setLoading(false);
  };

  const res = useSelector((state) => state.newsReducer.updateNews);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllNews());
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  return [news, clickHandler, handleShowFun,currentPage,handleChangePage];
}
export default ViewAllNewsHook;
