import React from "react";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminNewsActionsHook from "../../hook/admin/AdminNewsActionsHook";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const AdminNewsActions = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const[ actions ]= AdminNewsActionsHook(id);

  let columns = [
    {
      name:'النوع',
      selector: "type",
      sortable: true,
    },

    {
        name:'اسم المستخدم',
        selector: "userId.fullname.firstname",
        sortable: true,
    },
  

   
   
  ];

  const tableData = {
    columns,
    data: actions,
  };

  return (
    <div>
      <title>التفاعل </title>

    

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
 
  
}
export default AdminNewsActions;
