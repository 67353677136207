import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminAllCities from "../../Components/Admin/AdminAllCities";
import SideBar from "../../Components/Admin/SideBar";

const AdminAllCitiesPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
        <AdminAllCities />
      </div>
    </div>
  );
};

export default AdminAllCitiesPage;