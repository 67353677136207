import React from "react";
import AdminAllUsers from "../../Components/Admin/AdminAllUsers";
import SideBar from "../../Components/Admin/SideBar";
// import ViewAllUsersHook from "../../hook/admin/ViewAllUsersHook";
import Pagination from "../../Components/Utilities/Pagination";

const AdminAllUsersPage = () => {

  // const [allUsers, clickHandler, handleShowFun , getPage] = ViewAllUsersHook();

  // if (allUsers?.numberOfPages) var pageCount = allUsers?.numberOfPages;
  // else pageCount = 0;
 // let pageCount=30;

  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
        <AdminAllUsers />

      </div>
    </div>
  );
};

export default AdminAllUsersPage;
