import {
    GET_ALL_CONTACTS,
    GET_ONE_CONTACT,
    UPDATE_ONE_CONTACT,
    ADD_ONE_CONTACT
  } from "../type";
  import { useGetDataToken } from "../../hooks/useGetData";
  import { useUpdateDataWithImage } from "../../hooks/useUpdateData";
  import { useInsertDataWithImage } from "../../hooks/useInsertData";

  //get all contacts
  export const getAllContacts= () => async (dispatch) => {
    try {
        const response = await useGetDataToken(`/api/v1/contacts`);
    
        dispatch({
          type: GET_ALL_CONTACTS,
          payload: response,
          loading: true,
        });
      } catch (error) {
        dispatch({
          type: GET_ALL_CONTACTS,
          payload: error,
        });
      }
  };

  //update one contact
  export const updateOneContact=(id,body) =>async (dispatch) =>{
    try {
       // console.log('update action');
        const response = await useUpdateDataWithImage(`/api/v1/contacts/${id}`, body);
    
        dispatch({
          type: UPDATE_ONE_CONTACT,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: UPDATE_ONE_CONTACT,
          payload: error,
        });
      }
  }

  //get one contact
  export const getOneContact= (id) =>async (dispatch) =>{
    try {
        const response = await useGetDataToken(`/api/v1/contacts/${id}`);
    
        dispatch({
          type: GET_ONE_CONTACT,
          payload: response,
          loading: true,
        });
      } catch (error) {
        dispatch({
          type: GET_ONE_CONTACT,
          payload: error,
        });
      }
  };

  //add one contact
  export const addOneContact= (body) =>async (dispatch) =>{
    try {
        const response = await useInsertDataWithImage(`/api/v1/contacts`, body);
    
        dispatch({
          type: ADD_ONE_CONTACT,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: ADD_ONE_CONTACT,
          payload: error,
        });
      }
  }
