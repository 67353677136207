import {
    GET_NEWS_ACTIONS_FOR_ONE_NEWS
 } from "../type";

import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateDataWithImage } from "../../hooks/useUpdateData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";

//get actions for one news
export const getOneNewsActions = (id) =>async(dispatch)=>{
    try {
      const response = await useGetDataToken(`/api/v1/newactions/${id}`);
  
      dispatch({
        type: GET_NEWS_ACTIONS_FOR_ONE_NEWS,
        payload: response,
        
      });
    } catch (error) {
      dispatch({
        type: GET_NEWS_ACTIONS_FOR_ONE_NEWS,
        payload: error,
      });
    }
  }
