import {
    GET_ALL_News,
    UPDATE_ONE_NEWS,
    GET_ONE_NEWS,
    ADD_NEWS
    
  } from "../type";
  const inital = {
    allNews:[],
    updateNews:[],
    addNews:[],
    oneNews:[]
  };
  
const newsReducer = (state = inital, action) => {
    switch (action.type) {
        case GET_ALL_News:
            return{
                ...state,
                allNews:action.payload
            };

        case UPDATE_ONE_NEWS:
            return{
                 ...state,
                 updateNews:action.payload
            };

        case GET_ONE_NEWS:
            return{
                ...state,
                oneNews:action.payload
            };

        case ADD_NEWS:
            return{
                ...state,
                addNews:action.payload
            };
    
        default:
            return state;
    }
}
export default newsReducer;