import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";
import avatar from "../../../src/Images/adminIcon.png";
import { addOneGallary } from "../../redux/actions/gallariesActions";

const AdminAddGalleryHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [img, setImg] = useState(avatar);
  const [selectedFile, setSelectedFile] = useState(null);
  const[status,setStatus]=useState("جاهز للمشاركة");
  const[eventDate,setEventDate]=useState("");
  
  let onChangeTitle = (e) => {
    e.persist();
    setTitle(e.target.value);
  };

  //image
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImg(URL.createObjectURL(event.target.files[0]));
      setSelectedFile(event.target.files[0]);
  };

  const onChangeStatus= (e) =>{
    e.persist();
    setStatus(e.target.value);
  }

  const onChangeEventDate = (e) =>{
    e.persist();
    setEventDate(e.target.value);
  }

  const onSubmit = async() =>{
     if(title=='')
     {
        notify("من فضلك ادخل العنوان", "error");
        return;
     }

     if(selectedFile=="")
     {
      notify("من فضلك قم برفع الصورة", "error");
      return;
     }

        setLoading(true);
        const formData = new FormData();
        formData.append("title", title);
        formData.append("image", selectedFile);
        formData.append("statusIsShareIsLiveNoActive", status);
        formData.append("eventDate", eventDate);
        await dispatch(addOneGallary(formData));
        setLoading(false);
  }

  const res = useSelector((state) => state.gallariesReducer.addGallary);
  
    useEffect(() => {
      if (loading === false) {
        if (res) {
          notify("تم الاضافة بنجاح", "success");
          setTimeout(() => {
            navigate("/admin/galleries");
          }, 1500);
        } else {
          notify("فشل في عملية الاضافة", "error");
        }
      }
    }, [loading]);

  return[
    title,
    img,
    selectedFile,
    status,
    eventDate,
    onChangeTitle,
    onImageChange,
    onChangeStatus,
    onChangeEventDate,
    onSubmit  
  ];

}

export default AdminAddGalleryHook;
