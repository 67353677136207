import React from "react";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminEditUserSettingsHook from "../../hook/admin/AdminEditUserSettingsHook";
import AsyncSelect from "react-select/async";

const AdminEditUserSettings = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [
    showNews,
    newBorn,
    showDeath,
    showMonasba,
    showAlwaysLogin,
    userFaceId,

    onChangeShowNews,
    onChangeNewBorn,
    onChangeShowDeath,
    onChangeShowMonasba,
    onChangeShowAlwaysLogin,
    onChangeUserFaceId,
    onSubmit
  ] = AdminEditUserSettingsHook(id);

  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  return (
    <div>
      <title>تعديل اعدادات المستخدم </title>
      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">تعديل اعدادات المستخدم</div>

        <Col sm="8">
          <label htmlFor="news" className="d-block mt-2 ">
            اظهار اشعارات اخر الاخبار
          </label>
          <select
            id="news"
            value={showNews}
            onChange={onChangeShowNews}
            className="select input-form-area mt-2 px-3 "
          >
            <option value="true">نعم</option>
            <option value="false">لا</option>
          </select>

          <label htmlFor="newBorn" className="d-block mt-2 ">
            اظهار اشعارات اخر المواليد
          </label>
          <select
            id="newBorn"
            value={newBorn}
            onChange={onChangeNewBorn}
            className="select input-form-area mt-2 px-3 "
          >
            <option value="true">نعم</option>
            <option value="false">لا</option>
          </select>

          <label htmlFor="showDeath" className="d-block mt-2 ">
            اظهار اشعارات الوفيات
          </label>
          <select
            id="showDeath"
            value={showDeath}
            onChange={onChangeShowDeath}
            className="select input-form-area mt-2 px-3 "
          >
            <option value="true">نعم</option>
            <option value="false">لا</option>
          </select>

          <label htmlFor="showMonasba" className="d-block mt-2 ">
            اظهار اشعارات المناسبات
          </label>
          <select
            id="showMonasba"
            value={showMonasba}
            onChange={onChangeShowMonasba}
            className="select input-form-area mt-2 px-3 "
          >
            <option value="true">نعم</option>
            <option value="false">لا</option>
          </select>

          <label htmlFor="showAlwaysLogin" className="d-block mt-2 ">
            البقاء متصلا
          </label>
          <select
            id="showAlwaysLogin"
            value={showAlwaysLogin}
            onChange={onChangeShowAlwaysLogin}
            className="select input-form-area mt-2 px-3 "
          >
            <option value="true">نعم</option>
            <option value="false">لا</option>
          </select>

          <label htmlFor="userFaceId" className="d-block mt-2 ">
            استخدام بصمة الوجه
          </label>
          <select
            id="userFaceId"
            value={userFaceId}
            onChange={onChangeUserFaceId}
            className="select input-form-area mt-2 px-3 "
          >
            <option value="true">نعم</option>
            <option value="false">لا</option>
          </select>
        </Col>
      </Row>

      <Row>
          <Col sm="8" className="d-flex justify-content-end ">
            <button
              onClick={onSubmit}
              className="btn-save d-inline mt-3 "
              style={{ backgroundColor: color }}
            >
              {t("edit")}
            </button>
          </Col>
        </Row>
  
        <ToastContainer />
        
    </div>
  );
};

export default AdminEditUserSettings;
