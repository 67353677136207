import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";
import avatar from "../../../src/Images/adminIcon.png";
import { getOneGalleryDetails,updateOneGalleryDetails } from "../../redux/actions/galleryDetailsActions";
import { getAllUsers } from "../../redux/actions/usersActions";

const AdminEditGalleryDetailsHook = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [img, setImg] = useState(avatar);
  const [selectedFile, setSelectedFile] = useState(null);
  const [galleryId, setGalleryId] = useState("");
  const [userId, setUserId] = useState("");
  //const[oneGallery,setOneGallery]=useState([]);
  
   async function get()
  {
    await dispatch(getOneGalleryDetails(id));
    await dispatch(getAllUsers()); 
  }
  useEffect(()=>{
    get();
  },[]);

  let Users = useSelector((state) => state.usersReducers.allUser);
  let allUsers;
  if (Users) allUsers = Users;

  let oneGallariesDetails = useSelector((state) => state.galleryDetailsReducer.oneGalleryDetails);

  useEffect(()=>{
      if(oneGallariesDetails)
      {
          setImg(oneGallariesDetails.image);
          setGalleryId(oneGallariesDetails.galleryId?._id);
          setUserId(oneGallariesDetails.userId?._id);
      }
  },[oneGallariesDetails]);

   //set image
   const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImg(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };
  //set user id
  const onUserIdChange = (e) => {
    setUserId(e.target.value);
  };

  const onSubmit = async () => {
    if(userId=="")
    {
        notify("من فضلك قم قم ب اختيار مشتخدم", "error");
        return;
    }
    if(selectedFile=="")
     {
      notify("من فضلك قم برفع الصورة", "error");
      return;
     }

        setLoading(true);
        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("image", selectedFile);
        formData.append("galleryId", galleryId);
        await dispatch(updateOneGalleryDetails(id,formData));
        setLoading(false);
  };

  const res = useSelector((state) => state.galleryDetailsReducer.updateGalleryDetails);
  
  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          navigate(`/admin/gallarydetails/${galleryId}`);
        }, 1500);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  return [img, selectedFile, userId ,allUsers, onImageChange, onUserIdChange, onSubmit];

  

}
export default AdminEditGalleryDetailsHook;
