import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";

import {
  getOneMainCharacter,
  updateOneMainCharacter
} from "../../redux/actions/mainCharactersActions";

const AdminEditMainCharacterHook = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const[name,setName]=useState("");

  useEffect(()=>{
   dispatch(getOneMainCharacter(id));
  },[]);

  let oneMainCharacter = useSelector((state) => state.mainCharactersReducer.getOneMainCharacter);
  //console.log(oneMainCharacter);

  useEffect(() => {
    if (oneMainCharacter) {
      console.log('first');
      setName(oneMainCharacter.name);
    }
  }, [oneMainCharacter]);

  const onChangeName = (e) => {
    setName(e.target.value);
  };

  const onSubmit = async () => {
    if (name === "") {
        notify("من فضلك اكمل البيانات", "error");
        return;
      }

      setLoading(true);
      await dispatch(
        updateOneMainCharacter(id,{
          name: name
        })
      );
      setLoading(false);
    }

    const res = useSelector((state) => state.mainCharactersReducer.updateOneMainCharacter);

    useEffect(() => {
      if (loading === false) {
        if (res) {
          notify("تم التعديل بنجاح", "success");
          setTimeout(() => {
            navigate("/admin/mainchar");
          }, 1500);
        } else {
          notify("فشل في عملية التعديل", "error");
        }
      }
    }, [loading]);

    return [name, onChangeName, onSubmit];
}
export default AdminEditMainCharacterHook;