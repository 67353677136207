const FullNameFmlAddInputs = ({onChange,className})=>{

    return(

        <div >
    
        <input
          
          onBlur={onChange}
          type="text"
          className={`mt-2 px-3 ${className}`}
          style={{'width':'24%'}}
          placeholder='الاسم الاول'
          pattern="\S+"
        />
      
        
        <input    
          
          onBlur={onChange}
          type="text"
          className={`mt-2 mx-2 px-3 ${className}`}
          style={{'width':'24%'}}
          placeholder='الاسم الثاني'
          pattern="\S+"
        />
      
        <input          
             
            onBlur={onChange}
            type="text"
            className={`mt-2 mx-2 px-3 ${className}`}
            style={{'width':'24%'}}
            placeholder='الاسم الثالث'
            pattern="\S+"
          />
      
          <input
            
            onBlur={onChange}
            type="text"
            className={`mt-2 px-3 ${className}`}
            style={{'width':'24%'}}
            placeholder='الاسم الرابع'
            pattern="\S+"
          />
      
      
        </div>
    );
}

export default FullNameFmlAddInputs;