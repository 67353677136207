import {
    GET_ALL_News,
    UPDATE_ONE_NEWS,
    GET_ONE_NEWS,
    ADD_NEWS
 } from "../type";

import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateDataWithImage } from "../../hooks/useUpdateData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";

//get all news
export const getAllNews= () =>async(dispatch) =>{
    try {
        const response = await useGetDataToken(`/api/v1/news?web=1`);
    
        dispatch({
          type: GET_ALL_News,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: GET_ALL_News,
          payload: error,
        });
      }
}

//update one news
export const updateOneNews = (id,body) => async(dispatch) =>{
    try {
        const response = await useUpdateDataWithImage(`/api/v1/news/${id}`, body);
    
        dispatch({
          type: UPDATE_ONE_NEWS,
          payload: response,
          loading: true,
        });
      } catch (error) {
        dispatch({
          type: UPDATE_ONE_NEWS,
          payload: error,
        });
      }
}

//add one news
export const addOneNews=(body) =>async(dispatch) =>{
  try {
    const response = await useInsertDataWithImage(`/api/v1/news`,body);

    dispatch({
      type: ADD_NEWS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: ADD_NEWS,
      payload: error,
    });
  }
}

//get one news
export const getOneNews = (id) =>async(dispatch)=>{
  try {
    const response = await useGetDataToken(`/api/v1/news/${id}`);

    dispatch({
      type: GET_ONE_NEWS,
      payload: response,
      
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_NEWS,
      payload: error,
    });
  }
}
