import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";
import avatar from "../../../src/Images/adminIcon.png";
import { addOneDocument } from "../../redux/actions/documentsActions";

const AdminAddDocumentHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [title, setTitle] = useState("");
  const [img, setImg] = useState(avatar);
  const [selectedFile, setSelectedFile] = useState(null);
  const [pdffile, setPdfFile] = useState(avatar);
  const [selectedFilePdf, setSelectedFilePdf] = useState(null);
  const [isFixed, setIsFixed] = useState(true);

  let onChangeTitle = (e) => {
    e.persist();
    setTitle(e.target.value);
  };

  //image
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImg(URL.createObjectURL(event.target.files[0]));
      setSelectedFile(event.target.files[0]);
  };

  //pdf file
  const onPdfFileChange = (event) => {
    // console.log('done');
    if (event.target.files && event.target.files[0])
      setPdfFile(URL.createObjectURL(event.target.files[0]));
      setSelectedFilePdf(event.target.files[0]);
  };

  const onChangeIsFixed = (e) => {
    e.persist();
    if(e.target.value=="true") setIsFixed(true);
    if(e.target.value=="false") setIsFixed(false);
    
  };

  const onSubmit = async() => {
    if(title=='') 
    {
      notify("من فضلك ادخل العنوان", "error");
      return;
    }
    if(selectedFile=="")
    {
      notify("من فضلك قم برفع الصورة", "error");
      return;
    }
    if(selectedFilePdf=="")
    {
      notify("من فضلك قم برفع الملف المرفق", "error");
      return;
    }

        setLoading(true);
        const formData = new FormData();
        formData.append("title", title);
        formData.append("files", selectedFile);
        formData.append("files", selectedFilePdf);
        formData.append("isFixed", isFixed);
        
        await dispatch(addOneDocument(formData));
        setLoading(false);
  };

  useEffect(() => {
    if (loading === false) {
      //  console.log('done')
      
      notify("تم الإضافه  بنجاح", "success");
      setTimeout(() => {
        navigate(`/admin/documents`);
      }, 1500);
    }
  }, [loading]);

  return [
    title,
    img,
    selectedFile,
    pdffile,
    selectedFilePdf,
    isFixed,
    onChangeTitle,
    onImageChange,
    onPdfFileChange,
    onChangeIsFixed,
    onSubmit
  ];
};

export default AdminAddDocumentHook;
