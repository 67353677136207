import {
    GET_ALL_FIXED_PAGES,
    ADD_FIXED_PAGE,
    UPDATE_FIXED_PAGE,
    GET_ONE_FIXED_PAGE
  } from "../type";

import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateDataWithImage } from "../../hooks/useUpdateData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";

//get all fixed pages
export const getAllFixedPages= () =>async(dispatch)=>{
    try {
        const response = await useGetDataToken(`/api/v1/staticPages`);
    
        dispatch({
          type: GET_ALL_FIXED_PAGES,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: GET_ALL_FIXED_PAGES,
          payload: error,
        });
      }
}

//add fixed page
export const addFixedPage= (body)=>async(dispatch)=>{
  try {
    const response = await useInsertDataWithImage(`/api/v1/staticPages`, body);

    dispatch({
      type: ADD_FIXED_PAGE,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADD_FIXED_PAGE,
      payload: error,
    });
  }
}

//update fixed pages
export const updateFixedPage = (id,body)=> async(dispatch)=>{
  try {
    const response = await useUpdateDataWithImage(`/api/v1/staticPages/${id}`, body);

    dispatch({
      type: UPDATE_FIXED_PAGE,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_FIXED_PAGE,
      payload: error,
    });
  }

}
//get one fixed page
export const getOneFixedPage = (id) =>async(dispatch)=>{
  try {
    const response = await useGetDataToken(`/api/v1/staticPages/${id}`);

    dispatch({
      type: GET_ONE_FIXED_PAGE,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_FIXED_PAGE,
      payload: error,
    });
  }
}

