import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  FormControl,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import styles from '../../../src/index.css';

import user from "../../Images/adminIcon.png";
// import logo from "../../Images/logo.jpg";

import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useSelector } from "react-redux";


const NavBarLogin = () => {
  const languages = [
    {
      code: "en",
      name: "English",
      dir: "ltr",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
    },
  ];
  const [t, i18n] = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage]);
  const [userData, setUserData] = useState("");

  useEffect(() => {
    if (localStorage.getItem("user") != null) {
      setUserData(JSON.parse(localStorage.getItem("user")));
    }
  }, []);

  const logo = useSelector(
    (state) => state.settingsReducers.oneSettings.logo
  );
  const color = useSelector(
    (state) => state.settingsReducers.oneSettings.primaryColor
  );

  console.log(color)

  const logOut = () => {
    localStorage.removeItem("user");
    setUserData("");
  };

  const containerStyle = {
    backgroundColor:`${color}`,
    marginTop: '-9px',
    border: '0'
    
  };
  return (
    <Navbar
      className="sticky-top"
      variant="light"
      expand="sm"
      style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", backgroundColor: color && `${color}` }}
      
    >
      <Container>
      
      <Navbar.Brand >
      <a href="/">
        <img src={logo} className="logo2" alt="Logo" />
      </a>
    </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav"   />
        <Navbar.Collapse  style={containerStyle}   >

         
          <Nav className="me-auto" >
            {/*
            {i18n.language == "en" ? (
              <Nav.Link
                onClick={() => i18n.changeLanguage("ar")}
                className="nav-text langText d-flex mt-3 justify-content-center"
              >
                عربي
              </Nav.Link>
            ) : (
              <Nav.Link
                onClick={() => i18n.changeLanguage("en")}
                className="nav-text langText d-flex mt-3 justify-content-center"
              >
                English
              </Nav.Link>
            )}
            */}

            <div  >
            {userData != "" && (
              <NavDropdown 
              title={userData?.fullname?.firstname} 
              //  id="basic-nav-dropdown"
              //  style={containerStyle}
              
              
              >
              <div 
              className={`dropdown-menu show`}
               style={containerStyle}>
               
              <NavDropdown.Item 
              href="/"
              style={{backgroundColor: color }}
              >
              الصفحة الشخصية
              </NavDropdown.Item>     
              <NavDropdown.Divider />
                <NavDropdown.Item 
                onClick={logOut} 
                href="/"
                style={{backgroundColor: color }}
               
                >
                  {t("logout")}
                </NavDropdown.Item>
              </div>
           
              </NavDropdown>
            )}
            </div>
          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBarLogin;
