import React from "react";
import AdminEditNews from "../../Components/Admin/AdminEditNews";
import SideBar from "../../Components/Admin/SideBar";

const AdminEditNewsPage = ()=>{
    return (
        <div className="d-flex justify-content-start">
        <div>
          <SideBar />
        </div>
        <div className="p-4" style={{ width: '100%' }}>
         <AdminEditNews />
        </div>
      </div>
    )
}

export default AdminEditNewsPage;