import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountries,updateOneCountry } from "../../redux/actions/countriesActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";

const ViewAllCountriesHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getAllCountries());
  }, []);
  let allCountries = useSelector((state) => state.countriesReducer.allCountries);
  let countries = [];
  if (allCountries) countries = allCountries;



  //console.log(countries);
  const clickHandler = (id) => {
    navigate(`/admin/editcountry/${id}`);
  };

  //updateOneCountry
  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
        updateOneCountry(id, {
          status: status
        })
      );
  
    setLoading(false);
  };

  const res = useSelector((state) => state.countriesReducer.updateOneCountry);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllCountries());
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  return [countries, clickHandler, handleShowFun];

  
};

export default ViewAllCountriesHook;
