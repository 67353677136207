import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDocuments , updateOneDocument } from "../../redux/actions/documentsActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";

const ViewAllDocumentsHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    dispatch(getAllDocuments());
  },[]);

  let allDocuments= useSelector((state) => state.documentsReducer.allDocuments);
  let documents=[]
  if(allDocuments)  documents=allDocuments;
  console.log(documents);

  const clickHandler = (id) => {
    navigate(`/admin/editdocument/${id}`);
  };

  //updateOneCountry
  const handleShowFun = async (id, status) => {
    //console.log(id)
    setLoading(true);
    await dispatch(
        updateOneDocument(id, {
          status: status
        })
      );
  
    setLoading(false);
  };

  const res = useSelector((state) => state.documentsReducer.updateDocument);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllDocuments());
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  return [documents, clickHandler, handleShowFun];

  //console.log('first')
}

export default ViewAllDocumentsHook;