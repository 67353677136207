import React, { useEffect, useState ,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";

import {
  getOneFixedPage,
  updateFixedPage,
} from "../../redux/actions/fixedPagesActions";

const AdminEditFixedPagesHook = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const[type,setType]=useState("");

  const descRef=useRef();

  useEffect(() => {
    const get = async () => {
        await dispatch(getOneFixedPage(id));
      };
      get();
  }, []);

  let FixedPage = useSelector(
    (state) => state.fixedPagesReducer.oneFixedPage
  );

  

  useEffect(() => {
    if (FixedPage) {
      setTitle(FixedPage.title);
      setDescription(FixedPage.desc);
      descRef.current=FixedPage.desc;
      setImage(FixedPage.image);
      setType(FixedPage.type);
    }
   // setLoadingData(true);
  }, [FixedPage]);

  let onChangeSetTitle = (e) => {
    e.persist();
    setTitle(e.target.value);
  };

let onChangeSetDescription =(e) =>{
    e.persist();
    setDescription(e.target.value);
}

let onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImage(URL.createObjectURL(event.target.files[0]));
      setSelectedFile(event.target.files[0]);
  };

  let onChangeType= (e)=>{
    e.persist();
    setType(e.target.value);
  }

  

  let onSubmit= async ()=>{
    setLoading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("desc", descRef.current.getContent());
    formData.append("image", selectedFile);
    formData.append("type",type);
    
    await dispatch(updateFixedPage(id,formData));
    setLoading(false);
       
  }

  

useEffect(() => {
  if (loading === false) {
    //  console.log('done')
    setTitle("");
    setDescription("");
    notify("تم التعديل  بنجاح", "success");
    setTimeout(() => {
      navigate(`/admin/fixedpages`);
    }, 1500);
  }
}, [loading]);

  return [
        title,
        description,
        setDescription,
        descRef,
        image,
        type,
        onChangeSetTitle,
        onChangeSetDescription,
        onImageChange,
        onChangeType,
        onSubmit
  ];
};
export default AdminEditFixedPagesHook;
