import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminEditContactHook from "../../hook/admin/AdminEditContactHook";
import avatar from "../../../src/Images/adminIcon.png";
import MultiImageInput from "react-multiple-image-input";

const AdminEditContact = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [
    title,
    des,
    images,
    setImages,
    userId,
    users,
    onChangeTitle,
    onChangeDes,
    onChangeUserId,
    onSubmit,
  ] = AdminEditContactHook(id);
  //console.log(userId._id);

  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">تعديل التواصل</div>
        <Col sm="8">
          <label htmlFor="firstName" className="d-block mt-3">
            العنوان
          </label>
          <input
            id="firstName"
            onChange={onChangeTitle}
            value={title}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder="العنوان"
          />

          <div className="mt-3">
            <label className="d-block mt-2 mb-2">الصور</label>
            <MultiImageInput
              images={images}
              setImages={setImages}
              theme="light"
              max={10}
              // cropConfig={{ crop, ruleOfThirds: true }}
              allowCrop={false}
            />
          </div>

          <label className="d-block mt-2">اسم العضو</label>
          <select
            onChange={onChangeUserId}
            value={userId}
            className="select input-form-area mt-2 px-3 "
          >
            {users
              ? users.map((el) => {
                  return (
                    <option key={el._id} value={el._id}>
                      {el.fullname.firstname}
                    </option>
                  );
                })
              : null}
            )
          </select>

          <label htmlFor="Description" className="d-block mt-2 ">
            {t("Description")}
          </label>
          <textarea
            id="Description"
            onChange={onChangeDes}
            value={des}
            className="input-form-area p-2 mt-2"
            rows="4"
            cols="50"
            placeholder={t("Description")}
          />
        </Col>
      </Row>

      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-5 "
            style={{ backgroundColor: color }}
          >
            {t("edit")}
          </button>
        </Col>
      </Row>
    </div>
  );
};
export default AdminEditContact;
