import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ViewAllUsersHook from "../../hook/admin/ViewAllUsersHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddButton from "../Utilities/AddButton";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { IoIosSearch } from "react-icons/io";

import Pagination from "../Utilities/Pagination";

const AdminAllUsers = () => {
  const { t } = useTranslation();
  const [allUsers, clickHandler, handleShowFun, getPage, loading,searchByWord , cancelFilter] = ViewAllUsersHook();

  const navigate = useNavigate();
  const[searchParams,setSearchParams]=useSearchParams();

  if (allUsers?.numberOfPages) var pageCount = allUsers?.numberOfPages;
  else pageCount = 0;

  //  let pageCount=30;
  //   console.log('pageCount',pageCount);

  let columns = [
    {
      name: t("الاسم الاول"),
      selector: "fullname.firstname",
      sortable: true,
    },
    {
      name: t("phone"),
      selector: "mobile",
      sortable: true,
    },
    {
      name: t("username"),
      selector: "username",
      sortable: true,
    },
    {
      name: 'اعدادات المستخدم',
      cell: (row) => (
        <div
          style={{ cursor: 'pointer', color: 'blue', textDecorationLine: 'underline' }}
          variant='secondary'
          id={row._id}
          onClick={() => navigate(`/admin/usersettings/${row._id}`)}
        >
          {t("clickhere")}


        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },

    {
      name: t("modify"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("state"),
      cell: (row) =>
        row.status == true ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false, row.email);
            }}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true, row.email);
            }}
            id={row._id}
          >
            {t("active")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: allUsers?.data
  };

  // if(allUsers?.length==0) return;

  console.log('loading', loading);
  return (
    <div>

      <title>{`${t("users")}`} </title>



      <div className="d-flex justify-content-between">
        <div className="admin-content-text">{t("User Management")} </div>
        <AddButton title={t("add user")} navigateTo='/admin/addUser' />

      </div>
      <>

        <div className="d-flex justify-content-end my-4">
          <div className="d-flex gap-2">
            <input
            value={searchParams.get('keyword')}
            onChange={(e)=>{
              searchParams.set('keyword',e.target.value);
              setSearchParams(searchParams);
            }}  
            className="form-control" placeholder="ابحث هنا" />

            <select
            onChange={(e)=>{
              searchParams.set('type',e.target.value);
              setSearchParams(searchParams);
            }} 
            value={searchParams.get('type') ? searchParams.get('type') : '0'}
            className="form-control w-50"
            >
              <option value={'0'}> اختر النوع </option>
              <option value={'mobile'}> رقم الهاتف </option>
              <option value={'keyword'}> اسم المستخدم </option>
            </select>

            <button onClick={()=>searchByWord()} className="btn btn-primary" style={{width:'33%'}}> بحث <IoIosSearch /> </button>

            <button onClick={()=>cancelFilter()} className="btn btn-warning w-50" > الغاء الفلتر </button>
          </div>
        </div>
        <DataTableExtensions {...tableData}
          filter={false}
          print={false}
          export={false}
        >
          <DataTable
            noHeader
            defaultSortAsc={false}
            // pagination
            highlightOnHover
          />
        </DataTableExtensions>

        {loading && <Spinner animation="border" variant="primary" />}

        <div className="my-3">
          {pageCount > 1 ? <Pagination pageCount={pageCount} onPress={getPage} /> : null}
        </div>


      </>



      <ToastContainer />
    </div>
  );
};
export default AdminAllUsers;

