import {
  GET_ALL_MAIN_CHARACTERS,
  UPDATE_ONE_MAIN_CHARACTER,
  GET_ONE_MAIN_CHARACTER,
  ADD_MAIN_CHARACTER,
} from "../type";
const inital = {
  allMainCharacters: [],
  getOneMainCharacter: [],
  updateOneMainCharacter: [],
  addOneMainCharacter: [],
};

const mainCharactersReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_MAIN_CHARACTERS:
        return{
         ...state,
         allMainCharacters:action.payload
        };
    case UPDATE_ONE_MAIN_CHARACTER:
        return{
         ...state,
         updateOneMainCharacter:action.payload
        };
    case GET_ONE_MAIN_CHARACTER:
        return{
            ...state,
            getOneMainCharacter:action.payload
        };
    case ADD_MAIN_CHARACTER:
        return{
            ...state,
            addOneMainCharacter:action.payload
        };

    default:
            return state;
  }
};
export default mainCharactersReducer;
