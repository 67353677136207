import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./Pages/Auth/LoginPage";
import AdminAllUsersPage from "./Pages/Admin/AdminAllUsersPage";
import ProtectedRouteHook from "./hook/auth/ProtectedRouteHook";
import ProtectedRoute from "./Components/Utilities/ProtectedRoute";
import AdminEditUserPage from "./Pages/Admin/AdminEditUserPage";
import AdminAddUserPage from "./Pages/Admin/AdminAddUserPage";
import { Suspense, useEffect, lazy } from "react";
import AdminProfilePage from "./Pages/Admin/AdminProfilePage";
import NavBarLogin from "./Components/Utilities/NavBarLogin";
import AdminSettingsPage from "./Pages/Admin/AdminSettingsPage";
import AdminAllCountriesPage from "./Pages/Admin/AdminAllCountriesPage";
import AdminEditCountryPage from "./Pages/Admin/AdminEditCountryPage";
import AdminAddCountryPage from "./Pages/Admin/AdminAddCountryPage";
import AdminAllCitiesPage from "./Pages/Admin/AdminAllCitiesPage";
import AdminAddCityPage from "./Pages/Admin/AdminAddCityPage";
import AdminEditCityPage from "./Pages/Admin/AdminEditCityPage";
import AdminAllFixedPages from "./Pages/Admin/AdminAllFixedPages";
import AdminAddFixedPages from "./Pages/Admin/AdminAddFixedPages";
import AdminEditFixedPages from "./Pages/Admin/AdminEditFixedPages";
import AdminAllDocumentsPage from "./Pages/Admin/AdminAllDocumentsPage";
import AdminAddDocumentPage from "./Pages/Admin/AdminAddDocumentPage";
import AdminEditDocumentPage from "./Pages/Admin/AdminEditDocumentPage";
import AdminAllContactsPage from "./Pages/Admin/AdminAllContactsPage";
import AdminAddContactPage from "./Pages/Admin/AdminAddContactPage";
import AdminEditContactPage from "./Pages/Admin/AdminEditContactPage";
import AdminAllGalariesPage from "./Pages/Admin/AdminAllGalariesPage";
import AdminAddGalleryPage from "./Pages/Admin/AdminAddGalleryPage";
import AdminEditGalleryPage from "./Pages/Admin/AdminEditGalleryPage";
import AdminAllGalaryDetailsPage from "./Pages/Admin/AdminAllGalaryDetailsPage";
import AdminEditGalaryDetailsPage from "./Pages/Admin/AdminEditGalleryDetailsPage";
import AdminAddGalleryDetailsPage from "./Pages/Admin/AdminAddGalleryDetailsPage";
import AdminEditUserSettingsPage from "./Pages/Admin/AdminEditUserSettingsPage";
import AdminAllNewsPage from "./Pages/Admin/AdminAllNewsPage";
import AdminAddNewsPage from "./Pages/Admin/AdminAddNewsPage";
import AdminEditNewsPage from "./Pages/Admin/AdminEditNewsPage";
import AdminNewsActionsPage from "./Pages/Admin/AdminNewsActionsPage";
import AdminAllMainCharactersPage from "./Pages/Admin/AdminAllMainCharactersPage";
import AdminAddMainCharacterPage from "./Pages/Admin/AdminAddMainCharacterPage";
import AdminEditMainCharacterPage from "./Pages/Admin/AdminEditMainCharacterPage";
import AdminAllSubCharactersPage from "./Pages/Admin/AdminAllSubCharactersPage";
import AdminAddSubCharacterPage from "./Pages/Admin/AdminAddSubCharacterPage";
import AdminEditSubCharacterPage from "./Pages/Admin/AdminEditSubCharacterPage";
import { useSelector ,useDispatch } from "react-redux";
import { getOneSettings } from "./redux/actions/settingsActions";
import { getAllUsers, getOneUser } from "./redux/actions/usersActions";
import AdminPollsPage from "./Pages/Admin/AdminPollsPage";

import AdminAddPollPage from "./Pages/Admin/AdminAddPollPage";
import { ToastContainer } from "react-toastify";
import AdminEditPollsPage from "./Pages/Admin/AdminEditPollsPage";

// useEffect(()=>{
//   dispatch(getAllUsers());
// },[]);

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const run = async () => {
     
      await dispatch(getOneSettings());
      dispatch(getAllUsers());
     
    };
    run();
  }, []);
  let user = useSelector((state) => state.usersReducers.oneUser);

  

  const [isAdmin] = ProtectedRouteHook();

  console.log('isAdmin');
  console.log(isAdmin);

  

  const color = useSelector(
    (state) => state.settingsReducers.oneSettings.primaryColor
  );

  return (
    <div className="font">
      <BrowserRouter>
        <Suspense fallback="Loading ..........">
          <NavBarLogin />

          <Routes>
            {/* All Routes For Any One    */}
            <Route path="/login" element={<LoginPage />} />

            {/* All Route When Admin logged  auth={isAdmin}    */}
            <Route element={<ProtectedRoute  auth={isAdmin}  />} >
              <Route path="/admin/allusers" element={<AdminAllUsersPage />} />
              <Route
                path="/admin/edituser/:id"
                element={<AdminEditUserPage />}
              />
              <Route path="/admin/addUser" element={<AdminAddUserPage />} />
              <Route path="/admin/settings" element={<AdminSettingsPage />} />
              <Route index element={<AdminProfilePage />} />

              <Route
                path="/admin/countries"
                element={<AdminAllCountriesPage />}
              />
              <Route
                path="/admin/addcountry"
                element={<AdminAddCountryPage />}
              />
              <Route
                path="/admin/editcountry/:id"
                element={<AdminEditCountryPage />}
              />
              <Route
                path="/admin/cities/:id"
                element={<AdminAllCitiesPage />}
              />
              <Route path="/admin/addcity/:id" element={<AdminAddCityPage />} />
              <Route
                path="/admin/editcity/:id"
                element={<AdminEditCityPage />}
              />
              <Route
                path="/admin/fixedpages"
                element={<AdminAllFixedPages />}
              />
              <Route
                path="/admin/addFixedPage"
                element={<AdminAddFixedPages />}
              />
              <Route
                path="/admin/editFixedPage/:id"
                element={<AdminEditFixedPages />}
              />

              <Route
                path="/admin/documents"
                element={<AdminAllDocumentsPage />}
              />

              <Route
                path="/admin/addducument"
                element={<AdminAddDocumentPage />}
              />
              <Route
                path="/admin/editdocument/:id"
                element={<AdminEditDocumentPage />}
              />
              <Route
                path="/admin/contact"
                element={<AdminAllContactsPage />}
              />
              <Route
                path="/admin/addcontact"
                element={<AdminAddContactPage />}
              />
              <Route
                path="/admin/editcontact/:id"
                element={<AdminEditContactPage />}
              />
              <Route
                path="/admin/galleries"
                element={<AdminAllGalariesPage />}
              />

              <Route
                path="/admin/addgallery"
                element={<AdminAddGalleryPage />}
              />

              <Route
                path="/admin/editgallery/:id"
                element={<AdminEditGalleryPage />}
              />

              <Route
                path="/admin/gallarydetails/:id"
                element={<AdminAllGalaryDetailsPage />}
              />
              
              <Route
                path="/admin/editgallerydetails/:id"
                element={<AdminEditGalaryDetailsPage />}
              />

              <Route
                path="/admin/addgallerydetails/:id"
                element={<AdminAddGalleryDetailsPage />}
              />

              <Route
                path="/admin/usersettings/:id"
                element={<AdminEditUserSettingsPage />}
              />

              <Route
                path="/admin/allnews"
                element={<AdminAllNewsPage />}
              />

              <Route
                path="/admin/addnews"
                element={<AdminAddNewsPage />}
              />

              <Route
                path="/admin/editnews/:id"
                element={<AdminEditNewsPage />}
              />

              <Route
                path="/admin/newsaction/:id"
                element={<AdminNewsActionsPage />}
              />

              <Route
                path="/admin/mainchar"
                element={<AdminAllMainCharactersPage />}
              />
              
              <Route
                path="/admin/addmainchar"
                element={<AdminAddMainCharacterPage />}
              />

              <Route
                path="/admin/editmaincharacter/:id"
                element={<AdminEditMainCharacterPage />}
              />

              <Route
                path="/admin/subchar/:id"
                element={<AdminAllSubCharactersPage />}
              />

              <Route
                path="/admin/addsubchar/:id"
                element={<AdminAddSubCharacterPage />}
              />

              <Route
                path="/admin/editsubchar/:id"
                element={<AdminEditSubCharacterPage />}
              />


              <Route path="/admin/polls" element={<AdminPollsPage />} />
              <Route path="/admin/addPoll" element={<AdminAddPollPage />} />
              <Route path="/admin/editPoll/:id" element={<AdminEditPollsPage />} />




            </Route>
           
           
          </Routes>
        </Suspense>
      </BrowserRouter>

      <ToastContainer />
    </div>
  );
}

export default App;
