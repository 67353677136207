import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCitiesForOneCountry,updateOneCity } from "../../redux/actions/citiesActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";

const ViewAllCitiesHook = (id) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    
  
    useEffect(() => {
      dispatch(getAllCitiesForOneCountry(id));
    }, []);

    let allCities = useSelector((state) => state.citiesReducer.allCitiesInOneCountry);
    let cities = [];
    if (allCities) cities = allCities;

    const clickHandler = (id) => {
        navigate(`/admin/editcity/${id}`);
      };
    
      //updateOneCountry
      const handleShowFun = async (id, status) => {
        setLoading(true);
        await dispatch(
            updateOneCity(id, {
              status: status
            })
          );
      
        setLoading(false);
      };

      const res = useSelector((state) => state.citiesReducer.updateOneCity);

      useEffect(() => {
        if (loading === false) {
          if (res) {
            notify("تم التعديل بنجاح", "success");
            setTimeout(() => {
              dispatch(getAllCitiesForOneCountry(id));
            }, 1000);
          } else {
            notify("فشل في عملية التعديل", "error");
          }
        }
      }, [loading]);
    

    //console.log(cities);
    return [cities, clickHandler, handleShowFun];
}

export default ViewAllCitiesHook;