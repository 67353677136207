import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";

import {
  addOneContact
} from "../../redux/actions/contactsActions";
import { getAllUsers } from "../../redux/actions/usersActions";

const AdminAddContactHook = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [loadingData, setLoadingData] = useState(true);

    const[title,setTitle]=useState("");
    const[des,setDes]=useState("");
    const [images, setImages] = useState([]);
    const[userId,setUserId]=useState("");

    useEffect(()=>{
     dispatch(getAllUsers());
    },[]);

    let allUsers = useSelector((state) => state.usersReducers.allUser);
    let users=[];
    if(allUsers) users=allUsers;
    // console.log(allUsers);

    //set title
    const onChangeTitle= (e)=>{
        setTitle(e.target.value);
    }
    //set des
    const onChangeDes =(e) =>{
        setDes(e.target.value);
    }
    //set user id
    const onChangeUserId= (e) =>{
        setUserId(e.target.value);
    }

     //to convert base 64 to file
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

    const itemImages = Array.from(Array(Object.keys(images).length).keys()).map(
        (item, index) => {
          return dataURLtoFile(images[index], Math.random() + ".png");
        }
      );

      let onSubmit= async () =>{
        
        if(title=="")
        {
            notify("من فضلك ادخل العنوان", "error");
            return;
        }
        if(itemImages.length==0)
        {
            notify("من فضلك قم برفع الصور", "error");
            return;
        }
        if(userId=="")
        {
            notify("من فضلك قم ب اختيار العضو", "error");
            return;
        }
        console.log('itemImages');
        console.log(itemImages);
        setLoading(true);

        const formData= new FormData();
        formData.append("title", title);
        formData.append("des", des);
        formData.append("userId", userId);
        itemImages.map((item) => formData.append("images", item));
        await dispatch(addOneContact(formData));
        setLoading(false);
    }

    const res = useSelector((state) => state.contactsReducer.addContact);
  
    useEffect(() => {
      if (loading === false) {
        if (res) {
          notify("تم الاضافة بنجاح", "success");
          setTimeout(() => {
            navigate("/admin/contact");
          }, 1500);
        } else {
          notify("فشل في عملية الاضافة", "error");
        }
      }
    }, [loading]);

    return[
        title,
        des,
        images,
        setImages,
        userId,
        users,
        onChangeTitle,
        onChangeDes,
        onChangeUserId,
        onSubmit
    ];

    
}
export default AdminAddContactHook;