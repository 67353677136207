import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllContacts,updateOneContact } from "../../redux/actions/contactsActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";

const ViewAllContactsHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
     dispatch(getAllContacts());
  },[]);

  let allContacts = useSelector((state) => state.contactsReducer.allContacts);
  console.log(allContacts);
  let contacts = [];
  if (allContacts) contacts = allContacts;

  const clickHandler = (id) => {
    navigate(`/admin/editcontact/${id}`);
  };

  //updateOneCountry
  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
      updateOneContact(id, {
          status: status
        })
      );
  
    setLoading(false);
  };

  const res = useSelector((state) => state.contactsReducer.updateContact);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllContacts());
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading,res,dispatch]);

  return [contacts, clickHandler, handleShowFun];
  
}
export default ViewAllContactsHook;