import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import add from "../../Images/add.png";


const AddButton = ({ title, navigateTo }) => {

  const color = useSelector(
    (state) => state.settingsReducers.oneSettings.primaryColor
  );

  const navigate = useNavigate()
  return (
    <div>
      <Button
        style={{ backgroundColor: color ? color : '#c992f0', borderRadius: '6px', borderColor: '#fff' }}
        variant="secondary"
        onClick={() => navigate(`${navigateTo}`)}
      >
        {title}
        <img
          src={add}
          alt=""
          width="25px"
          height="25px"
          style={{ cursor: "pointer", marginRight: "8px", borderColor: 'black' }}
          onClick={() => navigate(`${navigateTo}`)}
        />
      </Button>
    </div>
  )
}

export default AddButton