export const UploadOneImg=({img,onImageChange,id})=>{
    return(
        <div>
        <label htmlFor={id}>
          <img
            src={img}
            alt="fzx"
            height="120px"
            width="120px"
            style={{
              cursor: "pointer", borderRadius: '50%'
            }}
          />
        </label>
        <input
          type="file"
          onChange={onImageChange}
          name="photo"
          id={id}
        />

      </div>
    );
};
