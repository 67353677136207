import {
    GET_ALL_CITIES_FOR_ONE_COUNTRY,
    ADD_CITY,
    GET_ONE_CITY,
    UPDATE_ONE_CITY
  } from "../type";
  const inital = {
    allCitiesInOneCountry:[],
    addCity:[],
    oneCity:[],
    updateOneCity:[]
  };
  
  const citiesReducer = (state = inital, action) => {
    switch (action.type) {
     case GET_ALL_CITIES_FOR_ONE_COUNTRY:
        return{
            ...state,
            allCitiesInOneCountry:action.payload
        };
    case ADD_CITY:
       return {
          ...state,
          addCity:action.payload
        };
    case GET_ONE_CITY:
        return{
         ...state,
         oneCity:action.payload
        };
    case UPDATE_ONE_CITY:
       return {
        ...state,
        updateOneCity:action.payload
        };
  
      default:
        return state;
    }
  };
  
  export default citiesReducer;
  