import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";

import {
  updateOneCity,
  getOneCity
} from "../../redux/actions/citiesActions";

const AdminEditCityHook = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [name, setName] = useState("");
  const[countryId,setCountryId]=useState("");

  useEffect(() => {
    const get = async () => {
      setLoadingData(true);
      await dispatch(getOneCity(id));
      //console.log('one country dispatch')
      setLoadingData(false);
    };
    get();
    //dispatch(getOneCountry(id));
  }, []);

  let oneCity = useSelector((state) => state.citiesReducer.oneCity);
  useEffect(() => {
    if (oneCity) {
      setName(oneCity.name);
      setCountryId(oneCity.country);
    }
  }, [loadingData]);

  let onChangeName = (e) => {
    e.persist();
    setName(e.target.value);
  };

  let onSubmit = async () => {
    if (name === "") {
      notify("من فضلك اكمل البيانات", "error");
      return;
    }
    setLoading(true);
    await dispatch(
        updateOneCity(id, {
        name: name,
      })
    );
    setLoading(false);
  };

  const res = useSelector((state) => state.citiesReducer.updateOneCity);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          navigate(`/admin/cities/${countryId}`);
        }, 1500);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);



  return [name, onChangeName, onSubmit];
};

export default AdminEditCityHook;
