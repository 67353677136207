import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ViewAllDocumentsHook from "../../hook/admin/ViewAllDocumentsHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddButton from "../Utilities/AddButton";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const AdminAllDocuments = () => {
    //   console.log('first')
    const[documents, clickHandler, handleShowFun]= ViewAllDocumentsHook();
  
    const navigate = useNavigate();
    const {t} = useTranslation();

   let columns = [
     {
       name: t("name"),
       selector: "title",
       sortable: true,
     },

     {
        name: t("image"),
        cell: (row) => (
          // eslint-disable-next-line jsx-a11y/alt-text
          <img
            src={row.mainImage}
            width="80px"
            height="80px"
            style={{
              padding: "2px",
              justifyContent: "center",
              alignItems: "center",
              objectFit: "contain",
            }}
          />
        ),
     },
     
     {
        name: 'المستند',
        cell: (row) => (
          <a
            style={{ cursor: 'pointer' }}
            variant='secondary'
            href={`${row.file}`} 
            target="_blank"
          >
            تحميل
            <i className="fa fa-folder-open-o mx-1" />
  
          </a>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
      },
  
     {
       name: t("modify"),
       cell: (row) => (
         <Button
           variant="secondary"
           onClick={() => clickHandler(row._id)}
           id={row._id}
         >
           {t("Modify")}
           <i className="fa fa-pencil-square-o mx-1" />
         </Button>
       ),
       ignoreRowClick: true,
       allowOverflow: true,
       button: true,
     },
     {
       name: t("state"),
       cell: (row) =>
         row.status == true ? (
           <Button
             variant="danger"
             onClick={() => {
               handleShowFun(row._id, false);
             }}
             id={row._id}
           >
             {t("block")}
             <i className="fa fa-lock mx-1" />
           </Button>
         ) : (
           <Button
             variant="success"
             onClick={() => {
               handleShowFun(row._id, true);
             }}
             id={row._id}
           >
             {t("active")}
             <i className="fa fa-unlock mx-1" />
           </Button>
         ),
       ignoreRowClick: true,
       allowOverflow: true,
       button: true,
     },
   ];
 
   const tableData = {
     columns,
     data: documents,
   };
 
   return( 

   <div>
   <title>الوثائق والمستندات</title>
       <div className="d-flex justify-content-between">
         <div className="admin-content-text">الوثائق والمستندات </div>
         <AddButton title='اضافة ' navigateTo='/admin/addducument' />
 
       </div>
 
       <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
         <DataTable
           noHeader
           defaultSortAsc={false}
           pagination
           highlightOnHover
         />
       </DataTableExtensions>
       <ToastContainer />
     </div>
   );
}

export default AdminAllDocuments;