import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminEditMainCharacter from "../../Components/Admin/AdminEditMainCharacter";
import SideBar from "../../Components/Admin/SideBar";

const AdminEditMainCharacterPage = () => {
  return (

    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
      <AdminEditMainCharacter />
      </div>
    </div>

  );
};

export default AdminEditMainCharacterPage;