import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminAddCountryHook from "../../hook/admin/AdminAddCountryHook";

const AdminAddCountry = () => {
  const [name, onChangeName, onSubmit] = AdminAddCountryHook();
  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">اضافة البلد</div>
        <Col sm="8">
          <label htmlFor="firstName" className="d-block mt-3">
            اسم البلد
          </label>
          <input
            id="firstName"
            onChange={onChangeName}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder="اسم البلد"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: color }}
          >
            اضافة
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminAddCountry;
