import {
  GET_ALL_COUNTRIES,
  UPDATE_ONE_COUNTRIES,
  GET_ONE_COUNTRIES,
  ADD_ONE_COUNTRY,
} from "../type";
const inital = {
  allCountries: [],
  getOneCountry: [],
  updateOneCountry: [],
  addOneCountry: [],
};

const countriesReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_COUNTRIES:
      return {
        ...state,
        allCountries: action.payload,
      };
    case ADD_ONE_COUNTRY:
      return {
        ...state,
        addOneCountry: action.payload,
      };
    case GET_ONE_COUNTRIES:
      return {
        ...state,
        getOneCountry: action.payload,
      };
    case UPDATE_ONE_COUNTRIES: {
      return {
        ...state,
        updateOneCountry: action.payload,
      };
    }

    default:
      return state;
  }
};

export default countriesReducer;
