import { Col, Row, Spinner } from "react-bootstrap";
import AdminAddAndEditPollHook from "../../hook/admin/AdminAddAndEditPollHook";
import AsyncSelect from "react-select/async";
import formatFmlObj from "../Utilities/formatFmlObj";
import AddPoll from "../Utilities/AddPoll";
import { useParams } from "react-router-dom";


export default function AdminAddAndEditPollComponent() {
    const [title,setTitle,desc,setDesc, isLoading,setIsLoading, allUsers, onChangeUserId, iterator, setIterator, polls, setPolls, onSubmit,userId] = AdminAddAndEditPollHook();

    let usersOptionsObj = allUsers && formatFmlObj(allUsers);

    const{id}=useParams();

    console.log('usersOptionsObj', usersOptionsObj);
    let loadUsersOptions = async (inputText) =>
        usersOptionsObj.filter((i) => i.fullname.includes(inputText));

    console.log('iterator', iterator);
    console.log('polls', polls);


    return (
        <div>
            <Row className="justify-content-start ">
                <div className="admin-content-text pb-4">
                   {id ? 'تعديل استبيان' :' اضافة استبيان'} </div>
                <Col sm="8">
                    <label htmlFor="title" className="d-block mt-3">
                        العنوان
                    </label>

                    <input
                        id="title"
                        value={title}
                        onChange={(e)=>setTitle(e.target.value)}
                        type="text"
                        className="input-form d-block mt-2 px-3"
                        placeholder="ادخل العنوان"
                    />
                </Col>

                <Col sm="8">
                    <label htmlFor="Description" className="d-block mt-4 ">
                        الوصف
                    </label>
                    <textarea
                        id="Description"
                        onChange={(e)=>setDesc(e.target.value)}
                         value={desc}
                        className="input-form-area p-2 my-2"
                        rows="4"
                        cols="50"
                        placeholder={'الوصف'}
                    />
                </Col>

                <Col sm="8">
                    <label className="d-block my-2">اسم المستخدم</label>

                    <AsyncSelect
                        value={userId}
                        isClearable={true}
                        loadOptions={loadUsersOptions}
                        onChange={onChangeUserId}
                        getOptionLabel={(e) => e.fullname}
                        getOptionValue={(e) => e._id}
                        onInputChange={loadUsersOptions}
                    />
                </Col>

                <Col sm="8">
                    <label className="d-block mt-4"> المقترحات </label>
                    {iterator?.map((el, i) => <AddPoll
                        key={i}
                        setIterator={setIterator}
                        polls={polls}
                        setPolls={setPolls}
                        index={i}
                    />)}

                    {id && <AddPoll
                        key={polls?.length +1}
                        setIterator={setIterator}
                        polls={polls}
                        setPolls={setPolls}
                        index={polls?.length +1}
                    />}
                </Col>

                <Col sm="8" className="d-flex justify-content-end my-4">
                    <button 
                    className={`btn ${id ? 'btn-warning' : 'btn-success'}`} 
                    onClick={() => onSubmit()}
                    disabled={isLoading}
                    >
                       {id ? 'تعديل الاستبيان' :'اضافة الاستبيان'} 
                    </button>

                    {isLoading&&<Spinner />}
                </Col>
            </Row>
        </div>
    )
}


