import React from "react";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminAddNewsHook from "../../hook/admin/AdminAddNewsHook";
import MultiImageInput from "react-multiple-image-input";
//import { Editor } from "@tinymce/tinymce-react";
import AsyncSelect from "react-select/async";
import formatFmlObj from "../Utilities/formatFmlObj";
import "react-quill/dist/quill.snow.css";
import TextEditor from "../Utilities/TextEditor";

const AdminAddNews = () => {
  const { t } = useTranslation();

  const [
    newsMainType,
    title,
    desc,
    setDesc,
    images,
    setImages,
    seen,
    important,
    newsDate,
    relationalUsersArray,
    userId,
    monasbaType,
    monasbaGrromId,
    monasbaBrideId,
    monasbaGroomNameFML,
    monasbaBirdeNameFML,
    newBornUserId,
    newBorn2UserId,
    newBornNameFML,
    newBorn2NameFML,
    newBornGenderFML,
    newBorn2GenderFML,
    parentId,
    deathUserId,
    deathFromOutFamilyFMLGender,
    deathFromOutFamilyFML,
    deathDesc,
    deathGanazaAddress,
    deathGanazaDateTime,
    deathGanazaLocation,
    deathDafnaAddress,
    deathDafnaDateTime,
    deathDafnaLocation,
    death3azaAddress,
    death3azaLocation,
    death3azaDateTimeFrom,
    death3azaDateTimeTo,
    male3azaContacts,
    female3azaContacts,
    birthFromFamily,

    onChangeNewsMainType,
    onChangeTitle,
    onChangeDesc,
    onChangeImages,
    onChangeSeen,
    onChangeImportant,
    onChangeNewsDate,
    onChangeRelationalUsersArray,
    onChangeUserId,
    onChangeMonasbaType,
    onChangeMonasbaGrromId,
    onChangeMonasbaBrideId,
    onChangeMonasbaGroomNameFML,
    onChangeMonasbaBirdeNameFML,
    onChangeNewBornUserId,
    onChangeNewBorn2UserId,
    onChangeNewBornNameFML,
    onChangeNewBorn2NameFML,
    onChangeNewBornGenderFML,
    onChangeNewBorn2GenderFML,
    onChangeParentId,
    onChangeDeathUserId,
    onChangeDeathFromOutFamilyFMLGender,
    onChangeDeathFromOutFamilyFML,
    onChangeDeathDesc,
    onChangeDeathGanazaAddress,
    onChangeDeathGanazaDateTime,
    onChangeDeathGanazaLocation,
    onChangeDeathDafnaAddress,
    onChangeDeathDafnaDateTime,
    onChangeDeathDafnaLocation,
    onChangeDeath3azaAddress,
    onChangeDeath3azaLocation,
    onChangeDeath3azaDateTimeFrom,
    onChangeDeath3azaDateTimeTo,
    onChangeMale3azaContacts,
    onChangeFemale3azaContacts,
    onChangeBirthFromFamily,

    normalType,
    birthType,
    deathType,
    selectMonasbaType,
    allUsers,
    familyFemales,
    familyMales,

    handleShow,
    key,
    onChangeKey,
    value,
    onChangeValue,
    clickHandler,
    show,
    showEducation,
    jobArr,
    eduKey,
    onChangeEduKey,
    eduValues,
    onChangeEduValues,
    EduArr,
    deleteElement,
    death3azaFemaleAddress,
    setDeath3azaFemaleAddress,
    death3azaFemaleLocation,
    setDeath3azaFemaleLocation,
    death3azaFemaleDateTimeFrom,
    setDeath3azaFemaleDateTimeFrom,
    death3azaFemaleDateTimeTo,
    setDeath3azaFemaleDateTimeTo,
    descRef,
    onSubmit,
  ] = AdminAddNewsHook();

  let usersOptionsObj = allUsers && formatFmlObj(allUsers);
  let malesOptionsObj = familyMales && formatFmlObj(familyMales);
  let femalesOptionsObj = familyFemales && formatFmlObj(familyFemales);

  let loadUsersOptions = async (inputText) =>
    usersOptionsObj.filter((i) => i.fullname.includes(inputText));

  let loadMalesOptions = async (inputText) =>
    malesOptionsObj.filter((i) => i.fullname.includes(inputText));

  let loadFemalesOptions = async (inputText) =>
    femalesOptionsObj.filter((i) => i.fullname.includes(inputText));

  const color = useSelector(
    (state) => state.settingsReducers.oneSettings.primaryColor
  );

  return (
    <div>
      <title>اضافة اخبار </title>
      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">اضافة اخبار</div>

        <Col sm="8">
          <label htmlFor="Description" className="d-block mt-3 mb-2 ">
            {t("Description")}
          </label>
          {/* <Editor onInit={(evt, editor) => (descRef.current = editor)} /> */}
          {/* <ReactQuill theme="snow" value={descRef} onChange={setValue} /> */}
          <TextEditor value={desc} descRef={descRef} />

          <label className="d-block mt-2">اسم المستخدم</label>

          <AsyncSelect
            isClearable={true}
            loadOptions={loadUsersOptions}
            onChange={onChangeUserId}
            getOptionLabel={(e) => e.fullname}
            getOptionValue={(e) => e._id}
            onInputChange={loadUsersOptions}
          />

          <label htmlFor="mainType" className="d-block mt-2 ">
            نوع الخبر
          </label>

          <select
            id="mainType"
            value={newsMainType}
            onChange={onChangeNewsMainType}
            className="select input-form-area mt-2 mb-4 px-3 "
          >
            <option selected> من فضلك اختر نوع الخبر</option>
            <option value="normal">عام</option>
            <option value="births">مواليد</option>
            <option value="deaths">وفيات</option>
            <option value="monasba">مناسبة</option>
          </select>

          {normalType && (
            <div>
              <label htmlFor="firstname" className="d-block mt-2 ">
                العنوان
              </label>
              <input
                id="firstname"
                value={title}
                onChange={onChangeTitle}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder="العنوان"
              />

              <label htmlFor="images" className="d-block mt-2 ">
                الصور
              </label>
              <div>
                <div className="mt-3">
                  <MultiImageInput
                    images={images}
                    setImages={setImages}
                    theme="light"
                    //max={5}
                    // cropConfig={{ crop, ruleOfThirds: true }}
                    allowCrop={false}
                  />
                </div>
              </div>

              <label className="d-block mt-2"> اشخاص ذوي صلة</label>

              <AsyncSelect
                isMulti
                loadOptions={loadUsersOptions}
                onChange={onChangeRelationalUsersArray}
                getOptionLabel={(e) => e.fullname}
                getOptionValue={(e) => e._id}
                onInputChange={loadUsersOptions}
              />
            </div>
          )}

          {birthType && (
            <div>
              <label className="d-block mt-2">
                من فضلك قم ب اختيار الوالد او الوالدة
              </label>

              <AsyncSelect
                isClearable={true}
                loadOptions={loadUsersOptions}
                onChange={onChangeParentId}
                getOptionLabel={(e) => e.fullname}
                getOptionValue={(e) => e._id}
                onInputChange={loadUsersOptions}
              />

              <label className="d-block mt-2 ">تاريخ الخبر</label>
              <input
                type="date"
                value={newsDate}
                onChange={onChangeNewsDate}
                className="input-form d-block mt-2 px-3"
              />

              <label htmlFor="newBornNameFML" className="d-block mt-2">
                اسم المولود
              </label>
              <input
                id="newBornNameFML"
                value={newBornNameFML}
                onChange={onChangeNewBornNameFML}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder=" اسم  المولود"
              />

              <label className="d-block mt-3 ">النوع</label>
              <select
                onChange={onChangeNewBornGenderFML}
                value={newBornGenderFML}
                className="select input-form-area mt-2 px-3 "
              >
                <option value="" selected>
                  {" "}
                  من فضلك قم ب اختيار النوع
                </option>
                <option value="male">ذكر</option>
                <option value="female">انثي</option>
              </select>

              <label htmlFor="newBorn2NameFML" className="d-block mt-2">
                اسم المولود التؤام
              </label>
              <input
                id="newBorn2NameFML"
                value={newBorn2NameFML}
                onChange={onChangeNewBorn2NameFML}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder="اسم المولود التؤام"
              />

              <label className="d-block mt-3 ">النوع</label>
              <select
                onChange={onChangeNewBorn2GenderFML}
                value={newBorn2GenderFML}
                className="select input-form-area mt-2 px-3 "
              >
                <option value="" selected>
                  {" "}
                  من فضلك قم ب اختيار النوع
                </option>
                <option value="male">ذكر</option>
                <option value="female">انثي</option>
              </select>

              <label className="d-block mt-3 ">المواليد من داخل العائلة</label>
              <select
                onChange={onChangeBirthFromFamily}
                value={birthFromFamily}
                className="select input-form-area mt-2 px-3 "
              >
                <option value="" selected>
                  {" "}
                  من فضلك اختر نعم او لا{" "}
                </option>
                <option value="true">نعم</option>
                <option value="false">لا</option>
              </select>
            </div>
          )}

          {selectMonasbaType && (
            <div>
              <label className="d-block mt-2 ">تاريخ المناسبة</label>
              <input
                type="date"
                value={newsDate}
                onChange={onChangeNewsDate}
                className="input-form d-block mt-2 px-3"
              />

              <label className="d-block mt-2">نوع المناسبة</label>

              <select
                onChange={onChangeMonasbaType}
                value={monasbaType}
                className="select input-form-area mt-2 px-3 "
              >
                <option value="">من فضلك اختر نوع المناسبة</option>
                <option value="marriage">زواج</option>
                <option value="contract">عقد قران</option>
                <option value="engagement">خطوبة</option>
              </select>

              <label className="d-block mt-2">
                اسم الخطيب (يتم ملئ هذه الخانة اذا كان الخطيب من داخل العائلة)
              </label>
              <AsyncSelect
                isClearable={true}
                loadOptions={loadMalesOptions}
                onChange={onChangeMonasbaGrromId}
                getOptionLabel={(e) => e.fullname}
                getOptionValue={(e) => e._id}
                onInputChange={loadMalesOptions}
              />

              <label className="d-block mt-2">
                اسم الخطيبة (يتم ملئ هذه الخانة اذا كان الخطيبة من داخل العائلة)
              </label>
              <AsyncSelect
                isClearable={true}
                loadOptions={loadFemalesOptions}
                onChange={onChangeMonasbaBrideId}
                getOptionLabel={(e) => e.fullname}
                getOptionValue={(e) => e._id}
                onInputChange={loadFemalesOptions}
              />

              <label htmlFor="monasbaGroomNameFML" className="d-block mt-2">
                ادخل اسم الخطيب (في حالة انه من خارج العائلة)
              </label>
              <input
                id="monasbaGroomNameFML"
                value={monasbaGroomNameFML}
                onChange={onChangeMonasbaGroomNameFML}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder="الاسم بالكامل"
              />

              <label htmlFor="monasbaBirdeNameFML" className="d-block mt-2">
                ادخل اسم الخطيبة (في حالة انه من خارج العائلة)
              </label>
              <input
                id="monasbaBirdeNameFML"
                value={monasbaBirdeNameFML}
                onChange={onChangeMonasbaBirdeNameFML}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder="الاسم بالكامل"
              />
            </div>
          )}

          {deathType && (
            <div>
              <label className="d-block mt-2">
                اسم المتوفي(في حالة انه عضو من العائلة)
              </label>

              <AsyncSelect
                isClearable={true}
                loadOptions={loadUsersOptions}
                onChange={onChangeDeathUserId}
                getOptionLabel={(e) => e.fullname}
                getOptionValue={(e) => e._id}
                onInputChange={loadUsersOptions}
              />

              <label htmlFor="deathFromOutFamilyFML" className="d-block mt-2">
                ادخل اسم المتوفي (في حالة انه من خارج العائلة)
              </label>
              <input
                id="deathFromOutFamilyFML"
                value={deathFromOutFamilyFML}
                onChange={onChangeDeathFromOutFamilyFML}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder="الاسم بالكامل"
              />

              <label htmlFor="countries" className="d-block mt-3 ">
                جنس التوفي(في حالة انه ليس عضو بالعائلة)
              </label>
              <select
                onChange={onChangeDeathFromOutFamilyFMLGender}
                value={deathFromOutFamilyFMLGender}
                className="select input-form-area mt-2 px-3 "
              >
                <option value="" selected>
                  {" "}
                  من فضلك قم ب اختيار النوع
                </option>
                <option value="male">ذكر</option>
                <option value="female">انثي</option>
              </select>

              <label htmlFor="deathGanazaAddress" className="d-block mt-2">
                عنوان الجنازة
              </label>
              <input
                id="deathGanazaAddress"
                value={deathGanazaAddress}
                onChange={onChangeDeathGanazaAddress}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder="العنوان"
              />

              <label className="d-block mt-2 ">ميعاد الجنازة</label>
              <input
                type="datetime-local"
                value={deathGanazaDateTime}
                onChange={onChangeDeathGanazaDateTime}
                className="input-form d-block mt-2 px-3"
              />

              <label htmlFor="deathGanazaLocation" className="d-block mt-2">
                رابط موقع الجنازة
              </label>
              <input
                id="deathGanazaLocation"
                value={deathGanazaLocation}
                onChange={onChangeDeathGanazaLocation}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder="رابط موقع الجنازة"
              />

              <label htmlFor="deathDafnaAddress" className="d-block mt-2">
                عنوان الدفن
              </label>
              <input
                id="deathDafnaAddress"
                value={deathDafnaAddress}
                onChange={onChangeDeathDafnaAddress}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder="العنوان"
              />

              <label className="d-block mt-2 ">ميعاد الدفن</label>
              <input
                type="datetime-local"
                value={deathDafnaDateTime}
                onChange={onChangeDeathDafnaDateTime}
                className="input-form d-block mt-2 px-3"
              />

              <label htmlFor="deathDafnaLocation" className="d-block mt-2">
                رابط موقع الدفن
              </label>
              <input
                id="deathDafnaLocation"
                value={deathDafnaLocation}
                onChange={onChangeDeathDafnaLocation}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder="رابط موقع الدفن "
              />

              <label htmlFor="death3azaAddress" className="d-block mt-2">
                عنوان العزاء(رجال)
              </label>
              <input
                id="death3azaAddress"
                value={death3azaAddress}
                onChange={onChangeDeath3azaAddress}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder="عنوان العزاء(رجال)"
              />

              <label htmlFor="death3azaFemaleAddress" className="d-block mt-2">
                عنوان العزاء(نساء)
              </label>
              <input
                id="death3azaFemaleAddress"
                value={death3azaFemaleAddress}
                onChange={(e) => setDeath3azaFemaleAddress(e.target.value)}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder="عنوان العزاء(نساء)"
              />

              <label htmlFor="death3azaLocation" className="d-block mt-2">
                رابط موقع العزاء(رجال)
              </label>
              <input
                id="death3azaLocation"
                value={death3azaLocation}
                onChange={onChangeDeath3azaLocation}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder="رابط موقع العزاء(رجال)"
              />

              <label htmlFor="death3azaFemaleLocation" className="d-block mt-2">
                رابط موقع العزاء(نساء)
              </label>
              <input
                id="death3azaFemaleLocation"
                value={death3azaFemaleLocation}
                onChange={(e) => setDeath3azaFemaleLocation(e.target.value)}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder="رابط موقع العزاء(نساء)"
              />

              <label className="d-block mt-2">
                اشخاص للتواصل (عزاء الرجال)
              </label>

              <div>
                <button
                  onClick={() => handleShow("3azamales")}
                  className="btn-save d-inline mt-2 "
                  style={{ backgroundColor: color }}
                >
                  اضافة اشخاص
                </button>
              </div>
              {show ? (
                <div>
                  <input
                    style={{
                      borderColor: "gray",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderRadius: "6px",
                    }}
                    value={key}
                    onChange={onChangeKey}
                    className="d-inline-block  mt-2 p-1 "
                    placeholder="الاسم"
                  />

                  <input
                    style={{
                      borderColor: "gray",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderRadius: "6px",
                    }}
                    value={value}
                    onChange={onChangeValue}
                    className="d-inline-block mx-3 mt-2 p-1 "
                    placeholder="رقم الجوال"
                  />

                  <button
                    onClick={() => clickHandler("3azamales")}
                    className="btn-save d-inline mt-2 "
                    style={{
                      backgroundColor: color,
                      marginRight: "3%",
                    }}
                  >
                    {t("add")}
                  </button>
                </div>
              ) : (
                ""
              )}

              {show ? (
                <Col sm-8>
                  <Table
                    striped
                    bordered
                    hover
                    className="mt-3 mx-5 w-50"
                    style={{ textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th> الاسم</th>
                        <th colSpan="2">رقم الجوال </th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobArr
                        ? jobArr.map((el) => (
                            <tr>
                              <td>{el.count}</td>
                              <td>{el.name}</td>
                              <td>{el.mobile}</td>
                              <td>
                                <i
                                  onClick={() =>
                                    deleteElement(el, "job", el.count)
                                  }
                                  class="fa-regular fa-trash-can"
                                >
                                  مسح
                                </i>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                </Col>
              ) : (
                ""
              )}

              <label className="d-block mt-2">
                اشخاص للتواصل (عزاء النساء)
              </label>

              <div>
                <button
                  onClick={() => handleShow("education")}
                  className="btn-save d-inline mt-2 "
                  style={{ backgroundColor: color }}
                >
                  اضافة اشخاص
                </button>
              </div>
              {showEducation ? (
                <div>
                  <input
                    style={{
                      borderColor: "gray",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderRadius: "6px",
                    }}
                    value={eduKey}
                    onChange={onChangeEduKey}
                    className="d-inline-block  mt-2 p-1 "
                    placeholder="الاسم"
                  />

                  <input
                    style={{
                      borderColor: "gray",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderRadius: "6px",
                    }}
                    value={eduValues}
                    onChange={onChangeEduValues}
                    className="d-inline-block mx-3 mt-2 p-1 "
                    placeholder="رقم الجوال"
                  />

                  <button
                    onClick={() => clickHandler("education")}
                    className="btn-save d-inline mt-2 "
                    style={{
                      backgroundColor: color,
                      marginRight: "3%",
                    }}
                  >
                    {t("add")}
                  </button>
                </div>
              ) : (
                ""
              )}

              {showEducation ? (
                <Col sm-8>
                  <Table
                    striped
                    bordered
                    hover
                    className="mt-3 mx-5 w-50"
                    style={{ textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th> الاسم</th>
                        <th>رقم الجوال </th>
                        <th>تحكم</th>
                      </tr>
                    </thead>
                    <tbody>
                      {EduArr
                        ? EduArr.map((el) => (
                            <tr>
                              <td>{el.count}</td>
                              <td>{el.name}</td>
                              <td>{el.mobile}</td>
                              <td>
                                <i
                                  onClick={() =>
                                    deleteElement(el, "education", el.count)
                                  }
                                  class="fa-regular fa-trash-can"
                                >
                                  ازالة
                                </i>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                </Col>
              ) : (
                ""
              )}

              <label htmlFor="death3azaDateTimeFrom" className="d-block mt-2 ">
                ميعاد بداية العزاء(رجال)
              </label>
              <input
                id="death3azaDateTimeFrom"
                type="datetime-local"
                value={death3azaDateTimeFrom}
                onChange={onChangeDeath3azaDateTimeFrom}
                className="input-form d-block mt-2 px-3"
              />

              <label
                htmlFor="death3azaFemaleDateTimeFrom"
                className="d-block mt-2"
              >
                {" "}
                ميعاد بداية العزاء(نساء)
              </label>
              <input
                id="death3azaFemaleDateTimeFrom"
                value={death3azaFemaleDateTimeFrom}
                onChange={(e) => setDeath3azaFemaleDateTimeFrom(e.target.value)}
                type="datetime-local"
                className="input-form d-block mt-2 px-3"
              />

              <label htmlFor="death3azaDateTimeTo" className="d-block mt-2">
                ميعاد نهاية العزاء(رجال)
              </label>
              <input
                id="death3azaDateTimeTo"
                type="datetime-local"
                value={death3azaDateTimeTo}
                onChange={onChangeDeath3azaDateTimeTo}
                className="input-form d-block mt-2 px-3"
              />

              <label
                htmlFor="death3azaFemaleDateTimeTo"
                className="d-block mt-2"
              >
                ميعاد نهاية العزاء(نساء)
              </label>
              <input
                value={death3azaFemaleDateTimeTo}
                onChange={(e) => setDeath3azaFemaleDateTimeTo(e.target.value)}
                id="death3azaFemaleDateTimeTo"
                type="datetime-local"
                className="input-form d-block mt-2 px-3"
              />
            </div>
          )}
        </Col>
      </Row>
      <Row>
        {(normalType || birthType || deathType || selectMonasbaType) && (
          <Col sm="8" className="d-flex justify-content-end ">
            <button
              onClick={onSubmit}
              className="btn-save d-inline mt-3 "
              style={{ backgroundColor: color }}
            >
              {t("add")}
            </button>
          </Col>
        )}
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminAddNews;
