import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminAddCityHook from "../../hook/admin/AdminAddCityHook";

const AdminAddCity = () => {
    const { id } = useParams();
    console.log(id)
    //const[name,onChangeName,onSubmit] = AdminEditCountryHook(id);
    const[name, onChangeName, onSubmit] =  AdminAddCityHook(id);
    const color = useSelector(
        (state) => state.settingsReducers.oneSettings.primaryColor
      );

      return (
        <div>
          <Row className="justify-content-start ">
            <div className="admin-content-text pb-4">اضافة مدينة</div>
            <Col sm="8">
              <label htmlFor="firstName" className="d-block mt-3">
                اسم مدينة
              </label>
              <input
                id="firstName"
                onChange={onChangeName}
                type="text"
                className="input-form d-block mt-2 px-3"
                placeholder="اسم مدينة"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="8" className="d-flex justify-content-end ">
              <button
                onClick={onSubmit}
                className="btn-save d-inline mt-2 "
                style={{ backgroundColor: color }}
              >
                اضافة
              </button>
            </Col>
          </Row>
          <ToastContainer />
        </div>
      );
}

export default AdminAddCity;