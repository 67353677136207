import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../useNotification";
import { addOnePoll, updateOnePoll } from "../../redux/actions/pollsActions";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function AdminAddAndEditPollHook() {

  const location=useLocation();
  const{id}=useParams();

  console.log('location',location.state,id);

 



  const [title, setTitle] = useState(()=> id ? location.state.title :"");
  const [desc, setDesc] = useState(()=> id ? location.state.description :"");
  const [userId, setUserId] = useState(()=> id ?{
    ...location.state.userID,
    fullname:`${location.state.userID.fullname.firstname} ${location.state.userID.fullname.middlename} ${location.state.userID.fullname.lastname} ${location.state.userID.fullname.surname}`
  } :"");
  const [iterator, setIterator] = useState(() =>{
    if(id){
     let length=location.state.polls;
      let arr=[];

      console.log('length',length);

     for(let i=0;i<length.length;i++){
      arr.push(i);
     }

     return arr;
    }
    else{
      return [0];
    }
    
  });
  const [polls, setPolls] = useState(()=> id ? location.state.polls :[]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate=useNavigate();

  console.log('userId',userId);
  let allUsers = useSelector((state) => state.usersReducers.allUser);

  const onChangeUserId = (value) => {
    setUserId(value);
  };

  const onSubmit = async () => {
    try {


      if (userId == "" || title == "" || desc == "" || polls?.length == 0) return notify("من فضلك اكمل البيانات", "error");

      setIsLoading(true);

      let obj={
        title,
        description:desc,
        polls,
        userID:userId
      }

      if(id){
        await dispatch(updateOnePoll(id,obj));
      }
      else{
        await dispatch(addOnePoll(obj));
      }
     
      setIsLoading(false);

     id ? notify("تم التعديل بنجاح","success")  :notify("تم الاضافة بنجاح","success");

      setTimeout(()=>{
        navigate('/admin/polls');
      },2000);
      // /admin/polls
    } catch (error) {
      return notify("حدث خطأ حاول مرة اخري", "error");
    }

  }

  return [
    title, setTitle, desc, setDesc,
    isLoading, setIsLoading,
    allUsers,
    onChangeUserId,
    iterator, setIterator,
    polls, setPolls, onSubmit,
    userId
  ];
}
