import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminEditDocumentHook from "../../hook/admin/AdminEditDocumentHook";
import avatar from "../../../src/Images/adminIcon.png";

const AdminEditDocument = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  //console.log('first')
  const [
    title,
    img,
    selectedFile,
    pdffile,
    selectedFilePdf,
    isFixed,
    onChangeTitle,
    onImageChange,
    onPdfFileChange,
    onChangeIsFixed,
    onSubmit
  ] = AdminEditDocumentHook(id);

  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  return (
    <div>
      <title>تعديل وثائق </title>
      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">تعديل وثائق</div>

        <Col sm="8">
          <div className="text-form pb-2">{t("image")}</div>
          <div>
            <label htmlFor="upload-photo">
              <img
                src={img}
                alt="fzx"
                height="120px"
                width="120px"
                style={{ cursor: "pointer" }}
              />
            </label>
            <input
              type="file"
              name="photo"
              onChange={onImageChange}
              id="upload-photo"
            />
          </div>

          <div className="text-form pb-2 mt-3">الوثيقة</div>
          <div className="text-form pb-2 mt-1">اذا كنت تريد تغيير الوثيقة قم برفع وثيقة جديدة </div>
          <div className="mt-1">
          
            <input
              type="file"
              name="photo-2"
              onChange={onPdfFileChange}
              accept="application/pdf"
             
            />
          </div>

          <label htmlFor="firstname" className="d-block mt-2 ">
            العنوان
            
          </label>
          <input
            id="firstname"
            value={title}
            onChange={onChangeTitle}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder='العنوان'
          />

          <label htmlFor="countries" className="d-block mt-3 ">
            مثبت
        </label>
        <select
          id="countries"
          name="countries"
          onChange={onChangeIsFixed}
          className="select input-form-area mt-2 px-3"
          value={isFixed}
        >
          <option value="true">نعم</option>
          <option value="false">لا</option>
          
          
        </select>
  
        

        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-3 "
            style={{ backgroundColor: color }}
          >
            {t("edit")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminEditDocument;
