import { Row, Col } from "react-bootstrap";
import React from "react";
import { ToastContainer } from "react-toastify";
import AdminSettingsHook from "../../hook/admin/AdminSettingsHook";
import add from "../../Images/add.png";
import { CompactPicker } from "react-color";

import { t } from "i18next";
import { useSelector } from "react-redux";
import { UploadOneImg } from "../Utilities/UploadOneImg";
import AsyncSelect from "react-select/async";
import formatFmlObj from "../Utilities/formatFmlObj";

const AdminSettings = () => {
  const [
    facebook,
    phone,
    onChangeFacebook,
    onChangePhone,
    onSubmit,
    phone2,
    onChangePhone2,
    email,
    onChangeEmail,
    address,
    onChangeAddress,
    titleAr,
    titleEn,
    onChangeTitleAr,
    onChangeTitleEn,
    showPicker,
    colors,
    handleChaneComplete,
    onChangePicker,
    img,
    familyTreeWithSymbolImg,
    printedFamilyTreeImg,
    birthImg,
    deathImg,
    monasbaImg,

    onImageChange,
    onFamilyTreeWithSymbolImgChange,
    onPrintedFamilyTreeImgChange,
    onBirthImgChange,
    onDeathImgChange,
    onMonasbaImgChange,

    seccolors,
    onChangePickerseccolors,
    handleChaneCompleteseccolors,
    showPickerseccolors,
    vat,
    onChangeVat,
    updateTree,
    loadingTree,

    showSmsCode,
    onChangeShowSmsCode,
    seccolors__,
    onChangePickerseccolors__,
    showPickerseccolors__,
    handleChaneCompleteseccolors__,
    allUsers,
    onChangeUserId,
    onSubmit_color,
  ] = AdminSettingsHook();

  // const color = useSelector(
  //   (state) => state.settingsReducers.oneSettings.primaryColor
  // );

  const color = useSelector(
    (state) => state.settingsReducers.oneSettings.primaryColor
  );

  let usersOptionsObj = allUsers && formatFmlObj(allUsers);

  let loadUsersOptions = async (inputText) =>
    usersOptionsObj.filter((i) => i.fullname.includes(inputText));

  return (
    <div>
      <Row className="justify-content-start">
        <Col sm="8">
          <div className="admin-content-text pb-4">{t("settings")}</div>
          <div className="text-form pb-2">{t("websiteLogo")}</div>
          <div>
            <UploadOneImg
              img={img}
              onImageChange={onImageChange}
              id="upload-photo"
            />
          </div>

          <div className="text-form pb-2 mt-5">
            شجرة التعريف بالرموز والالوان
          </div>
          <UploadOneImg
            img={familyTreeWithSymbolImg}
            onImageChange={onFamilyTreeWithSymbolImgChange}
            id="upload-photo-2"
          />

          <div className="text-form pb-2 mt-5">شجرة الاسرة المطبوعة</div>

          <input
            type="file"
            name="photo-2"
            onChange={onPrintedFamilyTreeImgChange}
            accept="application/pdf"
          />

          <div className="text-form pb-2 mt-5">صورة المواليد</div>
          <UploadOneImg
            img={birthImg}
            onImageChange={onBirthImgChange}
            id="upload-photo-4"
          />

          <div className="text-form pb-2 mt-5">صورة الوفيات</div>
          <UploadOneImg
            img={deathImg}
            onImageChange={onDeathImgChange}
            id="upload-photo-5"
          />

          <div className="text-form pb-2 mt-5">صورة المناسبات</div>
          <UploadOneImg
            img={monasbaImg}
            onImageChange={onMonasbaImgChange}
            id="upload-photo-6"
          />

          <label htmlFor="titleAr" className="d-block mt-2 ">
            {t("address in arabic")}
          </label>
          <input
            id="titleAr"
            value={titleAr}
            onChange={onChangeTitleAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("address")}
          />

          <label htmlFor="face" className="d-block mt-2 ">
            {t("Facebook")}
          </label>

          <input
            id="face"
            value={facebook}
            onChange={onChangeFacebook}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("Facebook")}
          />
          <label htmlFor="phone1" className="d-block mt-2 ">
            {t("Mobile Number 1")}
          </label>

          <input
            id="phone1"
            value={phone}
            onChange={onChangePhone}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("phone number")}
          />

          <label htmlFor="email" className="d-block mt-2 ">
            {t("email")}
          </label>
          <input
            id="email"
            value={email}
            onChange={onChangeEmail}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("email")}
          />

          <label htmlFor="smsCode" className="d-block mt-2">
            اظهار كود sms (قم ب اختيار لا في حالة تفعيل بوابة sms)
          </label>
          <select
            value={showSmsCode}
            onChange={onChangeShowSmsCode}
            className="input-form d-block mt-2 px-3"
          >
            <option value="false">لا</option>
            <option value="true">نعم</option>
          </select>

          <div className="d-flex justify-content-start">
            <div className="text-form mt-3 mx-2"> اللون الرئيسي</div>
            <div className="mt-1 d-flex">
              {colors && (
                <div
                  className="color ms-2 border  mt-1 mx-2"
                  style={{ backgroundColor: colors }}
                ></div>
              )}
              <img
                src={add}
                alt=""
                width="30px"
                height="35px"
                style={{ cursor: "pointer" }}
                onClick={onChangePicker}
              />

              {showPicker === true ? (
                <CompactPicker onChangeComplete={handleChaneComplete} />
              ) : null}
            </div>

            <div className="text-form mt-3 mx-2"> اللون الفرعى</div>
            <div className="mt-1 d-flex">
              {seccolors && (
                <div
                  className="color ms-2 border  mt-1 mx-2"
                  style={{ backgroundColor: seccolors }}
                ></div>
              )}
              <img
                src={add}
                alt=""
                width="30px"
                height="35px"
                style={{ cursor: "pointer" }}
                onClick={onChangePickerseccolors}
              />

              {showPickerseccolors === true ? (
                <CompactPicker
                  onChangeComplete={handleChaneCompleteseccolors}
                />
              ) : null}
            </div>
          </div>

          <div className="text-form mt-3 p-2">
            <h4 className="d-block mt-2" style={{ color: "black" }}>
              لتحديث الشجرة قم بالضغط هنا
            </h4>
            <button
              onClick={updateTree}
              className="btn-save mt-3 p-2"
              style={{ backgroundColor: color }}
            >
              {loadingTree === false ? "تحديث الشجرة" : "loading..."}
            </button>
          </div>
          <Row></Row>
          <div className="text-form mt-3 mx-2 mt-4"> اختر لون لفرع العائلة</div>
          <label className="d-block mt-2">اسم المستخدم</label>

          <AsyncSelect
            isClearable={true}
            loadOptions={loadUsersOptions}
            onChange={onChangeUserId}
            getOptionLabel={(e) => e.fullname}
            getOptionValue={(e) => e._id}
            onInputChange={loadUsersOptions}
          />
          <div>
            <div className="mt-4 d-flex">
              {seccolors__ && (
                <div
                  className="color ms-2 border  mt-1 mx-2"
                  style={{ backgroundColor: seccolors__ }}
                ></div>
              )}
              <img
                src={add}
                alt=""
                width="30px"
                height="35px"
                style={{ cursor: "pointer" }}
                onClick={onChangePickerseccolors__}
              />

              {showPickerseccolors__ === true ? (
                <CompactPicker
                  onChangeComplete={handleChaneCompleteseccolors__}
                />
              ) : null}
            </div>
            <button
              className="px-4 mt-4 py-2"
              onClick={onSubmit_color}
              style={{
                backgroundColor: color,
                marginRight: "10px",
                color: "white",
                border: 0,
                borderRadius: "10px",
              }}
            >
              تغير
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-3 "
            style={{ backgroundColor: color }}
          >
            حفظ الاعدادات
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminSettings;
