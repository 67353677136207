import axios from "axios";
//tree.megastore-app.com:5000
//104.248.246.2
//السدحان
//http://104.248.162.83  ---- http://sadhaan.dd.sa
//المجرن
//http://206.189.19.24/
// family demo
// http://megastore-app.store:9000


const baseURL = axios.create({ baseURL: "https://sadhaan.dd.sa" });

//const baseURL = axios.create({ baseURL: "http://localhost/" });


export default baseURL;
