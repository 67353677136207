import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";
import avatar from "../../../src/Images/adminIcon.png";
import { addGallaryDetails } from "../../redux/actions/galleryDetailsActions";
import { getAllUsers } from "../../redux/actions/usersActions";

const AdminAddGalleryDetailsHook = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [img, setImg] = useState(avatar);
  const [selectedFile, setSelectedFile] = useState(null);
  const [galleryId, setGalleryId] = useState(id);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  let Users = useSelector((state) => state.usersReducers.allUser);
  let allUsers;
  if (Users) allUsers = Users;
  //console.log(Users);
  //set image
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImg(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };
  //set user id
  const onUserIdChange = (value) => {
    setUserId(value._id);
  };

  const onSubmit = async () => {
    if(userId=="")
    {
        notify("من فضلك قم قم ب اختيار مشتخدم", "error");
        return;
    }
    if(selectedFile=="")
     {
      notify("من فضلك قم برفع الصورة", "error");
      return;
     }

        setLoading(true);
        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("image", selectedFile);
        formData.append("galleryId", galleryId);
        await dispatch(addGallaryDetails(formData));
        setLoading(false);
  };

  const res = useSelector((state) => state.galleryDetailsReducer.addGallaryDetails);
  
  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم الاضافة بنجاح", "success");
        setTimeout(() => {
          navigate(`/admin/gallarydetails/${id}`);
        }, 1500);
      } else {
        notify("فشل في عملية الاضافة", "error");
      }
    }
  }, [loading]);

  return [img, selectedFile, allUsers, onImageChange, onUserIdChange, onSubmit];
};
export default AdminAddGalleryDetailsHook;
