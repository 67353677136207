import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminEditDocument from "../../Components/Admin/AdminEditDocument";
import SideBar from "../../Components/Admin/SideBar";

const AdminEditDocumentPage = () => {
  return (

    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
      <AdminEditDocument />
        
      </div>
    </div>

  );
};

export default AdminEditDocumentPage;