import {
    GET_ALL_DOCUMENTS,
    UPDATE_ONE_DOCUMENT,
    ADD_DOCUMENT,
    GET_ONE_DOCUMENT
  } from "../type";
  const inital = {
  allDocuments:[],
  updateDocument:[],
  addDocument:[],
  oneDocument:[]
  };
  
  const documentsReducer = (state = inital, action) => {
    switch (action.type) {
     case GET_ALL_DOCUMENTS:
       return {
         ...state,
         allDocuments:action.payload
        };
     case UPDATE_ONE_DOCUMENT:
        return{
            ...state,
            updateDocument:action.payload
        };
    case ADD_DOCUMENT:
        return{
            ...state,
            addDocument:action.payload
        };
    case GET_ONE_DOCUMENT:
        return{
            ...state,
            oneDocument:action.payload
        };

        default:
        return state;
    }
}

export default documentsReducer;
