import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminEditGalleryDetailsHook from "../../hook/admin/AdminEditGalleryDetailsHook";
import AsyncSelect from "react-select/async";
import add from "../../Images/add.png";
import MultiImageInput from "react-multiple-image-input";

const AdminEditGalleryDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
//   const [img, selectedFile, allUsers, onImageChange, onUserIdChange, onSubmit] =
//     AdminAddGalleryDetailsHook(id);
  const[img, selectedFile, userId ,allUsers, onImageChange, onUserIdChange, onSubmit] =AdminEditGalleryDetailsHook(id);

 

const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

return (
  <div>
    <Row className="justify-content-start">
      <div className="admin-content-text pb-4">تعديل صورة</div>

      <Col sm="8">

      <div className="text-form pb-2">{t("image")}</div>
      <div>
        <label htmlFor="upload-photo">
          <img
            src={img}
            alt="fzx"
            height="120px"
            width="120px"
            style={{ cursor: "pointer" }}
          />
        </label>
        <input
          type="file"
          name="photo"
          onChange={onImageChange}
          id="upload-photo"
        />
      </div>

        <label className="d-block mt-3 mb-2">اسم المستخدم</label>
        <select
        onChange={onUserIdChange}
        value={userId}
        className="select input-form-area mt-2 px-3 "
      >
        <option value="">
          اسم الشريك(يتم ملئ هذه الخانة في حالة ان الشريك عضو من العائلة)
        </option>
        {allUsers
          ? allUsers.map((el) => {
              return (
                <option key={el._id} value={el._id}>
                  {el.fullname.firstname}
                </option>
              );
            })
          : null}
        )
      </select>
      </Col>
    </Row>
    <Row>
    <Col sm="8" className="d-flex justify-content-end ">
      <button
        onClick={onSubmit}
        className="btn-save d-inline mt-3 "
        style={{ backgroundColor: color }}
      >
        {t("edit")}
      </button>
    </Col>
  </Row>

  <ToastContainer />
  </div>
);
}
export default AdminEditGalleryDetails;