import  { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";
import {
  getAllSettingsOneUser,
  updateSettingsOneUser,
} from "../../redux/actions/userSettingsActions";
import { formateDate } from "../../Components/Utilities/formatDate";

const AdminEditUserSettingsHook = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [showNews, setShowNews] = useState("");
  const [newBorn, setNewBorn] = useState("");
  const [showDeath, setShowDeath] = useState("");
  const [showMonasba, setShowMonasba] = useState("");
  const [showAlwaysLogin, setShowAlwaysLogin] = useState("");
  const [userFaceId, setUserFaceId] = useState("");
  const [notificationId,setNotificationId]=useState("");

  async function get()
  {
    await dispatch(getAllSettingsOneUser(id));
  }
  useEffect(() => {
     get();
  }, []);

  let notificationSettings = useSelector((state) => state.userSettingsReducer.allSettings);
  console.log(notificationSettings);

  useEffect(() => {
    if (notificationSettings) {
        console.log('notificationSettings');
        console.log(notificationSettings.showGnewsNotify)
      setShowNews(notificationSettings.showGnewsNotify);
      setNewBorn(notificationSettings.showNewBornNotify);
      setShowDeath(notificationSettings.showDeathNotify);
      setShowMonasba(notificationSettings.showMonsbaNotify);
      setShowAlwaysLogin(notificationSettings.stayAlwaysLogin);
      setUserFaceId(notificationSettings.useFaceId);
      setNotificationId(notificationSettings._id);
    }
  }, [notificationSettings]);

  //set show news
  const onChangeShowNews = (e) => {
    setShowNews(e.target.value);
  };
  // set newBorn
  const onChangeNewBorn = (e) => {
    setNewBorn(e.target.value);
  };
  //setShowDeath
  const onChangeShowDeath = (e) => {
    setShowDeath(e.target.value);
  };
  //setShowMonasba
  const onChangeShowMonasba = (e) => {
    setShowMonasba(e.target.value);
  };
  //setShowAlwaysLogin
  const onChangeShowAlwaysLogin = (e) => {
    setShowAlwaysLogin(e.target.value);
  };
  //setUserFaceId
  const onChangeUserFaceId = (e) => {
    setUserFaceId(e.target.value);
  };

  const onSubmit = async () => {
    setLoading(true);
    await dispatch(
        updateSettingsOneUser(id, {
            showGnewsNotify:showNews,
            showNewBornNotify:newBorn,
            showDeathNotify:showDeath,
            showMonsbaNotify:showMonasba,
            stayAlwaysLogin:showAlwaysLogin,
            useFaceId:userFaceId
      })
    );
    setLoading(false);
  };

  const res = useSelector((state) => state.userSettingsReducer.updateSettings);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          navigate(`/admin/allusers`);
        }, 1500);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  return [
    showNews,
    newBorn,
    showDeath,
    showMonasba,
    showAlwaysLogin,
    userFaceId,

    onChangeShowNews,
    onChangeNewBorn,
    onChangeShowDeath,
    onChangeShowMonasba,
    onChangeShowAlwaysLogin,
    onChangeUserFaceId,
    onSubmit
  ];
};

export default AdminEditUserSettingsHook;
