import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminEditCityHook from "../../hook/admin/AdminEditCityHook";

const AdminEditCity = () => {
    const { id } = useParams();
    const[name,onChangeName,onSubmit] = AdminEditCityHook(id);
    const color = useSelector(
        (state) => state.settingsReducers.oneSettings.primaryColor
      );

     return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">تعديل المدينة</div>
        <Col sm="8">
          <label htmlFor="firstName" className="d-block mt-3">

            اسم المدينة
          </label>
          <input
            id="firstName"
            value={name}
            onChange={onChangeName}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder="اسم البلد"
          />
      
        
        
     
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: color ? color : '#c992f0' }}
          >
            حفظ التعديلات
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
}

export default AdminEditCity;