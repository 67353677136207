import {
    GET_ALL_GALLARIES,
    UPDATE_ONE_GALLARY,
    GET_ONE_GALLARY,
    ADD_GALLARY
    
  } from "../type";
  const inital = {
    allGallaries:[],
    updateGallery:[],
    addGallary:[],
    oneGallery:[]
  };
  
  const gallariesReducer = (state = inital, action) => {
    switch (action.type) {
       case GET_ALL_GALLARIES:
        return{
          ...state,
          allGallaries:action.payload
        };
        case UPDATE_ONE_GALLARY:
            return{
                ...state,
                updateGallery:action.payload
            };
        case ADD_GALLARY:
            return{
               ...state,
               addGallary:action.payload
            };
        case GET_ONE_GALLARY:
            return{
                ...state,
                oneGallery:action.payload
            }

        default:
            return state;
    }
}

export default gallariesReducer;