import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";

import {
    getOneNewsActions
} from "../../redux/actions/newsActionsActions";
import { getAllUsers } from "../../redux/actions/usersActions";
import { useRef } from "react";

const AdminNewsActionsHook = (id) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
      dispatch(getOneNewsActions(id))
    },[]);

    let oneNewsActions = useSelector((state) => state.newsActionsReducer.oneNewsActions);

    let actions = [];
    if (oneNewsActions) actions = oneNewsActions;

    return [actions];

}

export default AdminNewsActionsHook;
