import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSubCharactersForOneMain,updateSubCharacter } from "../../redux/actions/subCharactersActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";

const ViewAllSubCharactersHook = (id) => {
  //console.log('done');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  //console.log(id);
  useEffect(()=>{
  dispatch(getAllSubCharactersForOneMain(id));
  },[]);

  let allSub = useSelector((state) => state.subCharactersReducer.allSubCharacters);
  console.log(allSub);
    let subCharacters = [];
    if (allSub) subCharacters = allSub;

    const clickHandler = (id) => {
        navigate(`/admin/editsubchar/${id}`);
      };
    
      //updateOneCountry
      const handleShowFun = async (id, status) => {
        setLoading(true);
        await dispatch(
            updateSubCharacter(id, {
              status: status
            })
          );
      
        setLoading(false);
      };

      const res = useSelector((state) => state.subCharactersReducer.updateOneSubCharacter);

      useEffect(() => {
        if (loading === false) {
          if (res) {
            notify("تم التعديل بنجاح", "success");
            setTimeout(() => {
              dispatch(getAllSubCharactersForOneMain(id));
            }, 1000);
          } else {
            notify("فشل في عملية التعديل", "error");
          }
        }
      }, [loading]);
    

    //console.log(cities);
    return [subCharacters, clickHandler, handleShowFun];
}
export default ViewAllSubCharactersHook;