import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllFixedPages , updateFixedPage } from "../../redux/actions/fixedPagesActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";

const ViewAllFixedPagesHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      dispatch(getAllFixedPages());
  }, []);

  let allFixedPages = useSelector((state) => state.fixedPagesReducer.allFixedPages);
  let FixedPages = [];
  if (allFixedPages) FixedPages = allFixedPages;
  //console.log(FixedPages)

   //console.log(countries);
   const clickHandler = (id) => {
      navigate(`/admin/editFixedPage/${id}`);
    };
  
    //updateOneCountry
    const handleShowFun = async (id, status) => {
      setLoading(true);
      await dispatch(
            updateFixedPage(id, {
            status: status
          })
        );
    
      setLoading(false);
    };
  
    const res = useSelector((state) => state.fixedPagesReducer.updateFixedPage);
  
    useEffect(() => {
      if (loading === false) {
        if (res) {
          notify("تم التعديل بنجاح", "success");
          setTimeout(() => {
            dispatch(getAllFixedPages());
          }, 1000);
        } else {
          notify("فشل في عملية التعديل", "error");
        }
      }
    }, [loading]);
  
    return [FixedPages, clickHandler, handleShowFun];




};
export default ViewAllFixedPagesHook;
