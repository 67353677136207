import AdminAddAndEditPollComponent from "../../Components/Admin/AdminAddAndEditPollComponent";
import SideBar from "../../Components/Admin/SideBar";

export default function AdminEditPollsPage() {
  return (
    <div className="d-flex justify-content-start">
        <div>
          <SideBar />
        </div>
        <div className="p-4" style={{ width: '100%' }}>
          <AdminAddAndEditPollComponent />
        </div>
    </div>
  )
}
