import { t } from "i18next";
import React from "react";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";

const Pagination = ({ pageCount, onPress }) => {
  
  const[searchParams,setSearchParams]=useSearchParams();

  const handlePageClick = (data) => {
    // localStorage.setItem('page',data.selected+1);
   // console.log('data.selected + 1',data.selected + 1);
    searchParams.set('page',data.selected + 1);
    setSearchParams(searchParams);

    onPress(data.selected + 1);
  };

  let page=0;

  if(searchParams.get('page')) page=searchParams.get('page');
  else page=localStorage.getItem('page');

   let currentPageOnLoad=Number(page-1);
   if(currentPageOnLoad==null) currentPageOnLoad=0;
   let initialPage=Number(currentPageOnLoad);

    console.log("pageCount",pageCount);

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={t("next")}
      onPageChange={handlePageClick}
      marginPagesDisplayed={2}
      pageRangeDisplayed={3}
      pageCount={pageCount}
      previousLabel={t("back")}
      containerClassName={"pagination justify-content-center p-3"}
      pageClassName={"page-item"}
      pageLinkClassName={"page-link"}
      previousClassName={"page-item"}
      nextClassName={"page-item"}
      previousLinkClassName={"page-link"}
      nextLinkClassName={"page-link"}
      breakClassName={"page-item"}
      breakLinkClassName={"page-link"}
      activeClassName={"active"}
       forcePage={initialPage}
     // selectedPageRel={initialPage}
       initialPage={initialPage}
      
     // pageClassName={(page) => `page-item ${currentPage === data.selected ? 'active' : ''}`}
    />
  );
};

export default Pagination;
