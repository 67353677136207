import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOneUser } from "../../redux/actions/usersActions";

const ProtectedRouteHook = () => {
  console.log('protected routes');

  const dispatch=useDispatch();
  // const [userData, setUserData] = useState(
  //   JSON.parse(localStorage.getItem("user"))
  // );
  let isAdmin=false;
  // const [isAdmin, setIsAdmin] = useState();
  const user=JSON.parse(localStorage.getItem("user"));
  console.log(user);

  if(user)
  {
    if(user?.app=='family') isAdmin=true;
  }

  // console.log("userinLoaclStorage._id");
  // console.log(userinLoaclStorage._id);

  // useEffect(() => {
  //   async function checkuser(){
  //     if (userinLoaclStorage != null) {
  //     await  dispatch(getOneUser(userinLoaclStorage._id));
  //    } 
  //    else {
  //      setIsAdmin(false);
  //      //setIsResturantAdmin(false);
  //    }
  //   }

  //   checkuser();
   
  // }, []);

  // let user = useSelector((state) => state.usersReducers.oneUser);

  // useEffect(()=>{
  //   console.log('user use effect');
  //   console.log(user);
  //   if(user){
  //      if (user.role === "admin") {
  //   //setIsResturantAdmin(false);
  // //  setIsAdmin(true);
  // isAdmin=true;
  // }
  // else{
  //   isAdmin=false;
  // }
  //   }
  // },[user]);
 


  return [isAdmin];
};

export default ProtectedRouteHook;
