import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";

import {
  getOneSubCharacter,
  updateSubCharacter
} from "../../redux/actions/subCharactersActions";

const AdminEditSubCharacterHook = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const[name,setName]=useState("");
  const[mainId,setMainId]=useState("");
  //console.log(id);
  useEffect(()=>{
   dispatch(getOneSubCharacter(id));
  },[]);

  let oneSub = useSelector((state) => state.subCharactersReducer.getOneSubCharacter);
  //console.log(oneSub);
  useEffect(()=>{
   setName(oneSub.name);
   setMainId(oneSub.charMainCategory);
  },[oneSub]);

  const onChangeName = (e) => {
    setName(e.target.value);
  };

  const onSubmit = async () => {
    if (name === "") {
        notify("من فضلك اكمل البيانات", "error");
        return;
      }
      setLoading(true);
      await dispatch(
        updateSubCharacter(id, {
          name: name
        })
      );
      setLoading(false);
    };
  
    const res = useSelector((state) => state.subCharactersReducer.updateOneSubCharacter);
  
    useEffect(() => {
      if (loading === false) {
        if (res) {
          notify("تم التعديل بنجاح", "success");
          setTimeout(() => {
            navigate(`/admin/subchar/${mainId}`);
          }, 1500);
        } else {
          notify("فشل في عملية التعديل", "error");
        }
      }
    }, [loading]);

  
  return [name, onChangeName, onSubmit];


}
export default AdminEditSubCharacterHook;