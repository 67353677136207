import {
    GET_ALL_MAIN_CHARACTERS,
    UPDATE_ONE_MAIN_CHARACTER,
    GET_ONE_MAIN_CHARACTER,
    ADD_MAIN_CHARACTER,
  } from "../type";

  import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";
import { useInsertData } from "../../hooks/useInsertData";

//get all 
export const getAllMainCharacters= () =>async(dispatch) =>{
    try {
        const response = await useGetDataToken(`/api/v1/charmain`);
    
        dispatch({
          type: GET_ALL_MAIN_CHARACTERS,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: GET_ALL_MAIN_CHARACTERS,
          payload: error,
        });
      }
}

//update 
export const updateOneMainCharacter = (id,body) => async(dispatch) =>{
    try {
        const response = await useUpdateData(`/api/v1/charmain/${id}`, body);
    
        dispatch({
          type: UPDATE_ONE_MAIN_CHARACTER,
          payload: response,
          loading: true,
        });
      } catch (error) {
        dispatch({
          type: UPDATE_ONE_MAIN_CHARACTER,
          payload: error,
        });
      }
}

//add 
export const addOneMainCharacter =(body) =>async(dispatch)=>{
    try {
        const response = await useInsertData(`/api/v1/charmain`, body);
    
        dispatch({
          type:ADD_MAIN_CHARACTER,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: ADD_MAIN_CHARACTER,
          payload: error,
        });
      }
}

//get one 
export const getOneMainCharacter=(id) =>async(dispatch) =>{
    try {
      const response = await useGetDataToken(`/api/v1/charmain/${id}`);
  
      dispatch({
        type: GET_ONE_MAIN_CHARACTER,
        payload: response,
        loading: true,
      });
    } catch (error) {
      dispatch({
        type: GET_ONE_MAIN_CHARACTER,
        payload: error,
      });
    }
  }