import React from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginHook from "../../hook/auth/LoginHook";
import { ToastContainer } from "react-toastify";
import logo from "../../Images/logo.jpg"
import { useSelector } from "react-redux";

const LoginPage = () => {
  const [
    val,
    password,
    onChangeVal,
    onChangePassword,
    loading,
    onSubmit,
    isPress,
  ] = LoginHook();

  const color = useSelector(
    (state) => state.settingsReducers.oneSettings.primaryColor
  );
  return (
    <Container style={{ minHeight: "670px" }}>
      <Row className="py-5 d-flex justify-content-center ">
        <Col sm="12" className="d-flex flex-column ">
          <label className="mx-auto title-login">تسجيل الدخول</label>
          <input
            value={val}
            onChange={onChangeVal}
            placeholder="الايميل او رقم الجول..."
            type="text"
            className="user-input my-3 text-center mx-auto"
          />
          <input
            value={password}
            onChange={onChangePassword}
            placeholder="كلمه السر..."
            type="password"
            className="user-input text-center mx-auto"
          />
          <button onClick={onSubmit} className="btn-login mx-auto mt-4" style={{ backgroundColor: color }}>
            تسجيل الدخول
          </button>
          {isPress === true ? (
            loading === true ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : null
          ) : null}
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default LoginPage;
