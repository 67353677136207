import { useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { getAllPolls, updateOnePoll } from "../../redux/actions/pollsActions";
import notify from "../useNotification";

export default function AdminPollsHook() {
    
    const dispatch=useDispatch();

    useEffect(()=>{
        dispatch(getAllPolls());
    },[]);

    const allPolls=useSelector(state=>state.pollsReducer.polls);

    const handleShowFun=async(id,status)=>{
        try {
            await dispatch(updateOnePoll(id, {
                status
              }));

            notify('تم التعديل بنجاح',"success");

            await dispatch(getAllPolls());
            
        } catch (error) {
            notify('حدث خطأ اثناء التعديل حاول مرة اخري',"error");
            
        }
    }

    console.log('allPolls',allPolls);

    return[
        allPolls,
        handleShowFun
    ];
}
