import {
    GET_ALL_CONTACTS,
    GET_ONE_CONTACT,
    UPDATE_ONE_CONTACT,
    ADD_ONE_CONTACT
  } from "../type";
  const inital = {
    allContacts:[],
    oneContact:[],
    updateContact:[],
    addContact:[]
  };
  
  const contactsReducer = (state = inital, action) => {
    switch (action.type) {
        case GET_ALL_CONTACTS:
            return{
              ...state,
              allContacts:action.payload
            };
        case GET_ONE_CONTACT:
            return{
                ...state,
                oneContact:action.payload
            };
        case UPDATE_ONE_CONTACT:
            return{
                ...state,
                updateContact:action.payload
            };
        case ADD_ONE_CONTACT:
            return{
                ...state,
                addContact:action.payload
            };
        default:
            return state
    }
}

export default contactsReducer;