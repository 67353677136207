import {
  ALL_USERS,
  GET_ONE_USER,
  UPDATE_USER,
  ADD_USER_BY_ADMIN,
  GET_ONE_USER_TOKEN,
  GET_ALL_USERS_WITH_PAGINATION,
  UPDATE_USERS_COLORS,
} from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import {
  useUpdateData,
  useUpdateDataWithImage,
} from "../../hooks/useUpdateData";
import {
  useInsertData,
  useInsertDataWithImage,
} from "../../hooks/useInsertData";
// import { type } from "@testing-library/user-event/dist/type";

//get All users
export const getAllUsers = () => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/users/search/get`);

    dispatch({
      type: ALL_USERS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: ALL_USERS,
      payload: error,
    });
  }
};

// get all users with pagination
export const getAllUsersWithPagination =
  ({ page, keyword, type }) =>
  async (dispatch) => {
    try {
      let query = `/api/v1/users/admin?page=${page}`;

      if (keyword != undefined) query += `&keyword=${keyword}`;
      if (type != undefined) query += `&type=${type}`;

      const response = await useGetDataToken(query);

      dispatch({
        type: GET_ALL_USERS_WITH_PAGINATION,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_USERS_WITH_PAGINATION,
        payload: error,
      });
    }
  };

// get one user
export const getOneUser = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/users/${id}?web=1`);
    dispatch({
      type: GET_ONE_USER,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_USER,
      payload: error,
    });
  }
};

// get one user with new token
export const getOneUserWithToken = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(
      `/api/v1/users/refreshUserToken/${id}`
    );

    dispatch({
      type: GET_ONE_USER_TOKEN,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_USER_TOKEN,
      payload: error,
    });
  }
};
// add user
export const addUser = (body) => async (dispatch) => {
  try {
    const response = await useInsertDataWithImage(`/api/v1/users`, body);
    dispatch({
      type: ADD_USER_BY_ADMIN,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADD_USER_BY_ADMIN,
      payload: error.response,
    });
  }
};

// update user
export const updateUser = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateDataWithImage(`/api/v1/users/${id}`, body);

    dispatch({
      type: UPDATE_USER,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER,
      payload: error,
    });
  }
};

// update user
export const updateUsersColor = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(
      `/api/v1/users/update/users/colos/update-color/${id}`,
      body
    );

    dispatch({
      type: UPDATE_USERS_COLORS,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USERS_COLORS,
      payload: error,
    });
  }
};
