import React from "react";
import AdminAddCountry from "../../Components/Admin/AdminAddCountry";
import SideBar from "../../Components/Admin/SideBar";

const AdminAddCountryPage = ()=>{
    return (
        <div className="d-flex justify-content-start">
        <div>
          <SideBar />
        </div>
        <div className="p-4" style={{ width: '100%' }}>
          <AdminAddCountry />
        </div>
      </div>
    )
}

export default AdminAddCountryPage