import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData, useUpdateDataWithImage } from "../../hooks/useUpdateData";
import { useInsertData, useInsertDataWithImage } from "../../hooks/useInsertData";
import { GET_ALL_POLLS , UPDATE_ONE_POLL , ADD_ONE_POLL } from "../type";

// get all polls
export const getAllPolls=()=>async(dispatch)=>{
try {
    const response = await useGetDataToken(`/api/v1/polls`);
    dispatch({
        type:GET_ALL_POLLS,
        payload:response
    });

} catch (error) {
    dispatch({
        type: GET_ALL_POLLS,
        payload: error,
      });
}
}

// add poll
export const addOnePoll=(body)=>async(dispatch)=>{
    try {
        const response = await useInsertData(`/api/v1/polls`, body);

        dispatch({
            type: ADD_ONE_POLL,
            payload: response,
          });

    } catch (error) {
        dispatch({
            type: ADD_ONE_POLL,
            payload: error,
          });
    }
}

// ADD_ONE_POLL

// update poll
export const updateOnePoll=(id,body)=>async(dispatch)=>{
    try {
        const response = await useUpdateData(`/api/v1/polls/${id}`, body);

        dispatch({
            type: UPDATE_ONE_POLL,
            payload: response,
          });

    } catch (error) {
        dispatch({
            type: UPDATE_ONE_POLL,
            payload: error,
          });
    }
}