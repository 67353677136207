import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addFixedPage } from "../../redux/actions/fixedPagesActions";
import { useNavigate } from "react-router-dom";
import avatar from "../../../src/Images/adminIcon.png";
import notify from "../useNotification";

const AdminAddFixedPagesHook = () => {
     //console.log('add hook');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const[description,setDescription]=useState("");
    const [loadingData, setLoadingData] = useState(true);

    const [title, setTitle] = useState("");
    //const [description, setDescription] = useState("");
    const[image,setImage]=useState(avatar);
    const [selectedFile, setSelectedFile] = useState(null);
    const[type,setType]=useState("box");

    const descRef=useRef();

    let onChangeSetTitle = (e) => {
        e.persist();
        setTitle(e.target.value);
      };

    let onChangeSetDescription =(e) =>{
        e.persist();

        console.log('first');
        //setDescription(e.target.value);
    }

    let onImageChange = (event) => {
        if (event.target.files && event.target.files[0])
          setImage(URL.createObjectURL(event.target.files[0]));
          setSelectedFile(event.target.files[0]);
      };
    
    let onChangeType= (e)=>{
      e.persist();
      setType(e.target.value);
    }

      const onSubmit = async () => {

        // console.log('description');
        // console.log(description);
        //console.log(description.getContent().toString());
        //return;
       
        setLoading(true);
        const formData = new FormData();
        formData.append("title", title);
        formData.append("desc", descRef.current.getContent());
        formData.append("image", selectedFile);
        formData.append("type",type);
        
        await dispatch(addFixedPage(formData));
        setLoading(false);
      };

      useEffect(() => {
        if (loading === false) {
          //  console.log('done')
          //setTitle("");
          //setDescription("");
          notify("تم الإضافه  بنجاح", "success");
          setTimeout(() => {
            navigate(`/admin/fixedpages`);
          }, 1500);
        }
      }, [loading]);

      return [
        title,
        //description,
        //setDescription,
        descRef,
        image,
        type,
        onChangeSetTitle,
        onChangeSetDescription,
        onImageChange,
        onChangeType,
        description,setDescription,
        onSubmit
      ];


}

export default AdminAddFixedPagesHook;