import React, { useEffect, useState } from "react";
import avatar from "../../Images/avatar.png";
import notify from "../../hook/useNotification";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedLogo,
  selectedPrimaryColor,
} from "../../redux/actions/utilitiesAction";
import {
  getOneSettings,
  updateSettings,
  updateTreeByAdmin,
} from "../../redux/actions/settingsActions";
import {
  getAllUsers,
  updateUsersColor,
} from "../../redux/actions/usersActions";

const AdminSettingsHook = () => {
  const [facebook, setFacebook] = useState("");
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [allUsers, setAllUsers] = useState("");

  const [img, setImg] = useState(avatar);
  //FamilyTreeWithSymbol
  const [familyTreeWithSymbolImg, setFamilyTreeWithSymbolImg] =
    useState(avatar);
  //PrintedFamilyTree
  const [printedFamilyTreeImg, setPrintedFamilyTreeImg] = useState(avatar);
  //default photo for birth-death-monasba
  const [birthImg, setBirthImg] = useState(avatar);
  const [deathImg, setDeathImg] = useState(avatar);
  const [monasbaImg, setMonasbaImg] = useState(avatar);

  const [titleAr, settitleAr] = useState("");
  const [titleEn, settitleEn] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingTree, setLoadingTree] = useState(false);

  const [showSmsCode, setShowSmsCode] = useState("true");

  const [colors, setColors] = useState("");
  const [seccolors, setSeccolors] = useState("");

  const [seccolors__, setseccolors__] = useState("");

  const [vat, setVat] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const [selectedFamilySymbolFile, setSelectedFamilySymbolFile] =
    useState(null);
  const [selectedPrintedFamilyTreeFile, setSelectedPrintedFamilyTreeFile] =
    useState(null);
  const [selectedBirthImgFile, setSelectedBirthImgFile] = useState(null);
  const [selectedDeathImgFile, setSelectedDeathImgFile] = useState(null);
  const [selectedMonasbaImgFile, setSelectedMonasbaImgFile] = useState(null);

  const [showPickerseccolors, setShowPickerseccolors] = useState(false);
  const [showPickerseccolors__, setShowPickerseccolors__] = useState(false);
  const [userId, setUserId] = useState("");

  const dispatch = useDispatch();

  const onChangeUserId = (value) => {
    //console.log('first');
    //console.log(value._id)
    setUserId(value);
  };

  const onChangePickerseccolors__ = (e) => {
    setShowPickerseccolors__(!showPickerseccolors__);
  };

  const onChangePickerseccolors = (e) => {
    setShowPickerseccolors(!showPickerseccolors);
  };

  const handleChaneCompleteseccolors__ = (color) => {
    setseccolors__(color.hex);
    setShowPickerseccolors__(!showPickerseccolors__);
  };

  const handleChaneCompleteseccolors = (color) => {
    setSeccolors(color.hex);
    setShowPickerseccolors(!showPickerseccolors);
  };

  const onChangePicker = (e) => {
    setShowPicker(!showPicker);
  };

  const handleChaneComplete = (color) => {
    setColors(color.hex);
    setShowPicker(!showPicker);
  };

  const onChangeFacebook = (e) => {
    e.persist();
    setFacebook(e.target.value);
  };
  const onChangePhone = (e) => {
    e.persist();
    setPhone(e.target.value);
  };

  const onChangeVat = (e) => {
    e.persist();

    setVat(e.target.value);
  };
  const onChangePhone2 = (e) => {
    e.persist();
    setPhone2(e.target.value);
  };
  const onChangeEmail = (e) => {
    e.persist();
    setEmail(e.target.value);
  };
  const onChangeAddress = (e) => {
    e.persist();
    setAddress(e.target.value);
  };

  const onChangeTitleAr = (e) => {
    e.persist();
    settitleAr(e.target.value);
  };
  const onChangeTitleEn = (e) => {
    e.persist();
    settitleEn(e.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImg(URL.createObjectURL(event.target.files[0]));
      setSelectedFile(event.target.files[0]);
    }
  };

  const onFamilyTreeWithSymbolImgChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFamilyTreeWithSymbolImg(URL.createObjectURL(event.target.files[0]));
      setSelectedFamilySymbolFile(event.target.files[0]);
    }
  };

  const onPrintedFamilyTreeImgChange = (event) => {
    console.log("ccccccccccccccccccccccccccccc");
    if (event.target.files && event.target.files[0]) {
      setPrintedFamilyTreeImg(URL.createObjectURL(event.target.files[0]));
      setSelectedPrintedFamilyTreeFile(event.target.files[0]);
    }
  };

  const onBirthImgChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setBirthImg(URL.createObjectURL(event.target.files[0]));
      setSelectedBirthImgFile(event.target.files[0]);
    }
  };

  const onDeathImgChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setDeathImg(URL.createObjectURL(event.target.files[0]));
      setSelectedDeathImgFile(event.target.files[0]);
    }
  };

  const onMonasbaImgChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setMonasbaImg(URL.createObjectURL(event.target.files[0]));
      setSelectedMonasbaImgFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    const run = async () => {
      await dispatch(getOneSettings());
    };
    run();
  }, []);

  const item = useSelector((state) => state.settingsReducers.oneSettings);
  // console.log(item);
  // console.log(item._id);
  const treeResponse = useSelector(
    (state) => state.settingsReducers.updateTree
  );

  // console.log('treeResponse');
  // console.log(treeResponse);

  const updateTree = async () => {
    setLoadingTree(true);
    await dispatch(updateTreeByAdmin());
    setLoadingTree(false);
  };

  useEffect(() => {
    if (loadingTree === false && treeResponse)
      notify("تم تحديث الشجرة بنجاح", "success");
  }, [treeResponse, loadingTree]);

  const onChangeShowSmsCode = async (e) => {
    //console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
    setShowSmsCode(e.target.value);
  };

  //   formData.append("monasbaImage", selectedMonasbaImgFile);
  useEffect(() => {
    if (item) {
      if (item?.logo) setImg(item?.logo);
      else setImg(avatar);

      if (item?.FamilyTreeWithSymbol)
        setFamilyTreeWithSymbolImg(item?.FamilyTreeWithSymbol);
      else setFamilyTreeWithSymbolImg(avatar);

      if (item?.PrintedFamilyTree)
        setPrintedFamilyTreeImg(item?.PrintedFamilyTree);
      else setPrintedFamilyTreeImg(avatar);

      if (item?.birthImage) setBirthImg(item?.birthImage);
      else setBirthImg(avatar);

      if (item?.deathImage) setDeathImg(item?.deathImage);
      else setDeathImg(avatar);

      if (item?.monasbaImage) setMonasbaImg(item?.monasbaImage);
      else setMonasbaImg(avatar);

      setFacebook(item?.facebook);
      setPhone(item?.phone);
      setPhone2(item?.phone2);
      setEmail(item?.email);
      setAddress(item?.address);
      settitleAr(item?.titleAr);
      settitleEn(item?.titleEn);
      setColors(item?.primaryColor);
      setSeccolors(item?.secondaryColor);
      setVat(item.vat);
    }
  }, [item]);

  const onSubmit = async () => {
    const formData = new FormData();
    formData.append("facebook", facebook);
    formData.append("phone", phone);
    formData.append("phone2", phone2);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("logo", selectedFile);
    formData.append("symbolImage", selectedFamilySymbolFile);
    formData.append("printedImage", selectedPrintedFamilyTreeFile);
    formData.append("birthImage", selectedBirthImgFile);
    formData.append("deathImage", selectedDeathImgFile);
    formData.append("monasbaImage", selectedMonasbaImgFile);
    formData.append("titleAr", titleAr);
    formData.append("titleEn", titleEn);
    formData.append("primaryColor", colors);
    formData.append("seccolors", seccolors);
    formData.append("secondaryColor", seccolors);
    formData.append("id", item._id);
    formData.append("showSmsCode", showSmsCode);

    setLoading(true);
    await dispatch(updateSettings(item._id, formData));
    setLoading(false);
  };
  const res = useSelector((state) => state.settingsReducers.updateSettings);

  //console.log(res)
  useEffect(() => {
    if (loading === false) {
      if (res.status === 200) {
        notify("تم الاضافة بنجاح", "success");
        dispatch(getOneSettings());
      } else {
        notify("فشل في عملية الاضافه", "error");
      }
    }
  }, [loading]);

  useEffect(() => {
    const __ = async () => {
      await dispatch(getAllUsers());
    };
    __();
  }, []);

  let Users = useSelector((state) => state.usersReducers.allUser);

  useEffect(() => {
    setAllUsers(Users);
  }, [Users]);
  const [loading__, setLoading__] = useState(true);

  const onSubmit_color = async () => {
    setLoading__(true);
    await dispatch(updateUsersColor(userId._id, { color: seccolors__ }));
    setLoading__(false);
  };

  let updateuserscolro = useSelector(
    (state) => state.usersReducers.updateUsersColor
  );

  useEffect(() => {
    if (loading__ == false && updateuserscolro != null) {
      notify("تم التعديل بنجاح", "success");
    }
  }, [updateuserscolro, loading__]);
  return [
    facebook,
    phone,
    onChangeFacebook,
    onChangePhone,
    onSubmit,
    phone2,
    onChangePhone2,
    email,
    onChangeEmail,
    address,
    onChangeAddress,
    titleAr,
    titleEn,
    onChangeTitleAr,
    onChangeTitleEn,
    showPicker,
    colors,
    handleChaneComplete,
    onChangePicker,
    img,
    familyTreeWithSymbolImg,
    printedFamilyTreeImg,
    birthImg,
    deathImg,
    monasbaImg,

    onImageChange,
    onFamilyTreeWithSymbolImgChange,
    onPrintedFamilyTreeImgChange,
    onBirthImgChange,
    onDeathImgChange,
    onMonasbaImgChange,

    seccolors,
    onChangePickerseccolors,
    handleChaneCompleteseccolors,
    showPickerseccolors,
    vat,
    onChangeVat,
    updateTree,
    loadingTree,

    showSmsCode,
    onChangeShowSmsCode,
    seccolors__,
    onChangePickerseccolors__,
    showPickerseccolors__,
    handleChaneCompleteseccolors__,
    allUsers,
    onChangeUserId,
    onSubmit_color,
  ];
};
export default AdminSettingsHook;
