import { GET_ALL_SETTING_FOR_USER , UPDATE_SETTINGS_FOR_ONE_USER } from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";


// get all settings for  user
export const getAllSettingsOneUser = (id) => async (dispatch) => {
    try {
      const response = await useGetDataToken(`/api/v1/usersettings/user/${id}`);
      dispatch({
        type: GET_ALL_SETTING_FOR_USER,
        payload: response,
        loading: true,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_SETTING_FOR_USER,
        payload: error,
      });
    }
  };

  //update setting for one user
  export const updateSettingsOneUser = (id, body) => async (dispatch) => {
    try {
        //console.log('updateSettingsOneUser');
        
      const response = await useUpdateData(`/api/v1/usersettings/${id}`, body);
  
      dispatch({
        type: UPDATE_SETTINGS_FOR_ONE_USER,
        payload: response,
        loading: true,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SETTINGS_FOR_ONE_USER,
        payload: error,
      });
    }
  };