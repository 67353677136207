import {
  UPDATE_USERS_COLORS,
  ALL_USERS,
  GET_ONE_USER,
  UPDATE_USER,
  ADD_USER_BY_ADMIN,
  GET_ONE_USER_TOKEN,
  GET_ALL_USERS_WITH_PAGINATION,
} from "../type";
const inital = {
  allUser: [],
  oneUser: [],
  oneUserToken: [],
  updateUser: null,
  addUser: null,
  usersPerPage: [],
  updateUsersColor: null,
};

const usersReducers = (state = inital, action) => {
  switch (action.type) {
    case ALL_USERS:
      return {
        ...state,
        allUser: action.payload,
        // updateUser: null,
        // addUser: null
      };
    case GET_ONE_USER:
      return {
        ...state,
        oneUser: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        updateUser: action.payload,
      };

    case UPDATE_USERS_COLORS:
      return {
        ...state,
        updateUsersColor: action.payload,
      };
    case GET_ONE_USER_TOKEN:
      return {
        ...state,
        oneUserToken: action.payload,
      };

    case ADD_USER_BY_ADMIN:
      return {
        ...state,
        addUser: action.payload,
      };
    case GET_ALL_USERS_WITH_PAGINATION:
      return {
        ...state,
        usersPerPage: action.payload,
        updateUser: null,
        addUser: null,
      };
    default:
      return state;
  }
};

export default usersReducers;
