import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminAddGalleryHook from "../../hook/admin/AdminAddGalleryHook";

const AdminAddGallery = () => {
 
  const { t } = useTranslation();
   const [
    title,
    img,
    selectedFile,
    status,
    eventDate,
    onChangeTitle,
    onImageChange,
    onChangeStatus,
    onChangeEventDate,
    onSubmit
   ] = AdminAddGalleryHook();
     

  const color = useSelector(
    (state) => state.settingsReducers.oneSettings.primaryColor
  );

  return (
    <div>
      <title>اضافة معرض </title>
      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">اضافة معرض</div>

        <Col sm="8">
          <div className="text-form pb-2">{t("image")}</div>
          <div>
            <label htmlFor="upload-photo">
              <img
                src={img}
                alt="fzx"
                height="120px"
                width="120px"
                style={{ cursor: "pointer" }}
              />
            </label>
            <input
              type="file"
              name="photo"
              onChange={onImageChange}
              id="upload-photo"
            />
          </div>

          <label htmlFor="firstname" className="d-block mt-2 ">
            العنوان
            
          </label>
          <input
            id="firstname"
            value={title}
            onChange={onChangeTitle}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder='العنوان'
          />

          <label htmlFor="countries" className="d-block mt-3 ">
            الحالة
        </label>
        <select
          id="countries"
          name="countries"
          onChange={onChangeStatus}
          className="select input-form-area mt-2 px-3 "
        >
          <option value="isShare">جاهز للمشاركة</option>
          <option value="isLive">نشط</option>
          <option value="noActive">غير نشط</option>
        </select>

        <label className="d-block mt-2 ">تاريخ الحدث</label>
          <input
            type="date"
            value={eventDate}
            onChange={onChangeEventDate}
            className="input-form d-block mt-2 px-3"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-3 "
            style={{ backgroundColor: color }}
          >
            {t("add")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
}

export default AdminAddGallery;