import {
     GET_ALL_DOCUMENTS,
     UPDATE_ONE_DOCUMENT,
     ADD_DOCUMENT,
     GET_ONE_DOCUMENT
  } from "../type";

import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateDataWithImage } from "../../hooks/useUpdateData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";

//get all documents
export const getAllDocuments= () =>async(dispatch) =>{
    try {
        const response = await useGetDataToken(`/api/v1/documents`);
    
        dispatch({
          type: GET_ALL_DOCUMENTS,
          payload: response,
          loading: true,
        });
      } catch (error) {
        dispatch({
          type: GET_ALL_DOCUMENTS,
          payload: error,
        });
      }
}

//add document
export const addOneDocument =(body) =>async(dispatch)=>{
    try {
        const response = await useInsertDataWithImage(`/api/v1/documents`, body);
    
        dispatch({
          type: ADD_DOCUMENT,
          payload: response,
        });
      } catch (error) {
        dispatch({
          type: ADD_DOCUMENT,
          payload: error,
        });
      }
}

//update document
export const updateOneDocument = (id,body) => async(dispatch) =>{
    try {
        const response = await useUpdateDataWithImage(`/api/v1/documents/${id}`, body);
    
        dispatch({
          type: UPDATE_ONE_DOCUMENT,
          payload: response,
          loading: true,
        });
      } catch (error) {
        dispatch({
          type: UPDATE_ONE_DOCUMENT,
          payload: error,
        });
      }
}

//get one document
export const getOneDocument=(id) =>async(dispatch) =>{
  try {
    const response = await useGetDataToken(`/api/v1/documents/${id}`);

    dispatch({
      type: GET_ONE_DOCUMENT,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_DOCUMENT,
      payload: error,
    });
  }
}