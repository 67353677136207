import {
    GET_NEWS_ACTIONS_FOR_ONE_NEWS
  } from "../type";
  const inital = {
    oneNewsActions:[]
  };
  
const newsActionsReducer = (state = inital, action) => {
    switch (action.type) {
     case GET_NEWS_ACTIONS_FOR_ONE_NEWS:
        return{
            ...state,
            oneNewsActions:action.payload
        };

     default:
            return state;
    }
}
export default newsActionsReducer;
