import {
    GET_ALL_SUB_CHARACTERS,
    UPDATE_ONE_SUB_CHARACTER,
    GET_ONE_SUB_CHARACTER,
    ADD_SUB_CHARACTER,
  } from "../type";
  const inital = {
    allSubCharacters: [],
    getOneSubCharacter: [],
    updateOneSubCharacter: [],
    addOneSubCharacter: [],
  };
  
  const subCharactersReducer = (state = inital, action) => {
    switch (action.type) {
        case GET_ALL_SUB_CHARACTERS:
            return{
                ...state,
                allSubCharacters:action.payload
            };

        case UPDATE_ONE_SUB_CHARACTER:
            return{
                ...state,
                updateOneSubCharacter:action.payload
            };
        
        case GET_ONE_SUB_CHARACTER:
            return{
                ...state,
                getOneSubCharacter:action.payload
            };
        
        case ADD_SUB_CHARACTER:
            return{
                ...state,
                addOneSubCharacter:action.payload
            };

        default:
            return state;
    }
}

export default subCharactersReducer;

      