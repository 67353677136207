import { useState } from "react";
import notify from "../../hook/useNotification";
import { useParams } from "react-router-dom";

export default function AddPoll({setIterator,polls,setPolls,index}) {

    const{id}=useParams();

    const[newPoll,setNewPoll]=useState(()=> id ? polls[index]?.desc :"");
    const[isDeleted,setIsDeleted]=useState(false);
    const[isPressed,setIsPressed]=useState(false);

    console.log('newPoll',newPoll);

    return (
        <div className={`${isDeleted ? 'd-none' : ''} d-flex my-4 mt-2  justify-content-between`}>
            <input
            value={newPoll}
            onChange={(e)=>setNewPoll(e.target.value)}
                type="text"
                className="input-form w-75"
                placeholder="ادخل مقترح"
            />
            {
                (id && index <polls?.length) ?  
                <button className={`btn btn-warning ` } onClick={()=>{
                    if(newPoll=="" || newPoll!=undefined) return notify("لا يمكن ادخال مقترح فارغ","error");

                    let new_polls=polls?.map(poll=>{
                        if(poll.key==index){
                            return {
                                ...poll,
                                desc:newPoll
                            }
                        } 
                        else return poll
                    });

                    setPolls(new_polls);
                 
    
                }}>
                    تعديل
                </button>
                :
                <button className={`btn btn-success ${isPressed ? 'd-none' : ''}` } onClick={()=>{
                    if(newPoll=="") return notify("لا يمكن ادخال مقترح فارغ","error");
    
                    let key;
    
                    setIterator(prev=>{
                        let new_item=prev.length+1;
                        key=prev.length+1;
    
                        console.log('new_item',new_item);
                      return  [...prev,new_item]
                    });
    
                    let newPollObj={
                        key,
                        desc:newPoll,
                        count:0
                    };
    
                    setPolls(prev=>{
                        return[
                            ...prev,
                        newPollObj
                        ]
                    });
    
                    setIsPressed(true);
    
                }}>
                    اضافة
                </button>
            }
            

            <button className="btn btn-danger" onClick={()=>{
                setIsPressed(false);
                let new_polls=polls.filter(el=>el.desc!==newPoll);
                setIsDeleted(true);
                setPolls(new_polls);
                setNewPoll("");

            }}>
                حذف
            </button>
        </div>
    )
}
