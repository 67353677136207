import {
    GET_ALL_SUB_CHARACTERS,
    UPDATE_ONE_SUB_CHARACTER,
    GET_ONE_SUB_CHARACTER,
    ADD_SUB_CHARACTER,
  } from "../type";
  import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";
import { useInsertData } from "../../hooks/useInsertData";

  //get all sub char for one main character
export const getAllSubCharactersForOneMain = (id) => async (dispatch) => {
    try {
      const response = await useGetDataToken(`/api/v1/charsub/mainCharacter/${id}`);
  
      dispatch({
        type: GET_ALL_SUB_CHARACTERS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_SUB_CHARACTERS,
        payload: error,
      });
    }
  };

  //get one subcharacter
export const getOneSubCharacter = (id) => async (dispatch) => {
    try {
      const response = await useGetDataToken(`/api/v1/charsub/${id}`);
  
      dispatch({
        type: GET_ONE_SUB_CHARACTER,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ONE_SUB_CHARACTER,
        payload: error,
      });
    }
  };

  //add sub
export const addSubCharacter = (body) => async (dispatch) => {
    try {
      const response = await useInsertData(`/api/v1/charsub`, body);
  
      dispatch({
        type: ADD_SUB_CHARACTER,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: ADD_SUB_CHARACTER,
        payload: error,
      });
    }
  };

  //update one sub
export const updateSubCharacter = (id, body) => async (dispatch) => {
    try {
      const response = await useUpdateData(`/api/v1/charsub/${id}`, body);
  
      dispatch({
        type: UPDATE_ONE_SUB_CHARACTER,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ONE_SUB_CHARACTER,
        payload: error,
      });
    }
  };