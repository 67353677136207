import {
  GET_ALL_GALLARIES,
  UPDATE_ONE_GALLARY,
  ADD_GALLARY,
  GET_ONE_GALLARY,
} from "../type";

import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateDataWithImage } from "../../hooks/useUpdateData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";

//get all gallaries
export const getAllGallaries = () => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/galleries`);

    dispatch({
      type: GET_ALL_GALLARIES,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_GALLARIES,
      payload: error,
    });
  }
};

//update gallery
export const updateOneGallery = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateDataWithImage(
      `/api/v1/galleries/${id}`,
      body
    );

    dispatch({
      type: UPDATE_ONE_GALLARY,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ONE_GALLARY,
      payload: error,
    });
  }
};

//add gallary
export const addOneGallary = (body) => async (dispatch) => {
  try {
    const response = await useInsertDataWithImage(`/api/v1/galleries`, body);

    dispatch({
      type: ADD_GALLARY,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADD_GALLARY,
      payload: error,
    });
  }
};

//get one gallery
export const getOneGallery = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/galleries/${id}`);

    dispatch({
      type: GET_ONE_GALLARY,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_GALLARY,
      payload: error,
    });
  }
};
