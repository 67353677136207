import {
    UPDATE_SETTINGS, GET_ONE_SETTINGS ,UPDATE_TREE
} from "../type";

const inital = {
    updateSettings: [],
    oneSettings: [],
    updateTree:''

}

const settingsReducers = (state = inital, action) => {
    switch (action.type) {
        case UPDATE_SETTINGS:
            return {
                ...state,
                updateSettings: action.payload
            }
        case GET_ONE_SETTINGS:
            return {
                ...state,
                oneSettings: action.payload
            }

        case UPDATE_TREE:
            return{
                ...state,
                updateTree:action.payload
            }
        default:
            return state
    }
}

export default settingsReducers;
