import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminEditFixedPagesHook from "../../hook/admin/AdminEditFixedPagesHook";
import avatar from "../../../src/Images/adminIcon.png";
import { Editor } from '@tinymce/tinymce-react';
import TextEditor from "../Utilities/TextEditor";

const AdminEditFixedPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  
 const[
  title,
  description,
  setDescription,
  descRef,
  image,
  type,
  onChangeSetTitle,
  onChangeSetDescription,
  onImageChange,
  onChangeType,
  onSubmit

 ] = AdminEditFixedPagesHook(id);
 const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

 return (
    <div>
      <title>تعديل الصفحات الثابتة </title>
      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">تعديل الصفحات الثابتة</div>

        <Col sm="8">
          <div className="text-form pb-2">{t("image")}</div>
          <div>
            <label htmlFor="upload-photo">
              <img
                src={image=='' ? avatar : image}
                alt="fzx"
                height="120px"
                width="120px"
                style={{ cursor: "pointer" }}
              />
            </label>
            <input
              type="file"
              name="photo"
              onChange={onImageChange}
              id="upload-photo"
            />
          </div>

          <label htmlFor="firstname" className="d-block mt-2 ">
            العنوان
            
          </label>
          <input
            id="firstname"
            value={title}
            onChange={onChangeSetTitle}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder='العنوان'
          />

          <label htmlFor="type" className="d-block mt-2 ">
          النوع    
        </label>
        <select
        id="type"
        value={type}
        onChange={onChangeType}
        className="select input-form-area mt-2 px-3 "
      >
        <option value="box">صندوق</option>
        <option value="instructions">تنبيهات</option>
        <option value="pedigree">النسب</option>
        <option value="club">النادي</option>
      </select>
  
          <label htmlFor="Description" className="d-block mt-3 mb-2  ">
            {t("Description")}
          </label>
          {/* <Editor
          initialValue={description}
           onInit={(evt,editor)=> {
            descRef.current=editor;
            //setDescription(editor);
          }}
           /> */}
           <TextEditor value={description} descRef={descRef} />
          

        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: color }}
          >
            {t("edit")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminEditFixedPage;
