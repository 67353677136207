import { UPDATE_SETTINGS, GET_ONE_SETTINGS , UPDATE_TREE } from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData, useUpdateDataWithImage } from "../../hooks/useUpdateData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";



//update Settings
export const updateSettings = (id=null, body) => async (dispatch) => {
    try {
        //console.log('update setting');
        const response = await useInsertDataWithImage(`/api/v1/settings/edit`, body);

        dispatch({
            type: UPDATE_SETTINGS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_SETTINGS,
            payload: error,
        });
    }
};

//get one Settings by id
export const getOneSettings = () => async (dispatch) => {
    try {
        const response = await useGetDataToken(`/api/v1/settings`);
        dispatch({
            type: GET_ONE_SETTINGS,
            payload: response
        })
    } catch (error) {
        dispatch({
            type: GET_ONE_SETTINGS,
            payload: error
        })
    }
}

//update tree
export const updateTreeByAdmin=()=>async(dispatch)=>{
    try {
        const response= await useGetDataToken('/api/v1/users/parent/tree/2');
        console.log('update tree response');
        console.log(response);
        let random=Math.random();
        console.log(random);
        dispatch({
            type:UPDATE_TREE,
            payload:`updated ${random}`
        })

    } catch (error) {
        dispatch({
            type:UPDATE_TREE,
            payload:error
        })
    }
}
