import React from 'react'
import AdminPollsHook from '../../hook/admin/AdminPollsHook';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, ToastContainer } from 'react-bootstrap';
import AddButton from '../Utilities/AddButton';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";


export default function AdminPollsComponent() {
   const[allPolls,handleShowFun] =AdminPollsHook();
   const { t } = useTranslation();
   const navigate = useNavigate();


   let columns = [
    {
      name: 'العنوان',
      selector: "title",
      sortable: true,
    },
    {
      name: t("modify"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() =>{
            navigate(`/admin/editPoll/${row._id}`,{
              state:row
            });
          } }
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    {
      name: t("state"),
      cell: (row) =>
        row.status == true ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false);
            }}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true);
            }}
            id={row._id}
          >
            {t("active")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: allPolls?.data,
  };

  return (
    <div>
        <title> الاستبيان </title>

        <div className="d-flex justify-content-between">
         <div className="admin-content-text"> ادارة الاستبيانات </div>
         <AddButton title={'اضافة استبيان'} navigateTo='/admin/addPoll' />
       </div>

       <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
         <DataTable
           noHeader
           defaultSortAsc={false}
           pagination
           highlightOnHover
         />
       </DataTableExtensions>
       <ToastContainer />
    </div>
  )
}
