import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, getAllUsersWithPagination, updateUser } from "../../redux/actions/usersActions";
import { useNavigate, useSearchParams } from "react-router-dom";
import notify from "../useNotification";

const ViewAllUsersHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  // const[searchKeyWord,setSearchKeyWord]=useState("");

  //let user = [];



  const handleShowFun = async (id, status) => {
    try {
      setLoading(true);
      await dispatch(
        updateUser(id, {
          status: status
        })
      );
      setLoading(false);

      notify("تم التعديل بنجاح", "success");

      let page;
      searchParams.get('page') ? page = searchParams.get('page') : page = 1;

      let obj = {
        page
      };
      let keyword = searchParams.get('keyword');

      let type = searchParams.get('type');


      if (type) obj.type = type;

      if (keyword) obj.keyword = keyword;

      dispatch(getAllUsersWithPagination(obj));


    } catch (error) {
      notify("فشل في عملية التعديل", "error");
    }

  };
  const res = useSelector((state) => state.usersReducers.updateUser);







  useEffect(() => {
    let page;
    searchParams.get('page') ? page = searchParams.get('page') : page = 1;

    let keyword = searchParams.get('keyword');

    let obj = {
      page
    };

    if (keyword) obj.keyword = keyword;

    let type = searchParams.get('type');



    if (type) obj.type = type;

    setLoading(true);
    dispatch(getAllUsersWithPagination(obj));
    setLoading(false);
  }, []);

  const allUsers = useSelector((state) => state.usersReducers.usersPerPage);
  // let users = [];
  // if (allUsers) users = allUsers;

  console.log('allUsers', allUsers);

  const getPage = async (page) => {

    let obj = {
      page
    };
    let keyword = searchParams.get('keyword');

    if (keyword) obj.keyword = keyword;

    let type = searchParams.get('type');



    if (type) obj.type = type;

    setLoading(true);
    await dispatch(getAllUsersWithPagination(obj));
    setLoading(false);
    console.log('page', page);
  }

  const searchByWord = async () => {
    let page = 1;
    let keyword = searchParams.get('keyword');

    keyword = keyword.trim();

    searchParams.set('page', 1);
    setSearchParams(searchParams);

    let type = searchParams.get('type');

    if(!searchParams.get('type')) return notify("من فضلك ادخل نوع البحث","error");

    let obj = {
      page, keyword
    };

    if (type) obj.type = type;


    setLoading(true);
    await dispatch(getAllUsersWithPagination(obj));
    setLoading(false);
  }

  const cancelFilter=async()=>{
    let obj = {
      page:1
    };

    // if (type) obj.type = type;
    searchParams.set('type','');
    searchParams.set('page',1);
    searchParams.set('keyword','');
    setSearchParams(searchParams);

    setLoading(true);
    await dispatch(getAllUsersWithPagination(obj));
    setLoading(false);
  }

  const clickHandler = (id) => {
    navigate(`/admin/edituser/${id}`);
  };

  return [allUsers, clickHandler, handleShowFun, getPage, loading, searchByWord , cancelFilter];
};

export default ViewAllUsersHook;
