import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminAllDocuments from "../../Components/Admin/AdminAllDocuments";
import SideBar from "../../Components/Admin/SideBar";

const AdminAllDocumentsPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
       <AdminAllDocuments />
      </div>
    </div>
  );
};

export default AdminAllDocumentsPage;
