import React from "react";
import { Col, Row, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import AdminEditUserHook from "../../hook/admin/AdminEditUserHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { CompactPicker } from "react-color";

import Select from "react-select/async";
import MultiImageInput from "react-multiple-image-input";
import { Editor } from "@tinymce/tinymce-react";
import FullNameFmlAddInputs from "../Utilities/FullNameFmlAddInputs";
import { useParams } from "react-router-dom";
import formatFmlObj from "../Utilities/formatFmlObj";
import TextEditor from "../Utilities/TextEditor";
import add from "../../Images/add.png";

const AdminEditUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [
    firstname,
    middleName,
    lastName,
    surName,

    gender,
    zone,
    img,
    selectedFile,
    username,
    password,
    confirmPassword,
    maritalStatus,
    partnerId,
    partnerNameFML,
    birthDate,
    jobArr,
    EduArr,
    cityId,
    socialMediaArr,
    brotherFMLArr,
    reda3aSonsArr,

    role,
    motherId,
    fatherId,
    motherFML,
    contactArr,
    description,
    countryId,
    mobile,
    isDead,
    facebook,
    twitter,
    snapchat,
    onChangeFirstname,
    onChangeMiddleName,
    onChangeLastName,
    onChangeSurName,

    onChangeGender,
    onChangeZone,
    onImageChange,
    onChangeUserName,
    onChangeMobile,
    onChangePassword,
    onChangeConfirmPassword,
    onChangeMaritalStatus,
    onChangePartnerId,
    onChangePartnerNameFML,
    onChangeBirthDate,
    onChangeJobArr,
    onChangeEduArr,
    onChangeCityId,
    onChangeSocialMediaArr,
    reda3aMothersArr,

    onChangeRole,
    onChangeMotherId,
    onChangeMotherFML,
    onChangeContactArr,
    onChangeDescription,
    onChangeCountryId,
    onChangeFatherId,
    onChangeIsDead,
    onChangeFacebook,
    onChangeTwitter,
    onChangeSnapchat,

    allUsers,
    mothers,
    countries,
    cities,
    fathers,
    key,
    onChangeKey,
    eduKey,
    onChangeEduKey,
    socialKey,
    onChangeSocialKey,
    onChangeBrotherFMLKey,
    reda3aMothersKey,
    brotherFMLKey,
    onChangeReda3aMothersKey,
    reda3aSonsKey,
    onChangeReda3aSonsKey,

    value,
    onChangeValue,
    eduValues,
    setEduValues,
    onChangeEduValues,
    socialValues,
    onChangesocialValues,
    brotherFMLValues,
    onChangeBrotherFMLValues,
    reda3aSonsValues,
    onChangeReda3aSonsValues,

    tablekeysValues,
    clickHandler,
    show,
    showEducation,
    showSocial,
    showBrothersFML,
    showReda3aMothers,
    showReda3aSons,

    handleShow,
    deleteElement,

    images,
    setImages,
    isVip,
    charSubCategoryVip,
    charMainCategoryVip,
    charSubCategoryVipId,
    charMainCategoryVipId,
    creatorVip,
    onChangeIsVip,
    onChangeCharMainCategoryVip,
    onChangeCharSubCategoryVip,
    onChangeDescVip,
    onChangeCreatorVip,
    allMainCharacters,
    allSubForMainCharacter,
    descVip,
    setDescVip,
    descVipRef,
    onSubmit,
    loading,
    colors,
    removeColor,
    onChangePicker,
    showPicker,
    handleChaneComplete,
  ] = AdminEditUserHook(id);

  let Users = useSelector((state) => state.usersReducers.allUser);

  console.log("fathers_____________");
  console.log(fatherId._id);
  console.log("fathers_____________");

  const color = useSelector(
    (state) => state.settingsReducers.oneSettings.primaryColor
  );

  // if(Users.length=='0') return <Spinner />;

  let fathersObj = fathers && formatFmlObj(fathers);

  let mothersObj = mothers && formatFmlObj(mothers);

  let partenersObj = allUsers && formatFmlObj(allUsers);

  console.log("fathersObj");
  console.log(fathersObj);

  let loadFathersOptions = async (inputText) =>
    fathersObj.filter((i) => i.fullname.includes(inputText));
  let loadMothersOptions = async (inputText) =>
    mothersObj.filter((i) => i.fullname.includes(inputText));
  let loadPartenersOptions = async (inputText) =>
    partenersObj.filter((i) => i.fullname.includes(inputText));

  return (
    <div>
      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">تعديل المستخدم</div>

        <Col sm="8">
          {Users?.length == 0 && "loading......"}

          <Tabs
            defaultActiveKey="generalinfo"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab
              eventKey="generalinfo"
              title="معلومات عامة"
              style={{ backgroundColor: "white" }}
            >
              <div>
                <label htmlFor="upload-photo">
                  <img
                    src={img}
                    alt="fzx"
                    height="120px"
                    width="120px"
                    style={{ cursor: "pointer" }}
                  />
                </label>
                <input
                  type="file"
                  name="photo"
                  onChange={onImageChange}
                  id="upload-photo"
                />
              </div>

              <label htmlFor="firstname" className="d-block mt-2">
                الاسم بالكامل
              </label>

              <div>
                <input
                  value={firstname}
                  onChange={onChangeFirstname}
                  type="text"
                  className="mt-2 px-3"
                  style={{ width: "24%" }}
                  placeholder="الاسم الاول"
                />

                <input
                  value={middleName}
                  onChange={onChangeMiddleName}
                  type="text"
                  className=" mt-2 px-3 mx-1"
                  style={{ width: "24%" }}
                  placeholder="الاسم الثاني"
                />

                <input
                  value={lastName}
                  onChange={onChangeLastName}
                  type="text"
                  className="mt-2 px-3 mx-1"
                  style={{ width: "24%" }}
                  placeholder="الاسم الثالث"
                />

                <input
                  value={surName}
                  onChange={onChangeSurName}
                  type="text"
                  className="mt-2 px-3 mx-1"
                  style={{ width: "24%" }}
                  placeholder="الاسم الرابع"
                />
              </div>
              <div className="mt-1 d-flex">
                <label htmlFor="firstname" className="d-block mt-2">
                  اللون
                </label>

                {/* Display the color if it exists */}
                {colors && (
                  <div
                    onClick={() => removeColor(colors)}
                    className="color ms-2 border mt-1 mx-3"
                    style={{ backgroundColor: colors }}
                  ></div>
                )}

                {/* Add color picker icon */}
                <img
                  src={add}
                  alt=""
                  width="30px"
                  height="35px"
                  style={{ cursor: "pointer" }}
                  onClick={onChangePicker}
                  className="mx-3"
                />

                {/* Show color picker if activated */}
                {showPicker && (
                  <CompactPicker onChangeComplete={handleChaneComplete} />
                )}
              </div>

              <label className="d-block mt-2">اسم الاب</label>
              <Select
                loadOptions={loadFathersOptions}
                onChange={onChangeFatherId}
                getOptionLabel={(e) => e.fullname}
                getOptionValue={(e) => e._id}
                value={fatherId}
                isClearable={true}
                // options={fathers}
              />

              <label className="d-block mt-2">
                اسم الام(يتم ملئ هذه الخانة في حالة ان العضو من داخل العائلة)
              </label>

              <Select
                loadOptions={loadMothersOptions}
                onChange={onChangeMotherId}
                getOptionLabel={(e) => e.fullname}
                getOptionValue={(e) => e._id}
                value={motherId}
                isClearable={true}
              />

              <label className="d-block mt-4 ">
                اسم الام(يتم ملئ هذه الخانة في حالة ان العضو ليس من العائلة)
              </label>

              <div>
                <FullNameFmlAddInputs
                  onChange={onChangeMotherFML}
                  className="motherFml"
                />
              </div>

              <label htmlFor="countries" className="d-block mt-3 ">
                النوع
              </label>
              <select
                onChange={onChangeGender}
                value={gender}
                className="select input-form-area mt-2 px-3 "
              >
                <option value="male">ذكر</option>
                <option value="female">انثي</option>
              </select>

              <label className="d-block mt-2 ">تاريخ الميلاد</label>
              <input
                type="date"
                value={birthDate}
                onChange={onChangeBirthDate}
                className="input-form d-block mt-2 px-3"
              />
            </Tab>

            <Tab
              eventKey="accountinfo"
              title="معلومات الحساب"
              style={{ backgroundColor: "white" }}
            >
              <label htmlFor="email" className="d-block mt-2 ">
                اسم المستخدم
              </label>
              <input
                className="input-form d-block mt-2 px-3"
                placeholder="اسم المستخدم"
                value={username}
                onChange={onChangeUserName}
              />

              <label htmlFor="phone" className="d-block mt-2 ">
                {t("phone")}
              </label>
              <input
                id="phone"
                value={mobile}
                onChange={onChangeMobile}
                type="number"
                className="input-form d-block mt-2 px-3"
                placeholder={t("phone")}
              />
              <label htmlFor="confirmPassword" className="d-block mt-2 ">
                {t("password")}
              </label>
              <input
                id="password"
                value={password}
                onChange={onChangePassword}
                className="input-form d-block mt-2 px-3"
                placeholder={t("password")}
                type="password"
              />
              <label htmlFor="confirmPassword" className="d-block mt-2 ">
                {" "}
                {t("confirm password")}{" "}
              </label>

              <input
                id="confirmPassword"
                value={confirmPassword}
                onChange={onChangeConfirmPassword}
                className="input-form d-block mt-2 px-3"
                placeholder={t("confirm password")}
                type="password"
              />
              <label className="d-block mt-2 ">العضو متوفي </label>
              <select
                onChange={onChangeIsDead}
                value={isDead}
                className="select input-form-area mt-2 px-3 "
              >
                <option value="true">نعم</option>
                <option value="false">لا</option>
              </select>

              <label className="d-block mt-2 ">الدولة</label>
              <select
                id="countries"
                name="countries"
                onChange={onChangeCountryId}
                value={countryId}
                className="select input-form-area mt-2 px-3 "
              >
                <option value="0">من فضلك اختر الدولة</option>
                {countries
                  ? countries.map((el) => {
                      return (
                        <option key={el._id} value={el._id}>
                          {el.name}
                        </option>
                      );
                    })
                  : null}
                )
              </select>

              <label className="d-block mt-2 ">المدن</label>
              <select
                onChange={onChangeCityId}
                value={cityId}
                className="select input-form-area mt-2 px-3 "
              >
                <option value="0">من فضلك اختر المدينة</option>
                {cities
                  ? cities.map((el) => {
                      return (
                        <option key={el._id} value={el._id}>
                          {el.name}
                        </option>
                      );
                    })
                  : null}
                )
              </select>

              <label className="d-block mt-2 ">المنطقة</label>
              <input
                className="input-form d-block mt-2 px-3"
                placeholder="المنطقة"
                value={zone}
                onChange={onChangeZone}
              />
            </Tab>

            <Tab
              eventKey="partnerinfo"
              title="معلومات شريك الحياة"
              style={{ backgroundColor: "white" }}
            >
              <label className="d-block mt-2 ">الحالة الاجتماعية</label>

              <select
                value={maritalStatus}
                onChange={onChangeMaritalStatus}
                name="type"
                id="type"
                className="select input-form-area mt-2 px-3 "
              >
                <option value="single">اعزب</option>
                <option value="married">متزوج</option>
              </select>

              <label className="d-block mt-2">
                اسم الشريك(يتم ملئ هذه الخانة في حالة ان الشريك عضو من العائلة)
              </label>

              <Select
                loadOptions={loadPartenersOptions}
                onChange={onChangePartnerId}
                getOptionLabel={(e) => e.fullname}
                getOptionValue={(e) => e._id}
                options={allUsers}
                value={partnerId}
                isClearable={true}
              />

              <label className="d-block mt-2 ">
                الشريك من خارج العائلة(يتم ملئ هذه الخانة في حالة ان الشريك ليس
                عضو بالعائلة)
              </label>

              <div className="mt-1 mb-2">
                <FullNameFmlAddInputs
                  onChange={onChangePartnerNameFML}
                  className="partnerFml"
                />
              </div>
            </Tab>

            <Tab
              eventKey="educationinfo"
              title="الوظائف والمراحل التعليمية"
              style={{ backgroundColor: "white" }}
            >
              <label className="d-block mt-2 ">الوظائف السابقة للعضو</label>
              <div>
                <button
                  onClick={() => handleShow("job")}
                  className="btn-save d-inline mt-2 "
                  style={{ backgroundColor: color }}
                >
                  اضافة وظائف
                </button>
              </div>
              {show ? (
                <div>
                  <input
                    style={{
                      borderColor: "gray",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderRadius: "6px",
                    }}
                    value={key}
                    onChange={onChangeKey}
                    className="d-inline-block  mt-2 p-1 "
                    placeholder="الشركة"
                  />

                  <input
                    style={{
                      borderColor: "gray",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderRadius: "6px",
                    }}
                    value={value}
                    onChange={onChangeValue}
                    className="d-inline-block mx-3 mt-2 p-1 "
                    placeholder="الوظيفة"
                  />

                  <button
                    onClick={() => clickHandler("job")}
                    className="btn-save d-inline mt-2 "
                    style={{
                      backgroundColor: color,
                      marginRight: "3%",
                    }}
                  >
                    {t("add")}
                  </button>
                </div>
              ) : (
                ""
              )}

              {show ? (
                <Col sm-8>
                  <Table
                    striped
                    bordered
                    hover
                    className="mt-3 mx-5 w-75"
                    style={{ textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th> الشركة</th>
                        <th colSpan="2">الوظيفة </th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobArr
                        ? jobArr.map((el) => (
                            <tr>
                              <td>{el.count}</td>
                              <td>{el.companyname}</td>
                              <td>{el.jobname}</td>
                              <td>
                                <i
                                  onClick={() =>
                                    deleteElement(el, "job", el.count)
                                  }
                                  class="fa-regular fa-trash-can"
                                ></i>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                </Col>
              ) : (
                ""
              )}

              <label className="d-block mt-2 ">المراحل التعليمية</label>
              <div>
                <button
                  onClick={() => handleShow("education")}
                  className="btn-save d-inline mt-2 "
                  style={{ backgroundColor: color }}
                >
                  اضافة مرحلة
                </button>
              </div>
              {showEducation ? (
                <div>
                  <input
                    style={{
                      borderColor: "gray",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderRadius: "6px",
                    }}
                    value={eduKey}
                    onChange={onChangeEduKey}
                    className="d-inline-block  mt-2 p-1 "
                    placeholder="المرحلة التعليمية"
                  />

                  <input
                    style={{
                      borderColor: "gray",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderRadius: "6px",
                    }}
                    value={eduValues}
                    onChange={onChangeEduValues}
                    className="d-inline-block mx-3 mt-2 p-1 "
                    placeholder="الوصف"
                  />

                  <button
                    onClick={() => clickHandler("education")}
                    className="btn-save d-inline mt-2 "
                    style={{
                      backgroundColor: color,
                      marginRight: "3%",
                    }}
                  >
                    {t("add")}
                  </button>
                </div>
              ) : (
                ""
              )}

              {showEducation ? (
                <Col sm-8>
                  <Table
                    striped
                    bordered
                    hover
                    className="mt-3 mx-5 w-75"
                    style={{ textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th> العنصر</th>
                        <th colSpan="2">تحكم </th>
                      </tr>
                    </thead>
                    <tbody>
                      {EduArr
                        ? EduArr.map((el) => (
                            <tr>
                              <td>{el.count}</td>
                              <td>{el.certificate}</td>
                              <td>{el.universityname}</td>
                              <td>
                                <i
                                  onClick={() =>
                                    deleteElement(el, "education", el.count)
                                  }
                                  class="fa-regular fa-trash-can"
                                ></i>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                </Col>
              ) : (
                ""
              )}

              <label className="d-block mt-2 "> رابط الفيس بوك</label>
              <input
                type="text"
                value={facebook}
                onChange={onChangeFacebook}
                placeholder="ادخل رابط الفيس بوك"
                className="input-form d-block mt-2 px-3"
              />

              <label className="d-block mt-2 "> رابط تويتر</label>
              <input
                type="text"
                value={twitter}
                onChange={onChangeTwitter}
                placeholder="ادخل رابط تويتر"
                className="input-form d-block mt-2 px-3"
              />

              <label className="d-block mt-2 "> رابط سناب شات</label>
              <input
                type="text"
                value={snapchat}
                onChange={onChangeSnapchat}
                placeholder="ادخل رابط سناب شات"
                className="input-form d-block mt-2 px-3"
              />
            </Tab>

            <Tab
              eventKey="characterinfo"
              title="وضع ضمن الشخصيات"
              style={{ backgroundColor: "white" }}
            >
              <label htmlFor="isVip" className="d-block mt-2 ">
                وضع من ضمن الشخصيات
              </label>

              <select
                onChange={onChangeIsVip}
                value={isVip}
                className="select input-form-area mt-2 px-3 "
              >
                <option value="true">نعم</option>
                <option value="false">لا</option>
              </select>

              <label className="d-block mt-2 ">التصنيف الرئيسي</label>

              <select
                onChange={onChangeCharMainCategoryVip}
                value={charMainCategoryVipId}
                className="select input-form-area mt-2 px-3 "
              >
                <option value="0">من فضلك اختر التصنيف الرئيسي</option>
                {allMainCharacters
                  ? allMainCharacters.map((el) => {
                      return (
                        <option key={el._id} value={el._id}>
                          {el.name}
                        </option>
                      );
                    })
                  : null}
                )
              </select>

              <label className="d-block mt-2 ">التصنيف الفرعي</label>

              <select
                onChange={onChangeCharSubCategoryVip}
                value={charSubCategoryVipId}
                className="select input-form-area mt-2 px-3 "
              >
                <option value="0">من فضلك اختر التصنيف الفرعي</option>
                {allSubForMainCharacter
                  ? allSubForMainCharacter.map((el) => {
                      return (
                        <option key={el._id} value={el._id}>
                          {el.name}
                        </option>
                      );
                    })
                  : null}
                )
              </select>

              <div className="mt-5 mb-5">
                <label>السيرة الذاتية</label>
              </div>

              <TextEditor value={descVip} descRef={descVipRef} />
              {/* <Editor

 onInit={(evt, editor) => (descVipRef.current =editor)}
 init={{ height: 300}}
 initialValue={descVip}
 
  /> */}

              <label htmlFor="images" className="d-block mt-4 ">
                الصور
              </label>

              <div>
                <div className="mt-3">
                  <MultiImageInput
                    images={images}
                    setImages={setImages}
                    theme="light"
                    allowCrop={false}
                  />
                </div>
              </div>

              <label className="d-block mt-2 ">المعد</label>

              <Select
                loadOptions={loadPartenersOptions}
                onChange={onChangeCreatorVip}
                getOptionLabel={(e) => e.fullname}
                getOptionValue={(e) => e._id}
                options={allUsers}
                value={creatorVip}
                isClearable={true}
              />
            </Tab>

            <Tab
              eventKey="characterbrothers"
              title="الاشقاء"
              style={{ backgroundColor: "white" }}
            >
              <label className="d-block mt-2 ">اخوات من الرضاعة</label>

              <div>
                <button
                  onClick={() => handleShow("brothersFml")}
                  className="btn-save d-inline mt-2 "
                  style={{ backgroundColor: color }}
                >
                  اضافة اخوات
                </button>
              </div>

              {showBrothersFML ? (
                <div>
                  <FullNameFmlAddInputs
                    onChange={onChangeBrotherFMLKey}
                    className="brothersReda3"
                  />

                  <select
                    onChange={onChangeBrotherFMLValues}
                    value={brotherFMLValues}
                    className="select input-form-area mt-2 px-3 w-25 "
                  >
                    <option>الجنس</option>
                    <option value="male">ذكر</option>
                    <option value="female">انثي</option>
                  </select>

                  <button
                    onClick={() => clickHandler("brothersFml")}
                    className="btn-save d-inline mt-2 "
                    style={{
                      backgroundColor: color,
                      marginRight: "3%",
                    }}
                  >
                    {t("add")}
                  </button>
                </div>
              ) : (
                ""
              )}

              {showBrothersFML ? (
                <Col sm-8>
                  <Table
                    striped
                    bordered
                    hover
                    className="mt-3 mx-5 w-75"
                    style={{ textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th> الاسم رباعي</th>
                        <th colSpan="2">الجنس </th>
                      </tr>
                    </thead>
                    <tbody>
                      {brotherFMLArr
                        ? brotherFMLArr.map((el) => (
                            <tr>
                              <td>{el.count}</td>
                              <td>{el.name}</td>
                              <td>{el.gender}</td>
                              <td>
                                <i
                                  onClick={() =>
                                    deleteElement(el, "brothersFml", el.count)
                                  }
                                  class="fa-regular fa-trash-can"
                                ></i>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                </Col>
              ) : (
                ""
              )}

              <label className="d-block mt-2 ">امهات من الرضاعة</label>

              <button
                onClick={() => handleShow("mothersFml")}
                className="btn-save d-inline mt-2 "
                style={{ backgroundColor: color }}
              >
                اضافة امهات
              </button>

              {showReda3aMothers ? (
                <div>
                  <FullNameFmlAddInputs
                    onChange={onChangeReda3aMothersKey}
                    className="mothersFml"
                  />

                  <button
                    onClick={() => clickHandler("mothersFml")}
                    className="btn-save d-inline mt-2 "
                    style={{
                      backgroundColor: color,
                      marginRight: "3%",
                    }}
                  >
                    {t("add")}
                  </button>
                </div>
              ) : (
                ""
              )}

              {showReda3aMothers ? (
                <Col sm-8>
                  <Table
                    striped
                    bordered
                    hover
                    className="mt-3 mx-5 w-75"
                    style={{ textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th> الاسم رباعي</th>
                        <th colSpan="2">الجنس </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reda3aMothersArr
                        ? reda3aMothersArr.map((el) => (
                            <tr>
                              <td>{el.count}</td>
                              <td>{el.name}</td>
                              <td>{el.gender}</td>
                              <td>
                                <i
                                  onClick={() =>
                                    deleteElement(el, "mothersFml", el.count)
                                  }
                                  class="fa-regular fa-trash-can"
                                ></i>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                </Col>
              ) : (
                ""
              )}

              <label className="d-block mt-2 ">ابناء من الرضاعة</label>

              <button
                onClick={() => handleShow("reda3aSons")}
                className="btn-save d-inline mt-2 w-25 "
                style={{ backgroundColor: color }}
              >
                اضافة ابناء من الرضاعة
              </button>

              {showReda3aSons ? (
                <div>
                  <FullNameFmlAddInputs
                    onChange={onChangeReda3aSonsKey}
                    className="reda3aSons"
                  />

                  <select
                    onChange={onChangeReda3aSonsValues}
                    value={reda3aSonsValues}
                    className="select input-form-area mt-2 px-3 w-25 "
                  >
                    <option>الجنس</option>
                    <option value="male">ذكر</option>
                    <option value="female">انثي</option>
                  </select>

                  <button
                    onClick={() => clickHandler("reda3aSons")}
                    className="btn-save d-inline mt-2 "
                    style={{
                      backgroundColor: color,
                      marginRight: "3%",
                    }}
                  >
                    {t("add")}
                  </button>
                </div>
              ) : (
                ""
              )}

              {showReda3aSons ? (
                <Col sm-8>
                  <Table
                    striped
                    bordered
                    hover
                    className="mt-3 mx-5 w-75"
                    style={{ textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th> الاسم رباعي</th>
                        <th colSpan="2">الجنس </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reda3aSonsArr
                        ? reda3aSonsArr.map((el) => (
                            <tr>
                              <td>{el.count}</td>
                              <td>{el.name}</td>
                              <td>{el.gender}</td>
                              <td>
                                <i
                                  onClick={() =>
                                    deleteElement(el, "reda3aSons", el.count)
                                  }
                                  class="fa-regular fa-trash-can"
                                ></i>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </Table>
                </Col>
              ) : (
                ""
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-4 "
            style={{ backgroundColor: color }}
            disabled={loading}
          >
            تعديل
          </button>

          {loading && <Spinner />}
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminEditUser;
