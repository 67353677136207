import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";
import avatar from "../../../src/Images/adminIcon.png";
import { addOneMainCharacter } from "../../redux/actions/mainCharactersActions";

const AdminAddMainCharacterHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState("");

  const onChangeName = (e) => {
    setName(e.target.value);
  };

  const onSubmit = async () => {
    if (name === "") {
        notify("من فضلك اكمل البيانات", "error");
        return;
      }
      setLoading(true);
      await dispatch(
        addOneMainCharacter( {
          name: name
        })
      );
      setLoading(false);
    };
  
    const res = useSelector((state) => state.mainCharactersReducer.addOneMainCharacter);
  
    useEffect(() => {
      if (loading === false) {
        if (res) {
          notify("تم الاضافة بنجاح", "success");
          setTimeout(() => {
            navigate("/admin/mainchar");
          }, 1500);
        } else {
          notify("فشل في عملية الاضافة", "error");
        }
      }
    }, [loading]);

  return [name, onChangeName, onSubmit];
};
export default AdminAddMainCharacterHook;
