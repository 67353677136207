import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";

import {
  getOneCountry,
  updateOneCountry
} from "../../redux/actions/countriesActions";

const AdminEditCountryHook = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [name, setName] = useState("");

  useEffect(() => {
    const get = async () => {
      setLoadingData(true);
      await dispatch(getOneCountry(id));
      //console.log('one country dispatch')
      setLoadingData(false);
    };
    get();
    //dispatch(getOneCountry(id));
  }, []);

  let oneCountry = useSelector((state) => state.countriesReducer.getOneCountry);
  useEffect(() => {
    if (oneCountry) {
      setName(oneCountry.name);
    }
  }, [loadingData]);

  let onChangeName = (e) => {
    e.persist();
    setName(e.target.value);
  };

  let onSubmit = async () => {
    if (name === "") {
      notify("من فضلك اكمل البيانات", "error");
      return;
    }
    setLoading(true);
    await dispatch(
      updateOneCountry(id, {
        name: name,
      })
    );
    setLoading(false);
  };

  const res = useSelector((state) => state.countriesReducer.updateOneCountry);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          navigate("/admin/countries");
        }, 1500);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);



  return [name, onChangeName, onSubmit];
};

export default AdminEditCountryHook;
