import { GET_ALL_POLLS,UPDATE_ONE_POLL,ADD_ONE_POLL } from "../type";


const initial={
    polls:[],
    updatedPoll:null,
    newPoll:null
};
const pollsReducer=(state=initial,action)=>{
    switch(action.type){
        case GET_ALL_POLLS: return{
            ...state,polls:action.payload,
            updatedPoll:null,
            newPoll:null
        }
        case ADD_ONE_POLL: return{
            ...state,
            newPoll:action.payload
        }

        case UPDATE_ONE_POLL:return{
            ...state,
            updatedPoll:action.payload
        }

        default:return state

    }

}

export default pollsReducer;