import React from 'react'
import SideBar from '../../Components/Admin/SideBar'
import AdminPollsComponent from '../../Components/Admin/AdminPollsComponent'

export default function AdminPollsPage() {
  return (
    <div className="d-flex justify-content-start">
         <div>
          <SideBar />
        </div>
        <div className="p-4" style={{ width: '100%' }}>
          <AdminPollsComponent />
        </div>
    </div>
  )
}
