import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";

import {
    getOneNews,
    updateOneNews
} from "../../redux/actions/newsActions";
import { getAllUsers } from "../../redux/actions/usersActions";
import { useRef } from "react";
import { getDateTime } from "../../Components/Utilities/getDateTime";
import { formateDate } from "../../Components/Utilities/formatDate";

const AdminEditNewsHook = (id) => {
    const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [newsMainType, setNewsMainType] = useState("");
  const [title, setTitle] = useState("");
  const descRef=useRef();

  const [desc, setDesc] = useState("");
  const [images, setImages] = useState([]);
  // const[mainImage,setMainImage]=useState("");
  //:00.000Z


  //normal

  const [seen, setSeen] = useState("");
  const [important, setImportant] = useState("");
  //monasba
  const [newsDate, setNewsDate] = useState("");
  const [relationalUsersArray, setRelationalUsersArray] = useState("");
  const [userId, setUserId] = useState("");
  const [monasbaType, setMonasbaType] = useState("");
  const [monasbaGrromId, setMonasbaGrromId] = useState("");
  const [monasbaBrideId, setMonasbaBrideId] = useState("");
  const [monasbaGroomNameFML, setMonasbaGroomNameFML] = useState("");
  const [monasbaBirdeNameFML, setMonasbaBirdeNameFML] = useState("");
  //birth
  const [newBornUserId, setNewBornUserId] = useState("");
  const [newBorn2UserId, setNewBorn2UserId] = useState("");
  const [newBornNameFML, setNewBornNameFML] = useState("");
  const [newBorn2NameFML, setNewBorn2NameFML] = useState("");
  const [newBornGenderFML, setNewBornGenderFML] = useState("");
  const [newBorn2GenderFML, setNewBorn2GenderFML] = useState("");
  const [parentId, setParentId] = useState("");
  //death
  const [deathUserId, setDeathUserId] = useState("");
  const [deathFromOutFamilyFMLGender, setDeathFromOutFamilyFMLGender] =
    useState("");
  const [deathFromOutFamilyFML, setDeathFromOutFamilyFML] = useState("");
  const [deathDesc, setDeathDesc] = useState("");

  const [deathGanazaAddress, setDeathGanazaAddress] = useState("");
  const [deathGanazaDateTime, setDeathGanazaDateTime] = useState("");
  const [deathGanazaLocation, setDeathGanazaLocation] = useState("");

  const [deathDafnaAddress, setDeathDafnaAddress] = useState("");
  const [deathDafnaDateTime, setDeathDafnaDateTime] = useState("");
  const [deathDafnaLocation, setDeathDafnaLocation] = useState("");

  const [death3azaAddress, setDeath3azaAddress] = useState("");
  const [death3azaLocation, setDeath3azaLocation] = useState("");
  const [death3azaDateTimeFrom, setDeath3azaDateTimeFrom] = useState("");
  const [death3azaDateTimeTo, setDeath3azaDateTimeTo] = useState("");
  const [male3azaContacts, setMale3azaContacts] = useState("");
  const [female3azaContacts, setFemale3azaContacts] = useState("");
  const [allUsers, setAllUsers] = useState("");

  const[normalType,setNormalType]=useState(false);
  const[birthType,setBirthType]=useState(false);
  const[deathType,setDeathType]=useState(false);
  const[selectMonasbaType,setselectMonasbaType]=useState(false);
  const[firstNameNewBorn,setFirstNameNewBorn]=useState("");
  const[firstNameNewBorn2,setFirstNameNewBorn2]=useState("");
  const[familyFemales,setFamilyFemales]=useState([]);
  const[familyMales,setFamilyMales]=useState([]);

  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const [jobArr, setJobArr] = useState([]);

  const[eduKey,setEduKey]=useState("");
  const[eduValues,setEduValues]=useState("");
  const[showEducation,setShowEducation]=useState(false);
  const [EduArr, setEduArr] = useState([]);

  const[female3AzaArr,setFemale3AzaArr]=useState([]);
  const[birthFromFamily,setBirthFromFamily]=useState("");

  const[death3azaFemaleAddress,setDeath3azaFemaleAddress]=useState("");
  const[death3azaFemaleLocation,setDeath3azaFemaleLocation]=useState("");
  const[death3azaFemaleDateTimeFrom,setDeath3azaFemaleDateTimeFrom]=useState("");
  const[death3azaFemaleDateTimeTo,setDeath3azaFemaleDateTimeTo]=useState("");

  async function get()
  {
   await dispatch(getOneNews(id));
   await dispatch(getAllUsers());
  } 
  useEffect(()=>{
    get();
  },[]);

  let Users = useSelector((state) => state.usersReducers.allUser);

   useEffect(()=>{
    if(Users)
    {
      let familyFemales= Users.filter(el=>{
        if(el.gender==='female') return el;
      });

      let familyMales=Users.filter(el=>{
        if(el.gender==='male') return el;
      });

      setFamilyMales(familyMales);
      setFamilyFemales(familyFemales);
    }

    setAllUsers(Users);
   },[Users]);

  let oneNews= useSelector((state) => state.newsReducer.oneNews);
  console.log(oneNews);

 async function setType()
  {
    if(newsMainType=='births')
    {
        // setNormalType(false);
        setBirthType(true);
        // setDeathType(false);
        // setselectMonasbaType(false);
    }
    else if(newsMainType=='normal')
    { 
        setNormalType(true);
      //   setBirthType(false);
      //   setDeathType(false);
      //  setselectMonasbaType(false);

    }
    else if(newsMainType=='monasba')
    {
        // setNormalType(false);
        // setBirthType(false);
        // setDeathType(false);
        setselectMonasbaType(true);
    }
    else if(newsMainType=='deaths')
    {
        // setNormalType(false);
        // setBirthType(false);
        setDeathType(true);
        //setselectMonasbaType(false);
    }
  }

  useEffect(()=>{
    setNewsMainType(oneNews.NewsMainType);
    setTitle(oneNews.title);
    setDesc(oneNews.desc);
    setImages(oneNews.images);
    setSeen(oneNews.seen);
    setImportant(oneNews.important);
    setNewsDate(formateDate(oneNews.newsDate));
    setUserId(oneNews.userId);
    setMonasbaType(oneNews.monasbaType);

    setMonasbaGrromId(oneNews.monasbaGrromId?._id);
    setMonasbaBrideId(oneNews.monasbaBrideId?._id);
    setMonasbaGroomNameFML(oneNews.monasbaGroomNameFML);
    setMonasbaBirdeNameFML(oneNews.monasbaBirdeNameFML);
    setRelationalUsersArray(oneNews.relationalUsersArray);

    setNewBornUserId(oneNews.newBornUserId?._id);
    setNewBorn2UserId(oneNews.newBorn2UserId?._id);
    setNewBornNameFML(oneNews.newBornNameFML);
    setNewBorn2NameFML(oneNews.newBorn2NameFML);
    setNewBornGenderFML(oneNews.newBornGenderFML);
    setNewBorn2GenderFML(oneNews.newBorn2GenderFML)
    setParentId(oneNews.parentId?._id);
    setDeathUserId(oneNews.deathUserId?._id);
    setDeathFromOutFamilyFMLGender(oneNews.deathFromOutFamilyFMLGender);
    setDeathFromOutFamilyFML(oneNews.deathFromOutFamilyFML);
    setDeathGanazaAddress(oneNews.deathGanazaAddress);
    setDeathGanazaDateTime(oneNews.deathGanazaDateTime && getDateTime(oneNews.deathGanazaDateTime));
    setDeathGanazaLocation(oneNews.deathGanazaLocation);
    setDeathDafnaAddress(oneNews.deathDafnaAddress);
    setDeathDafnaDateTime(oneNews.deathDafnaDateTime && getDateTime(oneNews.deathDafnaDateTime));
    setDeathDafnaLocation(oneNews.deathDafnaLocation);
    setDeath3azaAddress(oneNews.death3azaAddress);
    setDeath3azaLocation(oneNews.death3azaLocation)
    setDeath3azaDateTimeFrom(oneNews.death3azaDateTimeFrom && getDateTime(oneNews.death3azaDateTimeFrom));
    setDeath3azaDateTimeTo(oneNews.death3azaDateTimeTo && getDateTime(oneNews.death3azaDateTimeTo));
    //setMale3azaContacts(oneNews.male3azaContacts);
    //male3aza contacts ar
    setJobArr(oneNews.male3azaContacts);
    oneNews.male3azaContacts? setShow(true) : setShow(false);

    //female3aza contacts ar
    setEduArr(oneNews.female3azaContacts);
    oneNews.female3azaContacts? setShowEducation(true) : setShowEducation(false);

    
    //setFemale3azaContacts(oneNews.female3azaContacts);
    setNewsMainType(oneNews.NewsMainType);
    setDeath3azaFemaleAddress(oneNews?.death3azaFemaleAddress);
    setDeath3azaFemaleLocation(oneNews?.death3azaFemaleLocation);
    setDeath3azaFemaleDateTimeFrom(oneNews?.death3azaFemaleDateTimeFrom);
    setDeath3azaFemaleDateTimeTo(oneNews?.death3azaFemaleDateTimeTo);


    if (oneNews.images) setImages(oneNews.images);
      else setImages([]);
  },[oneNews]);

  // console.log('desc');
  // console.log(desc);

  useEffect(()=>{
    
    if(newsMainType)
    {
      //console.log('newsMainType');
      if(newsMainType=='births')
      {
          // setNormalType(false);
          setBirthType(true);
          // setDeathType(false);
          // setselectMonasbaType(false);
      }
      else if(newsMainType=='normal')
      { 
          setNormalType(true);
        //   setBirthType(false);
        //   setDeathType(false);
        //  setselectMonasbaType(false);
  
      }
      else if(newsMainType=='monasba')
      {
          // setNormalType(false);
          // setBirthType(false);
          // setDeathType(false);
          setselectMonasbaType(true);
      }
      else if(newsMainType=='deaths')
      {
          // setNormalType(false);
          // setBirthType(false);
          setDeathType(true);
          //setselectMonasbaType(false);
      }
    }
  
  },[newsMainType]);

  const handleShow = (flag) =>{
    console.log('handle');
    if(flag=='3azamales')
    {
      setShow(true);
    }
    else if(flag=='education')
    {
      setShowEducation(true);
    }
    // else if(flag=='social')
    // {
    //   setShowSocial(true);
    // }
    
  };

  const onChangeKey = (e) => {
    e.persist();
    console.log('key');
   
    setKey(e.target.value);
 };

 const onChangeValue = (e) => {
   e.persist();
   setValue(e.target.value);
 };

 const onChangeEduKey = (e) => {
   e.persist();
   setEduKey(e.target.value);
 };

 const onChangeEduValues =(e) =>{
   setEduValues(e.target.value);
 }



 const clickHandler = (flag) => {
   if(flag==='3azamales')
   {
    console.log('3aza males');
     setJobArr([
       ...jobArr,
       { name: key, mobile: value, count: jobArr.length + 1 },
     ]);
   }
   else if(flag==='education')
   {
    console.log('education')
     setEduArr([
       ...EduArr,
       { name: eduKey, mobile: eduValues, count: EduArr.length + 1 },
     ]);
    
   }
   // else if(flag=='social')
   // {
   //   setSocialMediaArr([
   //     ...socialMediaArr,
   //     { title: socialKey, link: socialValues, count: socialMediaArr.length + 1 },
   //   ]);
   // }
   
    setKey("");
    setValue("");
    setEduKey("");
    setEduValues("");
   // setSocialKey("");
    
   // setSocialValues("");
 };

 const deleteElement = (el,Arr,count=null) => {
   if(Arr==='3azamales')
   {
     // setJobArr(jobArr.filter((element) => el !== element));
     setJobArr(jobArr.filter(el => el.count !== count))
   }
   else if(Arr==='education')
   {
     setEduArr(EduArr.filter((element) => el !== element));
   }
   // else if(Arr=='social')
   // {
   //    setSocialMediaArr(socialMediaArr.filter((element) => el !== element));
   // }
   
 };


  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const onChangeDesc = (e) => {
    setDesc(e.target.value);
  };

  // const onChangeMainImage = (e) =>{
  //   setMainImage(e.target.value);
  // }

  const onChangeImages = (e) => {};

  const onChangeSeen = (e) => {
    setSeen(e.target.value);
  };

  const onChangeImportant = (e) => {
    setImportant(e.target.value);
  };

  const onChangeNewsDate = (e) => {
    setNewsDate(e.target.value);
  };
  //array
  const onChangeRelationalUsersArray = (values) => {
    console.log('object');
     console.log(values);
     let id=[];
     values.map((e)=>{
      id.push(e._id);
     })
     console.log(id);
     setRelationalUsersArray(id);
    //
  };

  const onChangeUserId = (e) => {
    //console.log('first');
    //console.log(value._id)
    setUserId(e.target.value);
  };

  const onChangeMonasbaType = (e) => {
    setMonasbaType(e.target.value);
  };

  const onChangeMonasbaGrromId = (e) => {
    setMonasbaGrromId(e.target.value);
  };

  const onChangeMonasbaBrideId = (e) => {
    setMonasbaBrideId(e.target.value);
  };

  const onChangeMonasbaGroomNameFML = (e) => {
    setMonasbaGroomNameFML(e.target.value);
  };

  const onChangeMonasbaBirdeNameFML = (e) => {
    setMonasbaBirdeNameFML(e.target.value);
  };

  const onChangeNewBornUserId = (e) => {
    setNewBornUserId(e.target.value);
    setFirstNameNewBorn(e.target.value);
  };

  const onChangeNewBorn2UserId = (e) => {
    setNewBorn2UserId(e.target.value);
    setFirstNameNewBorn2(e.target.value);
  };

  const onChangeNewBornNameFML = (e) => {
    setNewBornNameFML(e.target.value);
  };

  const onChangeNewBorn2NameFML = (e) => {
    setNewBorn2NameFML(e.target.value);
  };

  const onChangeNewBornGenderFML = (e) => {
    setNewBornGenderFML(e.target.value);
  };

  const onChangeNewBorn2GenderFML = (e) => {
    setNewBorn2GenderFML(e.target.value);
  };

  const onChangeParentId = (e) => {
    //console.log('first');
    //console.log(value._id);
    setParentId(e.target.value);
  };

  const onChangeDeathUserId = (e) => {
    setDeathUserId(e.target.value);
  };

  const onChangeDeathFromOutFamilyFMLGender = (e) => {
    setDeathFromOutFamilyFMLGender(e.target.value);
  };

  const onChangeDeathFromOutFamilyFML = (e) => {
    setDeathFromOutFamilyFML(e.target.value);
  };

  const onChangeDeathDesc = (e) => {
    setDeathDesc(e.target.value);
  };

  const onChangeDeathGanazaAddress = (e) => {
    setDeathGanazaAddress(e.target.value);
  };

  const onChangeDeathGanazaDateTime = (e) => {
    setDeathGanazaDateTime(e.target.value);
  };

  const onChangeDeathGanazaLocation = (e) => {
    setDeathGanazaLocation(e.target.value);
  };

  const onChangeDeathDafnaAddress = (e) => {
    setDeathDafnaAddress(e.target.value);
  };

  const onChangeDeathDafnaDateTime = (e) => {
    setDeathDafnaDateTime(e.target.value);
  };

  const onChangeDeathDafnaLocation = (e) => {
    setDeathDafnaLocation(e.target.value);
  };

  const onChangeDeath3azaAddress = (e) => {
    setDeath3azaAddress(e.target.value);
  };

  const onChangeDeath3azaLocation = (e) => {
    setDeath3azaLocation(e.target.value);
  };

  const onChangeDeath3azaDateTimeFrom = (e) => {
    setDeath3azaDateTimeFrom(e.target.value);
  };

  const onChangeDeath3azaDateTimeTo = (e) => {
    setDeath3azaDateTimeTo(e.target.value);
  };
  //array
  const onChangeMale3azaContacts = (values) => {

    console.log('object');
     let id=[];
     values.map((e)=>{
      id.push(e._id);
     })
     console.log(id);
     setMale3azaContacts(id);
  };
  //array
  const onChangeFemale3azaContacts = (values) => {
    console.log('object');
     let id=[];
     values.map((e)=>{
      id.push(e._id);
     })
     console.log(id);
     setFemale3azaContacts(id);
  };

  

  // const itemImages = Array.from(Array(Object.keys(images).length).keys()).map(
  //   (item, index) => {
  //     return dataURLtoFile(images[index], Math.random() + ".png");
  //   }
  // );

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  //convert url to file
  const convertURLtoFile = async (url) => {
    const response = await fetch(url, { mode: "cors" });
    const data = await response.blob();
    const ext = url.split(".").pop();
    const filename = url.split("/").pop();
    const metadata = { type: `image/${ext}` };
    return new File([data], Math.random(), metadata);
  };

  const onChangeBirthFromFamily = (e) =>{
    setBirthFromFamily(e.target.value);
}
  
  const onSubmit = async () => {
    // console.log(descRef.current.getContent());
    // return;

    if(userId=="")
    {
      notify("من فضلك ادخل اسم المستخدم", "error");
      return;
    }

    let itemImages = [];
    //convert array of base 64 image to file
    Array.from(Array(Object.keys(images).length).keys()).map((item, index) => {
      if (images[index].length <= 1000) {
        convertURLtoFile(images[index]).then((val) => itemImages.push(val));
      } else {
        itemImages.push(dataURLtoFile(images[index], Math.random() + ".png"));
      }
    })
    //NewsMainType
     
    const formData = new FormData();
     //itemImages.map((item) => formData.append("images", item));
     formData.append("NewsMainType", newsMainType);
     formData.append("userId", userId);

     //formData.append("desc", descRef.current.getContent());
     formData.append("desc", descRef.current.getContent());
     if(itemImages.length>0) itemImages.map((item) => formData.append("images", item));

    if(newsMainType==='normal')
    {
      
      formData.append("title", title);
      if(relationalUsersArray.length>0)relationalUsersArray.map((item) => formData.append("relationalUsersArray", item));

    }

    if(newsMainType=="births")
    {
      if(parentId)formData.append("parentId", parentId);
      formData.append("newsDate", newsDate);
    }

    if(newsMainType=="monasba")
    {
      

      if(monasbaType=="")
      {
        notify("من فضلك اختر نوع المناسبة", "error");
      return;
      }
 
      formData.append("monasbaType", monasbaType);
      if(monasbaGrromId) formData.append("monasbaGrromId", monasbaGrromId);
      if(monasbaBrideId) formData.append("monasbaBrideId", monasbaBrideId);
      formData.append("monasbaGroomNameFML", monasbaGroomNameFML);
      formData.append("monasbaBirdeNameFML", monasbaBirdeNameFML);

    }

    if(newsMainType=="deaths")
    {
     
     // if(deathUserId)formData.append("deathUserId", deathUserId._id);
      formData.append("deathFromOutFamilyFMLGender", deathFromOutFamilyFMLGender);
      formData.append("deathFromOutFamilyFML", deathFromOutFamilyFML);
      formData.append("deathDesc", descRef.current.getContent());
      formData.append("deathDesc", descRef.current.getContent());
      //formData.append("deathDesc", descRef.current.getContent());
      formData.append("deathGanazaAddress", deathGanazaAddress);
      deathGanazaDateTime&& formData.append("deathGanazaDateTime", deathGanazaDateTime);
      formData.append("deathGanazaLocation", deathGanazaLocation);

      formData.append("deathDafnaAddress", deathDafnaAddress);
      deathDafnaDateTime&& formData.append("deathDafnaDateTime", deathDafnaDateTime);
      formData.append("deathDafnaLocation", deathDafnaLocation);

      formData.append("death3azaAddress", death3azaAddress);
      formData.append("death3azaLocation", death3azaLocation);
      death3azaDateTimeFrom&& formData.append("death3azaDateTimeFrom", death3azaDateTimeFrom);
      death3azaDateTimeTo&& formData.append("death3azaDateTimeTo", death3azaDateTimeTo);
      formData.append("male3azaContacts", JSON.stringify(jobArr));
      formData.append("female3azaContacts", JSON.stringify(EduArr));

       ////////////////
       formData.append("death3azaFemaleAddress",death3azaFemaleAddress);
       formData.append("death3azaFemaleLocation",death3azaFemaleLocation);
       death3azaFemaleDateTimeFrom&& formData.append("death3azaFemaleDateTimeFrom",death3azaFemaleDateTimeFrom);
       death3azaFemaleDateTimeTo&& formData.append("death3azaFemaleDateTimeTo",death3azaFemaleDateTimeTo);
       ////////////

    
    }
  
    setLoading(true);
    
    await dispatch(updateOneNews(id,formData));
    
    setLoading(false);
  };

  const res = useSelector((state) => state.newsReducer.addNews);
  
    useEffect(() => {
      if (loading === false) {
      
        if (res) {
          notify("تم التعديل بنجاح", "success");
          setTimeout(() => {
            navigate("/admin/allnews");
          }, 1500);
        } else {
          notify("فشل في عملية التعديل", "error");
        }
      }
    }, [loading]);

  return [
    newsMainType,
    title,
    desc,
    setDesc,
    descRef,
    images,
    setImages,
    seen,
    important,
    newsDate,
    relationalUsersArray,
    userId,
    monasbaType,
    monasbaGrromId,
    monasbaBrideId,
    monasbaGroomNameFML,
    monasbaBirdeNameFML,
    newBornUserId,
    newBorn2UserId,
    newBornNameFML,
    newBorn2NameFML,
    newBornGenderFML,
    newBorn2GenderFML,
    parentId,
    deathUserId,
    deathFromOutFamilyFMLGender,
    deathFromOutFamilyFML,
    deathDesc,
    deathGanazaAddress,
    deathGanazaDateTime,
    deathGanazaLocation,
    deathDafnaAddress,
    deathDafnaDateTime,
    deathDafnaLocation,
    death3azaAddress,
    death3azaLocation,
    death3azaDateTimeFrom,
    death3azaDateTimeTo,
    male3azaContacts,
    female3azaContacts,
    birthFromFamily,
    
    onChangeTitle,
    onChangeDesc,
    onChangeImages,
    onChangeSeen,
    onChangeImportant,
    onChangeNewsDate,
    onChangeRelationalUsersArray,
    onChangeUserId,
    onChangeMonasbaType,
    onChangeMonasbaGrromId,
    onChangeMonasbaBrideId,
    onChangeMonasbaGroomNameFML,
    onChangeMonasbaBirdeNameFML,
    onChangeNewBornUserId,
    onChangeNewBorn2UserId,
    onChangeNewBornNameFML,
    onChangeNewBorn2NameFML,
    onChangeNewBornGenderFML,
    onChangeNewBorn2GenderFML,
    onChangeParentId,
    onChangeDeathUserId,
    onChangeDeathFromOutFamilyFMLGender,
    onChangeDeathFromOutFamilyFML,
    onChangeDeathDesc,
    onChangeDeathGanazaAddress,
    onChangeDeathGanazaDateTime,
    onChangeDeathGanazaLocation,
    onChangeDeathDafnaAddress,
    onChangeDeathDafnaDateTime,
    onChangeDeathDafnaLocation,
    onChangeDeath3azaAddress,
    onChangeDeath3azaLocation,
    onChangeDeath3azaDateTimeFrom,
    onChangeDeath3azaDateTimeTo,
    onChangeMale3azaContacts,
    onChangeFemale3azaContacts,
    onChangeBirthFromFamily,

    normalType,
    birthType,
    deathType,
    selectMonasbaType,
    allUsers,
    familyFemales,
    familyMales,

    handleShow,
    key,
    onChangeKey,
    value,
    onChangeValue,
    clickHandler,
    show,
    showEducation,
    jobArr,
    eduKey,
    onChangeEduKey,
    eduValues,
    onChangeEduValues,
    EduArr,
    deleteElement,
    death3azaFemaleAddress,
    setDeath3azaFemaleAddress,
    death3azaFemaleLocation,
    setDeath3azaFemaleLocation,
    death3azaFemaleDateTimeFrom,
    setDeath3azaFemaleDateTimeFrom,
    death3azaFemaleDateTimeTo,
    setDeath3azaFemaleDateTimeTo,
    onSubmit
  ];


   

    //console.log(id);

}
export default AdminEditNewsHook;