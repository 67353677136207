export default function formatFmlObj(obj) {
 let result=  obj.map((item)=>{
        return{
          ...item,
          fullname:`${item.fullname.firstname} ${item.fullname.middlename} ${item.fullname.lastname} ${item.fullname.surname}`
        }
      });

  return result;
}

export  function formatSingleFmlObject(obj){
  console.log('obj');
  console.log(obj);
  return{
    ...obj,
    fullname:`${obj.fullname.firstname} ${obj.fullname.middlename} ${obj.fullname.lastname} ${obj.fullname.surname}`
  }
}